import React from "react";
import {
  Box,
  Drawer,
  DrawerProps,
  List,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { setSidebar } from "../../redux/state/sidebarSlice";
import { navItems } from "./config";
import NavItem from "./NavItem";
import ProfileComponent from "./Profile";
import useBreakpoints from "../../hooks/useBreakPoints";
import { useTranslation } from "react-i18next";
import { useGetAllMyNotificationsQuery } from "../../redux/api/notificationApi";

const Sidebar: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isExpanded } = useSelector((state: RootState) => state.sidebar);
  const { currentProgram } = useSelector((state: RootState) => state.program);
  const { user } = useSelector((state: RootState) => state.user);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { isMd, isLg, isXl } = useBreakpoints();

  const DrawerWidthExpanded = isXl ? 379 : isLg ? 200 : isMd ? 150 : "auto";
  const DrawerWidthCollapsed = isXl ? 100 : isLg ? 80 : isMd ? 50 : "inherit";

  const drawerVariant = isMdUp ? "permanent" : "temporary";

  const { t } = useTranslation();
  const { features, free_trial, free_trial_end_date } = user;
  const shouldShowPlaylist = features.playlist;
  const menuToExcludeWithoutHavingFeatureInPlan = "/browse/playlists";

  // Filter navItems based on shouldShowPlaylist
  const filteredNavItems = navItems.filter((item) => {
    if (
      !shouldShowPlaylist &&
      item.path === menuToExcludeWithoutHavingFeatureInPlan
    ) {
      return false;
    }
    return true;
  });

  const translatedNavItems = filteredNavItems.map((item) => ({
    ...item,
    title: t(item.title),
    info: t(item.info),
  }));

  const handleDrawerToggle = () => {
    dispatch(setSidebar(!isExpanded));
  };

  // Fetch notifications
  const { data: notifications } = useGetAllMyNotificationsQuery({
    page_number: 1,
    page_size: 10,
  });

  const commonProps: DrawerProps | SwipeableDrawerProps = {
    anchor: "left",
    variant: drawerVariant,
    open: isExpanded,
    elevation: 5,
    onClose: handleDrawerToggle, // Always provide a function
    transitionDuration: { enter: 1500, exit: 1000 },
    sx: {
      width: isExpanded ? DrawerWidthExpanded : DrawerWidthCollapsed,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        justifyContent: "space-between",
        overflowX: "hidden",
        paddingX: theme.spacing(2),
        background: isExpanded
          ? "transparent linear-gradient(90deg, #FFFFFF 0%, #000000 300%) 0% 0% no-repeat padding-box"
          : "transparent",
        boxShadow: isExpanded ? theme.shadows[5] : "none",
        border: "none",
        position: "fixed",
      },
    },
    onMouseOver: () => dispatch(setSidebar(true)),
    onMouseLeave: () => dispatch(setSidebar(false)),
  };

  return isMdUp ? (
    <Drawer {...(commonProps as DrawerProps)}>
      {/* Drawer content */}
      {renderDrawerContent()}
    </Drawer>
  ) : (
    <SwipeableDrawer
      {...(commonProps as SwipeableDrawerProps)}
      onOpen={handleDrawerToggle}
    >
      {/* Drawer content */}
      {renderDrawerContent()}
    </SwipeableDrawer>
  );

  function renderDrawerContent() {
    return (
      <>
        {isExpanded && (
          <Box
            sx={{
              flex: "20%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              "&::-webkit-scrollbar": {
                width: 0,
                height: 0,
              },
              msOverflowStyle: "none", // Internet Explorer 10+
              scrollbarWidth: "none", // Firefox
            }}
          >
            <ProfileComponent
              name={currentProgram?.nickname}
              avatarUrl={currentProgram?.picture?.url}
            />
          </Box>
        )}

        <Box
          sx={{
            flex: "80%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            "&::-webkit-scrollbar": {
              width: 0,
              height: 0,
            },
            msOverflowStyle: "none", // Internet Explorer 10+
            scrollbarWidth: "none", // Firefox
          }}
        >
          <List>
            {translatedNavItems.map((item, index) => (
              <NavItem
                key={index}
                item={item}
                isExpanded={isExpanded}
                badgeContent={
                  item.title === t("nav.notifications")
                    ? notifications?.count
                    : undefined
                }
              />
            ))}
          </List>
          {isExpanded && renderFreeTrialBanner()}
        </Box>
      </>
    );
  }

  function renderFreeTrialBanner() {
    if (
      !free_trial ||
      !free_trial_end_date ||
      new Date() >= new Date(free_trial_end_date)
    ) {
      return null;
    }

    const formattedDate = new Date(free_trial_end_date).toLocaleDateString(
      undefined,
      {
        year: "numeric",
        month: "long",
        day: "numeric",
      }
    );

    return (
      <Box
        sx={{
          padding: theme.spacing(1),
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          textAlign: "center",
          position: "relative",
          borderRadius: theme.spacing(1),
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CampaignIcon fontSize="large" sx={{ color: "white" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="body1" textAlign={"left"}>
            {t("Settings.SubscriptionSettings.FreeTrial")}
          </Typography>
          <Typography variant="body2" textAlign={"left"}>
            {t("Settings.SubscriptionSettings.Ends")} {formattedDate}
          </Typography>
        </Box>
      </Box>
    );
  }
};

export default Sidebar;
