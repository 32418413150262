import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#EC674C',
  },
  // '& .MuiInput-underline:after': {
  //   borderBottomColor: '#B2BAC2',
  // },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#707070',
      opacity: 0.5,
    },
    '&:hover fieldset': {
      borderColor: '#707070',
      opacity: 0.5
    },
    '&.Mui-focused fieldset': {
      borderColor: '#EC674C',
      opacity: 1
    },
   
  },
});

export default StyledTextField;
