import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  // FastForward as FastForwardIcon,
  // FastRewind as FastRewindIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  // Settings as SettingsIcon,
  VolumeUp,
  SkipNext,
  SkipPrevious,
  ClosedCaption as ClosedCaptionIcon,
} from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import "./video.css";
import {
  IconButton,
  Popover,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Box,
  MenuList,
  MenuItem,
  Tooltip,
} from "@mui/material";
import BackButton from "../../components/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import VolumeSlider from "../../components/VolumeSlider";
import { selectMedia } from "../../utils/getMedia";
import { useContinueWatching } from "../../hooks/useContinueWatching";
import { setContinueWatchingId, setCurrentVideo } from "../../redux/state/videoSlice";
import ProgressBar from "./ProgressBar";
import {
  useGetViewByProgramIdAndVideoIdQuery,
  // useGetViewWatchAnalyticsQuery,
  useSendVideoViewMutation,
  useUpdateVideoViewMutation,
} from "../../redux/api/analyticsApi";
import {
  calculateThreshold,
  getDeviceType,
  getPlatform,
} from "../../utils/analyticsFunctions";
import { Video } from "../../types";
import PlaylistPopover from "../../components/PlaylistPopover";
import { useAlert } from "../../contexts/AlertContext";

enum thresholdReason {
  view = "view",
  complete = "complete",
}

const NowPlayingPage: React.FC = () => {
  const { currentVideo, continueWatchingId, currentPlaylist, playMode } =
    useSelector((state: RootState) => state.video);
  const { currentProgram } = useSelector((state: RootState) => state.program);
  const { id } = useSelector((state: RootState) => state.user.user);
  const {error} = useAlert();

  // const { personalSubtitle, personalVideoResolution } = useSelector(
  //   (state: RootState) => state.settings.personalSettings
  // );
  const videoRef = useRef<HTMLVideoElement>(null);

  const [playlistVideos, setPlaylistVideos] = useState<Video[]>([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const [isShuffled, setIsShuffled] = useState(false);
  const [shuffledVideos, setShuffledVideos] = useState<Video[]>([]);

  const [autoplayEnabled, setAutoplayEnabled] = useState(false);

  const playerContainerRef = useRef<HTMLDivElement>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [savedProgressTime, setSavedProgressTime] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoError, setVideoError] = useState<string | null>(); // Error state
  const hideControlsTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [viewId, setViewId] = useState("");
  const [markedAsViewed, setMarkedAsViewed] = useState(false);
  const [markedAsComplete, setMarkedAsComplete] = useState(false);

  const [shouldShowDisclaimer, setShouldShowDisclaimer] = useState(false);

  const dispatch = useDispatch();

  const [ccAnchorEl, setCcAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [playlistEl, setPlaylistEl] = useState<HTMLButtonElement | null>(null);
  const [selectedCaption, setSelectedCaption] = useState<string | null>(null);
  const [captionLang, setCaptionLang] = useState<string | null>(null);

  const [captionsData, setCaptionsData] = useState<{
    [language: string]: string;
  }>({});

  useEffect(() => {
    const fetchCaptions = async () => {
      if (currentVideo?.captions?.length > 0) {
        const captions = currentVideo.captions;
        const captionsDataPromises = captions.map(async (caption) => {
          try {
            const response = await fetch(caption.url);
            const text = await response.text();
            const blob = new Blob([text], { type: "text/vtt" });
            const blobUrl = URL.createObjectURL(blob);
            return {
              language: caption.language,
              blobUrl: blobUrl,
            };
          } catch (error) {
            console.error(
              `Error fetching caption for language ${caption.language}:`,
              error
            );
            return null;
          }
        });

        const captionsDataArray = await Promise.all(captionsDataPromises);
        const captionsDataMap = captionsDataArray.reduce((acc, captionData) => {
          if (captionData) {
            acc[captionData.language] = captionData.blobUrl;
          }
          return acc;
        }, {} as { [language: string]: string });

        console.log(captionsDataMap)

        setCaptionsData(captionsDataMap);
      }
    };

    fetchCaptions();

    // Cleanup function to revoke Blob URLs
    return () => {
      Object.values(captionsData).forEach((blobUrl) => {
        URL.revokeObjectURL(blobUrl);
      });
    };
  }, [currentVideo]);

  // Volume Popover State
  const [volumeAnchorEl, setVolumeAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [volume, setVolume] = useState<number>(1); // 1 is maximum (100%)

  let interval: NodeJS.Timeout | null = null;
  const disclaimerTimeout = 5000; // Timeout duration in milliseconds

  // Function to manually close the disclaimer
  const closeDisclaimer = () => {
    setShouldShowDisclaimer(false);
    const isPlaying = !videoRef?.current?.paused;
    if (isPlaying === false) {
      playVideo();
    }
  };

  const handleTogglePlaylist = (event: React.MouseEvent<HTMLButtonElement>) => {
    // setPlaylistEl(playlistEl ? null : event.currentTarget);

    if (playlistEl) {
      setPlaylistEl(null);
    } else {
      setPlaylistEl(event.currentTarget);
    }
  };

  const handleClosePlaylist = () => {
    setPlaylistEl(null);
  };

  const handleSelectVideo = (index: number) => {
    const isPlaying = !videoRef?.current?.paused;
    if (isPlaying) {
      pauseVideo();
    }
    if (interval) {
      clearInterval(interval);
    }
    setCurrentVideoIndex(index);
    handleClosePlaylist();
  };

  // Settings Popover State
  // const [settingsAnchorEl, setSettingsAnchorEl] =
  //   useState<HTMLButtonElement | null>(null);

  // Open states for popovers
  const isVolumePopoverOpen = Boolean(volumeAnchorEl);
  // const isSettingsPopoverOpen = Boolean(settingsAnchorEl);
  const isCcPopoverOpen = Boolean(ccAnchorEl);
  const isPlaylistPopoverOpen = Boolean(playlistEl);

  const { fetchVideoContinueWatching, update, remove, create } =
    useContinueWatching(currentProgram.id);

  const [createVideoView] = useSendVideoViewMutation();

  const [updateVideoView] = useUpdateVideoViewMutation();

  const { data: viewWatchAnalyics } = useGetViewByProgramIdAndVideoIdQuery({
    video_id: currentVideo?.id,
    program_id: currentProgram.id,
  });

  // Handler for click event
  const checkProgress = useCallback(async () => {
    setLoading(true);

    try {
      if (currentVideo) {
        const updatedData = await fetchVideoContinueWatching({
          program_id: currentProgram.id,
          video_id: currentVideo?.id,
          sort: "asc",
          field: "created_at",
          page_number: 1,
          page_size: 1,
        }).unwrap();

        if (
          updatedData &&
          updatedData.data.length === 1 &&
          updatedData.data[0].video_id === currentVideo.id
        ) {
          console.log("Continue watching exists");
          dispatch(
            setCurrentVideo({
              video: currentVideo,
              programId: currentProgram.id,
              progress: updatedData.data[0].current_position,
              total: currentVideo.medias[0].length,
              continueWatchingId: updatedData.data[0].id,
            })
          );
          const progressTime = updatedData.data[0].current_position;
          const completed = updatedData.data[0].is_complete;
          setMarkedAsComplete(completed);
          setSavedProgressTime(progressTime);
          if (
            progressTime > 0 &&
            progressTime < currentVideo.medias[0].length
          ) {
            setShowContinueDialog(true);
          } else if (progressTime === 0 && currentVideo?.disclaimer) {
            setShouldShowDisclaimer(true);
          }
        } else {
          // If the video is not in the continue watching list, create a new entry
          if (playMode === "playlist") {
            const response = await create({
              program_id: currentProgram.id,
              video_id: currentVideo?.id,
              current_position: 0,
              is_complete: false,
            });

            dispatch(
              setCurrentVideo({
                video: currentVideo,
                programId: currentProgram.id,
                progress: 0,
                total: currentVideo?.medias[0].length,
                continueWatchingId: response.data.id,
              })
            );
          }

          if (currentVideo?.disclaimer) {
            setShouldShowDisclaimer(true);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching video continue watching data:", error);
    } finally {
      setLoading(false);
    }
  }, [
    fetchVideoContinueWatching,
    currentProgram?.id,
    currentVideo?.id,
    dispatch,
  ]);

  const markVideoAsViewed = async () => {
    if (videoRef?.current) {
      const currentTime = videoRef?.current?.currentTime;
      const totalLength = videoRef?.current?.duration;
      try {
        const response = await createVideoView({
          company_id: id,
          video_id: currentVideo.id,
          program_id: currentProgram.id,
          retention_rate: currentTime / totalLength,
          device_type: getDeviceType(), // Add device type
          platform: getPlatform(), // Add platform
        }).unwrap();
        setMarkedAsViewed(true);
        setViewId(response.data.id);
      } catch (error) {
        console.error("Error marking video as viewed:", error);
      }
    }
  };

  const updateVideoAnalytics = async () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const totalLength = videoRef.current.duration;
      try {
        await updateVideoView({
          id: viewId,
          retention_rate: currentTime / totalLength,
        }).unwrap();
      } catch (error) {
        console.error("Error updating video view:", error);
      }
    }
  };

  // Function to handle progress bar change
  const handleProgressChange = (newValue: number) => {
    const newTime = (newValue / 100) * totalDuration;
    setCurrentTime(newTime);
    if (videoRef?.current) {
      videoRef.current.currentTime = newTime;
    }
  };

  const handleCcClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCcAnchorEl(event.currentTarget);
  };

  const handleCcClose = () => {
    setCcAnchorEl(null);
  };

  const handleCaptionSelect = (
    caption: { language: string; url: string } | null
  ) => {
    if (caption?.language && captionsData[caption?.language]) {
      console.log(caption.language);
      console.log(captionsData[caption?.language]);
      setSelectedCaption(captionsData[caption?.language]);
      setCaptionLang(caption.language);
    } else {
      setSelectedCaption(null);
      setCaptionLang(null);
    }
    handleCcClose();
  };

  const handleMouseDown = () => {
    if (videoRef?.current && !videoRef?.current?.paused) {
      videoRef.current.pause();
      setPlaying(false); // Update the playing state
    }
  };

  const handleMouseUp = () => {
    if (videoRef?.current && !playing) {
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to play the video:", error);
        setVideoError("An error occurred while trying to play the video.");
      });
      setPlaying(true); // Resume playing
    }
  };

  // Volume Handlers
  const toggleVolumeSlider = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (volumeAnchorEl) {
      setVolumeAnchorEl(null);
    } else {
      setVolumeAnchorEl(event.currentTarget);
    }
  };

  const handleVolumeClose = () => {
    setVolumeAnchorEl(null);
  };

  // Update to handle video metadata loaded event
  const handleMetadataLoaded = () => {
    if (videoRef?.current) {
      setTotalDuration(videoRef.current.duration);
    }
  };

  // Update to handle volume change
  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const newVolume = (newValue as number) / 100; // Convert to a fraction
    setVolume(newVolume);
    if (videoRef?.current) {
      videoRef.current.volume = newVolume;
    }
  };

  // Settings Handlers
  // const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setSettingsAnchorEl(event.currentTarget);
  // };

  // const handleSettingsClose = () => {
  //   setSettingsAnchorEl(null);
  // };

  const handleRewind = () => {
    if (videoRef?.current) {
      videoRef.current.currentTime = videoRef.current.currentTime - 10;
      setCurrentTime(videoRef.current.currentTime - 10);
    }
  };

  const handleFastForward = () => {
    if (videoRef?.current) {
      videoRef.current.currentTime = videoRef.current.currentTime + 10;
      setCurrentTime(videoRef.current.currentTime + 10);
    }
  };

  const pauseVideo = () => {
    if (videoRef?.current) {
      videoRef?.current?.pause();
      setPlaying(false);
    }
  };

  const playVideo = () => {
    if (videoRef?.current) {
      videoRef?.current?.play().catch((error) => {
        console.error("Error attempting to play the video:", error);
        setVideoError("An error occurred while trying to play the video.");
      });
      setPlaying(true);
    }
  };

  const togglePlay = async () => {
    try {
      // If no continue watching ID, create a new one
      if (!continueWatchingId) {
        const response = await create({
          program_id: currentProgram.id,
          video_id: currentVideo.id,
          current_position: 0,
          is_complete: false,
        });
  
        if (response.data.id) {
          dispatch(setContinueWatchingId(response.data.id));
        }
      }
  
      // Toggle play/pause state of the video
      if (videoRef?.current) {
        const isPlaying = !videoRef?.current?.paused;
        if (isPlaying) {
          pauseVideo();
        } else {
          playVideo();
        }
      }
    } catch (e) {
      console.error("Error toggling play state:", e);
      error("Error while trying to play video, try again")
    }
  };
  

  const handleError = () => {
    if (videoRef?.current) {
      const error = videoRef?.current?.error;
      if (error) {
        let errorMessage = "An unknown error occurred.";
        switch (error.code) {
          case error.MEDIA_ERR_ABORTED:
            errorMessage = "The video playback was aborted.";
            break;
          case error.MEDIA_ERR_NETWORK:
            errorMessage = "A network error caused the video download to fail.";
            break;
          case error.MEDIA_ERR_DECODE:
            errorMessage =
              "The video playback was aborted due to a corruption problem or because the video used features your browser did not support.";
            break;
          case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
            errorMessage =
              "The video could not be loaded, either because the server or network failed or because the format is not supported.";
            break;
        }
        console.error("Video Error: ", errorMessage);
        setVideoError(errorMessage); // Set the error message
      }
    }
  };

  const handleRemoveContinueWatching = useCallback(async () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const totalLength = videoRef.current.duration;

      if (currentTime === totalLength && continueWatchingId) {
        try {
          await remove(continueWatchingId);
          dispatch(setContinueWatchingId(null));
        } catch (error) {
          console.error("Error removing continue watching:", error);
        }
      }
    }
  }, [remove, videoRef?.current]);

  const handleProgress = useCallback(async () => {
    console.log("Handle Progress Function Triggered");
    if (videoRef?.current) {
      const currentTime = videoRef.current.currentTime;
      const totalLength = videoRef.current.duration;
      setCurrentTime(currentTime);
      console.log("Saving Progress");
      console.log("Current Time: ", currentTime);
      console.log("Total Length: ", totalLength);
      await saveProgress(currentTime, totalLength);

      const viewThreshold = calculateThreshold(
        totalLength,
        thresholdReason.view
      );

      if (currentTime >= viewThreshold && !markedAsViewed && !viewId) {
        await markVideoAsViewed(); // Function to send data that the video is viewed
      }

      if (viewId) {
        await updateVideoAnalytics();
      }
    }
  }, [videoRef?.current, viewId, markVideoAsViewed, continueWatchingId]);

  const saveProgress = async (currentTime: number, length: number) => {
    console.log("Save Progress Function Triggered");
    const completeThreshold = calculateThreshold(
      length,
      thresholdReason.complete
    );

    console.log("Complete Threshold: ", completeThreshold);

    console.log("Program Id: ", currentProgram.id);
    console.log("Current Video: ", currentVideo);
    console.log("Continue Watching Id: ", continueWatchingId);
    // Only set is_complete if markedAsComplete is false and the user has crossed the completeThreshold
    if (
      videoRef?.current &&
      currentProgram?.id &&
      currentVideo?.id &&
      continueWatchingId
    ) {
      console.log("All conditions met to save continue watching progress");
      const progressData = {
        current_position: currentTime,
        is_complete: markedAsComplete,
      };

      // Update is_complete only if the video is completed and it's not already marked as complete
      if (currentTime >= completeThreshold && !markedAsComplete) {
        progressData["is_complete"] = true;
        setMarkedAsComplete(true);
      }

      try {
        // Update the progress
        await update(continueWatchingId, progressData);
      } catch (error) {
        console.error("Error saving progress:", error);
      }
    }
  };

  // // Handle seeking
  // const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const newPlayed = parseFloat(e.target.value);
  //   if (videoRef.current) {
  //     videoRef.current.currentTime =
  //       (videoRef.current.duration / 100) * newPlayed;
  //   }
  //   setPlayed(newPlayed);
  // };

  // Fullscreen toggle
  const toggleFullscreen = () => {
    const el = playerContainerRef.current;

    if (!document.fullscreenElement && el) {
      el.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const formatTime = (seconds: number) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().slice(11, 19);
  };

  const hideControls = () => {
    console.log("Hiding Controls");
    setShowControls(false);
  };

  const startHideControlsTimer = () => {
    console.log("Starting Hide Controls Timer");
    if (hideControlsTimerRef.current) {
      clearTimeout(hideControlsTimerRef.current);
    }
    hideControlsTimerRef.current = setTimeout(hideControls, 5000); // Hide controls after 5 seconds
  };

  const handleMouseMove = () => {
    console.log("Mouse Move");
    setShowControls(true);
    startHideControlsTimer();
  };

  const handleMouseEnter = () => {
    console.log("Mouse Enter");
    setShowControls(true);
    startHideControlsTimer();
  };

  const handleMouseLeave = () => {
    console.log("Mouse Leave");
    if (hideControlsTimerRef.current) {
      clearTimeout(hideControlsTimerRef.current); // Clear the timer when the mouse leaves
    }
    hideControls();
  };

  const handleContinueFromSavedProgress = () => {
    if (videoRef?.current) {
      const isPlaying = !videoRef.current.paused;
      videoRef.current.currentTime = savedProgressTime;
      if (!isPlaying) {
        videoRef?.current?.play();
        setPlaying(true);
      }
    }
    setShowContinueDialog(false);
  };

  const handleStartFromBeginning = () => {
    if (videoRef?.current) {
      videoRef.current.currentTime = 0;
    }
    setSavedProgressTime(0); // Reset the saved progress time
    setShowContinueDialog(false);
  };

  // Function to handle spacebar press
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.code === "Space") {
      event.preventDefault(); // Prevent the default action of the space key
      togglePlay();
    }
  };

  // Manage Video play/pause interval

  useEffect(() => {
    if (playing) {
      interval = setInterval(() => {
        handleProgress();
      }, 1000);
    } else if (!playing && interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [playing, handleProgress]);

  useEffect(() => {
    // Add keydown event listener
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array ensures this effect runs once on mount and cleanup

  useEffect(() => {
    return () => {
      if (hideControlsTimerRef.current) {
        clearTimeout(hideControlsTimerRef.current); // Clear the timer when the component unmounts
      }
    };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullScreen = Boolean(
        document.fullscreenElement === playerContainerRef.current
      );

      setIsFullscreen(isFullScreen);
      if (isFullScreen) {
        document.body.classList.add("no-scrollbar");
      } else {
        document.body.classList.remove("no-scrollbar");
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      if (document.fullscreenElement) {
        document.exitFullscreen(); // Exit fullscreen when the component unmounts
      }
      document.body.classList.remove("no-scrollbar"); // Remove the 'no-scrollbar' class
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    checkProgress();
  }, [currentVideo]);

  useEffect(() => {
    if (viewWatchAnalyics && viewWatchAnalyics.data) {
      setViewId(viewWatchAnalyics.data.id);
      setMarkedAsViewed(true);
    }
  }, []);

  // Effect to handle disclaimer timeout
  useEffect(() => {
    if (shouldShowDisclaimer) {
      const timer = setTimeout(() => {
        setShouldShowDisclaimer(false);
        const isPlaying = !videoRef?.current?.paused;
        if (isPlaying === false) {
          playVideo();
        }
      }, disclaimerTimeout);

      // Cleanup function to clear the timeout if disclaimer is closed manually or `shouldShowDisclaimer` changes
      return () => clearTimeout(timer);
    }
  }, [shouldShowDisclaimer]);

  // Function to fetch videos in the playlist
  useEffect(() => {
    const loadPlaylistVideos = async () => {
      if (playMode === "playlist" && currentPlaylist.length > 0) {
        setPlaylistVideos([...currentPlaylist]);
      }
    };

    loadPlaylistVideos();
  }, [currentPlaylist, playMode]);

  // Set the current video when the playlist updates or the index changes
  useEffect(() => {
    if (playlistVideos.length > 0) {
      const currentVideo = isShuffled
        ? shuffledVideos[currentVideoIndex]
        : playlistVideos[currentVideoIndex];
      dispatch(
        setCurrentVideo({ video: currentVideo, programId: currentProgram.id })
      );
    }
  }, [
    playlistVideos,
    shuffledVideos,
    currentVideoIndex,
    isShuffled,
    dispatch,
    currentProgram,
  ]);

  const handleNextVideo = () => {
    const isPlaying = !videoRef?.current?.paused;
    if (isPlaying) {
      pauseVideo();
    }
    if (interval) {
      clearInterval(interval);
    }

    if (isShuffled) {
      // Generate a random index that's different from the current index
      let nextIndex: React.SetStateAction<number>;
      do {
        nextIndex = Math.floor(Math.random() * shuffledVideos.length);
      } while (nextIndex === currentVideoIndex);

      setCurrentVideoIndex(nextIndex);
      handleClosePlaylist();
    } else {
      // Play the next video in the list if not the last one
      if (currentVideoIndex < playlistVideos.length - 1) {
        setCurrentVideoIndex(currentVideoIndex + 1);
        handleClosePlaylist();
      }
    }
  };

  const handlePreviousVideo = () => {
    const isPlaying = !videoRef?.current?.paused;
    if (isPlaying) {
      pauseVideo();
    }
    if (interval) {
      clearInterval(interval);
    }

    if (isShuffled) {
      // Generate a random index that's different from the current index
      let previousIndex: React.SetStateAction<number>;
      do {
        previousIndex = Math.floor(Math.random() * shuffledVideos.length);
      } while (previousIndex === currentVideoIndex);

      setCurrentVideoIndex(previousIndex);
    } else {
      // Play the previous video in the list if not the first one
      if (currentVideoIndex > 0) {
        setCurrentVideoIndex(currentVideoIndex - 1);
      }
    }
  };

  const shufflePlaylist = () => {
    const shuffled = [...playlistVideos];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    setShuffledVideos(shuffled);
  };

  const toggleShuffle = () => {
    if (!isShuffled) {
      shufflePlaylist();
      setIsShuffled(true);
    } else {
      setIsShuffled(false);
      setCurrentVideoIndex(0); // Optionally reset to the first video
    }
  };

  const handleEndOfVideo = () => {
    if (autoplayEnabled) {
      handleNextVideo(); // Only move to the next video if autoplay is enabled
    }
  };

  useEffect(() => {
    const videoElement = videoRef?.current;
    if (videoElement) {
      videoElement.addEventListener("ended", handleEndOfVideo);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("ended", handleEndOfVideo);
      }
    };
  }, [videoRef, handleEndOfVideo, autoplayEnabled]);

  useEffect(() => {
    if (videoRef?.current) {
      if (!videoRef.current.error) {
        setVideoError(null);
      }
    }
  }, [currentVideo]);

  return (
    <div
      className={`video-player-container ${
        showControls ? "show-controls" : ""
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={playerContainerRef}
    >
      {loading ? (
        <div className="loading-spinner">
          <CircularProgress sx={{ color: "#EC674C" }} />
        </div>
      ) : videoError ? (
        <div className="video-error">
          <Typography variant="h5" sx={{ color: "white" }} textAlign={"center"}>
            {videoError}
          </Typography>
        </div>
      ) : currentVideo && currentVideo.medias.length ? (
        <video
          id="video-player"
          ref={videoRef}
          src={selectMedia(currentVideo.medias)?.url ?? "placeholder_video.mp4"}
          poster={
            currentVideo?.thumbnails?.[0]?.url ?? "placeholder_poster.png"
          }
          onTimeUpdate={handleRemoveContinueWatching}
          onLoadedMetadata={handleMetadataLoaded}
          onEnded={() => setPlaying(false)}
          onError={handleError} // Catch streaming errors
          controls={false}
          width={"100%"}
          height={"100%"}
        >
          {captionLang === "english" && selectedCaption && (
            <track
              src={selectedCaption}
              kind="subtitles"
              srcLang="en"
              label="ENGLISH"
              default
            />
          )}
          {captionLang === "spanish" && selectedCaption && (
            <track
              src={selectedCaption}
              kind="subtitles"
              srcLang="es"
              label="SPANISH"
              default
            />
          )}
          {captionLang === "french" && selectedCaption && (
            <track
              src={selectedCaption}
              kind="subtitles"
              srcLang="fr"
              label="FRENCH"
              default
            />
          )}
        </video>
      ) : (
        <div className="video-placeholder">
          <Typography variant="h5">
            Video is unavailable. Please select another video.
          </Typography>
        </div>
      )}

      <div className="controls-overlay">
        <div className="video-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 3,
            }}
          >
            <BackButton />
            <Typography variant="h5" sx={{ color: "white", ml: 5 }}>
              {currentVideo?.title}
            </Typography>
          </Box>

          {/* Playlist toggle button */}
          {playMode === "playlist" &&
          playlistVideos &&
          playlistVideos.length ? (
            <Tooltip
              title={isPlaylistPopoverOpen ? "Hide Playlist" : "Show Playlist"}
            >
              <IconButton
                onClick={handleTogglePlaylist}
                className="control-button"
              >
                <ListIcon />
              </IconButton>
            </Tooltip>
          ) : null}

          {/* PlaylistPopover Component */}
          <PlaylistPopover
            videos={isShuffled ? shuffledVideos : playlistVideos}
            isShuffled={isShuffled}
            autoplayEnabled={autoplayEnabled}
            currentVideoIndex={currentVideoIndex}
            anchorEl={playlistEl}
            onClose={handleClosePlaylist}
            onSelectVideo={handleSelectVideo}
            onNext={handleNextVideo} // Add next handler
            onPrevious={handlePreviousVideo} // Add previous handler
            onShuffle={toggleShuffle} // Add shuffle handler
            onToggleAutoplay={() => setAutoplayEnabled(!autoplayEnabled)}
          />
        </div>

        {!videoError && !loading && (
          <div className="control-buttons">
            <IconButton className="playback-button" onClick={handleRewind}>
              <SkipPrevious />
            </IconButton>
            <IconButton
              className="playback-button play-pause-button"
              onClick={togglePlay}
            >
              {playing ? (
                <PauseIcon fontSize="inherit" />
              ) : (
                <PlayArrowIcon fontSize="inherit" />
              )}
            </IconButton>
            <IconButton className="playback-button" onClick={handleFastForward}>
              <SkipNext />
            </IconButton>
          </div>
        )}

        {!videoError && !loading && (
          <div style={{ width: "100%" }}>
            <div className="time-progress-container">
              <div className="time-display">
                <Typography sx={{ color: "white" }}>
                  {formatTime(currentTime)}
                </Typography>
              </div>

              <div className="time-display">
                <Typography sx={{ color: "white" }}>
                  {formatTime(totalDuration)}
                </Typography>
              </div>
            </div>
            <div className="control-bar">
              <Box className="progress-bar">
                <ProgressBar
                  currentTime={currentTime}
                  duration={totalDuration}
                  onProgressChange={handleProgressChange}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                />
              </Box>

              <div className="control-buttons">
                <IconButton
                  className="control-button"
                  onClick={toggleVolumeSlider}
                >
                  <VolumeUp />
                </IconButton>
                <Popover
                  open={isVolumePopoverOpen}
                  anchorEl={volumeAnchorEl}
                  onClose={handleVolumeClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{
                    "&: MuiPopover-paper": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <VolumeSlider
                    volume={volume}
                    onVolumeChange={handleVolumeChange}
                  />
                </Popover>
                {/* <IconButton
                className="control-button"
                onClick={handleSettingsClick}
              >
                <SettingsIcon />
              </IconButton>
              <Popover
                open={isSettingsPopoverOpen}
                anchorEl={settingsAnchorEl}
                onClose={handleSettingsClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              ></Popover> */}
                {currentVideo?.captions?.length > 0 && (
                  <>
                    <IconButton
                      className="control-button"
                      onClick={handleCcClick}
                    >
                      <ClosedCaptionIcon />
                    </IconButton>
                    <Popover
                      open={isCcPopoverOpen}
                      anchorEl={ccAnchorEl}
                      onClose={handleCcClose}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      <MenuList>
                        {currentVideo.captions.map((caption, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => handleCaptionSelect(caption)}
                          >
                            {caption.language}
                          </MenuItem>
                        ))}
                        <MenuItem onClick={() => handleCaptionSelect(null)}>
                          Turn off captions
                        </MenuItem>
                      </MenuList>
                    </Popover>
                  </>
                )}
                <IconButton
                  className="control-button"
                  onClick={toggleFullscreen}
                >
                  {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </div>
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={showContinueDialog}
        onClose={() => setShowContinueDialog(false)}
        aria-labelledby="continue-dialog-title"
        aria-describedby="continue-dialog-description"
      >
        <DialogTitle id="continue-dialog-title">
          {"Continue Watching?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="continue-dialog-description">
            Do you want to continue watching from where you left off or start
            from the beginning?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStartFromBeginning} color="primary">
            Start from Beginning
          </Button>
          <Button
            onClick={handleContinueFromSavedProgress}
            color="primary"
            autoFocus
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={shouldShowDisclaimer}
        onClose={closeDisclaimer}
        aria-labelledby="disclaimer-dialog-title"
        aria-describedby="disclaimer-dialog-description"
      >
        <DialogTitle id="continue-dialog-title">{"Disclaimer"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="continue-dialog-description">
            {currentVideo?.disclaimer ?? ""}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NowPlayingPage;
