import { baseApi } from "./baseApi";
import type {
  CreateProgramInput,
  GetProgramsParams,
  Program,
  ProgramListResponse,
  UploadPictureResponse,
} from "../../types";
import {
  GET_PROGRAMS_ENDPOINT,
  GET_PROGRAM_BY_ID_ENDPOINT,
  CREATE_PROGRAM_ENDPOINT,
  UPDATE_PROGRAM_ENDPOINT,
  DELETE_PROGRAM_ENDPOINT,
  UPLOAD_PICTURE_ENDPOINT,
} from "../../constants/endpoints";

export const programApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrograms: builder.query<ProgramListResponse, GetProgramsParams>({
      query: (params) => ({
        url: GET_PROGRAMS_ENDPOINT,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Program" as const,
                id,
              })),
              { type: "Program", id: "LIST" },
            ]
          : [{ type: "Program", id: "LIST" }],
    }),
    getProgramById: builder.query<{data: Program}, string>({
      query: (program_id) => GET_PROGRAM_BY_ID_ENDPOINT(program_id),
    }),
    createProgram: builder.mutation<{data: Program}, CreateProgramInput>({
      query: (program) => ({
        url: CREATE_PROGRAM_ENDPOINT,
        method: "POST",
        body: program,
      }),
      invalidatesTags: ["Program"],
    }),
    updateProgram: builder.mutation<
      {data: Program, success: boolean},
      Partial<CreateProgramInput> & { program_id: string }
    >({
      query: ({ program_id, ...body }) => ({
        url: UPDATE_PROGRAM_ENDPOINT(program_id),
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { program_id }) => [
        { type: "Program", id: program_id },
      ],
    }),
    deleteProgram: builder.mutation<{ success: boolean }, string>({
      query: (program_id) => ({
        url: DELETE_PROGRAM_ENDPOINT(program_id),
        method: "DELETE",
      }),
      invalidatesTags: (result, error, program_id) => [
        { type: "Program", id: program_id },
      ],
    }),
    uploadProgramPicture: builder.mutation<
      UploadPictureResponse,
      { program_id: string; picture: File }
    >({
      query: ({ program_id, picture }) => {
        const formData = new FormData();
        formData.append("picture", picture);

        return {
          url: UPLOAD_PICTURE_ENDPOINT(program_id),
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, { program_id }) => [
        { type: "Program", id: program_id },
      ],
    }),
    // ... other endpoints
  }),
});

export const {
  useGetProgramsQuery,
  useLazyGetProgramsQuery,
  useGetProgramByIdQuery,
  useCreateProgramMutation,
  useUpdateProgramMutation,
  useDeleteProgramMutation,
  useUploadProgramPictureMutation,
} = programApi;
