import React, { useState } from "react";
import { Card, CardMedia, Chip, Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useBreakpoints from "../../../../../hooks/useBreakPoints";
import { Video } from "../../../../../types";
import VideoPreviewModal from "../../../../../components/VideoPreviewModal";
import { formatVideoLength } from "../../../../../utils/formatVideoLength";

/**
 * @description Creates JSS styles for the RecommendationsCard component.
 * @param {Theme} theme - The MUI theme object to apply theming styles.
 */
const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: "relative",
    backdropFilter: "blur(0px)",
    WebkitBackdropFilter: "blur(0px)",
    "&::before": {
      // Adds a linear gradient overlay to the card
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background:
        "transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 0,
    },
    "&:hover": {
      outline: "4px solid #EC674C",
      outlineOffset: "-2px",
      cursor: "pointer",
    },
  },
  media: {
    height: "100%", // Ensures the media covers the entire height of the card
  },
  infoContainer: {
    // Absolutely positions the info container at the center of the card
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    zIndex: 1,
  },
  chip: {
    "& .MuiChip-label": {
      // Styling for the text inside the chips
      color: "#FFFFFF",
      lineHeight: "50px",
      fontFamily: "Roboto",
      letterSpacing: "0px",
    },
  },
  title: {
    color: "white",
    marginBottom: theme.spacing(1), // Adds bottom margin based on theme spacing
    fontWeight: "bold",
    fontSize: "1.7rem"
  },
  description: {
    textAlign: "left",
    color: "#FFFFFF",
    letterSpacing: "0px",
    lineHeight: "35px",
    fontWeight: "normal",
    opacity: 1,
  },
  chipContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1), // Adds bottom margin for chip container
  },
}));

/**
 * @description Interface for RecommendationsCard props.
 * @property {string} id - The unique identifier for the film.
 * @property {string} filmName - The name of the film.
 * @property {string} category - The category of the film.
 * @property {string} image - The URL of the film's image.
 * @property {string} description - A short description of the film.
 * @property {string} filmLength - The length of the film.
 */
export interface RecommendationsCardProps {
  video: Video;
}

/**
 * @description A card component to display film recommendations.
 * @param {RecommendationsCardProps} props - The props for the component.
 * @returns {React.ReactElement} - A rendered card component.
 */
export default function RecommendationsCard({
  video,
}: RecommendationsCardProps): React.ReactElement {
  const classes = useStyles();
  const { isSm, isMd, isLg, isXl } = useBreakpoints();
  const { title, category, thumbnails, description, medias } = video;
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  // Determines the card's height and width based on the screen size.
  const cardHeight = isXl ? 302 : isLg ? 202 : isMd ? 252 : isSm ? 202 : 202;
  const cardWidth = isXl ? 790 : isLg ? 590 : isMd ? 475 : isSm ? 395 : 300;

  const handleClick = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  const truncateDescription = (text: string, limit: number) =>
    text.length > limit ? `${text.substring(0, limit)}...` : text;

  return (
    <>
      <Card
        className={classes.card}
        sx={{ width: cardWidth, height: cardHeight, borderRadius: "10px" }}
        onClick={handleClick}
      >
        <CardMedia
          className={classes.media}
          image={thumbnails[0]?.url || "/images/thumbnail-placeholder.jpg"}
          title={title}
        />
        <Box className={classes.infoContainer}>
          {/* Film Title */}
          <h2 className={classes.title}>{title}</h2>
          {/* Chips for Category and Film Length */}
          <div className={classes.chipContainer}>
            {category.length
              ? category.map((category, index) => (
                  <Chip
                    key={index}
                    label={category}
                    className={classes.chip}
                    sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
                  />
                ))
              : ""}
            {medias && medias.length ? (
              medias[0].length ? (
                <Chip
                  label={formatVideoLength(medias[0].length)}
                  className={classes.chip}
                  sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          {/* Film Description */}
          <h3 className={classes.description} style={{ marginTop: 0 }}>
            {truncateDescription(
              description,
              isXl || isLg ? 300 : isMd ? 200 : 100
            )}
          </h3>
        </Box>
      </Card>

      <VideoPreviewModal
        open={isPreviewOpen}
        onClose={handleClosePreview}
        video={video}
      />
    </>
  );
}
