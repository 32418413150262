import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Document, Page, pdfjs } from "react-pdf";

// Set the worker source
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

const ViewDocument = ({
  name,
  description,
  fileUrl,
}: {
  name: string;
  description: string;
  fileUrl: string;
}) => {
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) =>
      prevPageNumber < (numPages ?? 1) ? prevPageNumber + 1 : prevPageNumber
    );
  };

  const goToPreviousPage = () => {
    setPageNumber((prevPageNumber) =>
      prevPageNumber > 1 ? prevPageNumber - 1 : prevPageNumber
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        margin: 0.5,
      }}
    >
      <FileIcon sx={{ marginRight: "8px" }} />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {name}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      </Box>
      <IconButton onClick={handleOpen} sx={{ marginLeft: "8px" }}>
        <VisibilityIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <p>
            Page {pageNumber} of {numPages}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={goToPreviousPage} disabled={pageNumber <= 1}>
            Previous
          </Button>
          <Button
            onClick={goToNextPage}
            disabled={pageNumber >= (numPages ?? 1)}
          >
            Next
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ViewDocument;
