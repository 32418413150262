import bigNaassom from "../../assets/big-naassom.png";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  FormHelperText,
  Theme,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { forgotPasswordSchema } from "../../constants/validationSchemas";
import { useFormik } from "formik";
import useIsFireTv from "../../hooks/useIsFireTv";
import StyledTextField from "../../components/Input";
import { useRecoverPasswordMutation } from "../../redux/api/authApi";
import { useAlert } from "../../contexts/AlertContext";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/TFLogo1.png";


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    display: "grid",
    gridTemplateColumns: "1fr", // Single column by default
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr", // Two columns on medium screens and up
    },
  },
  logo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between("xs", "md")]: {
      width: 150,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
  leftColumn: {
    display: "grid",
    gridTemplateRows:
      "auto 1fr" /* Two rows: auto size for the logo, 1fr for the content */,
    height: "100%" /* Full height */,
    overflowY: "auto" /* Keep your existing overflow styles */,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center" /* Center vertically */,
    alignItems: "center" /* Center horizontally */,
    overflowY: "auto" /* If you want to make the content scrollable */,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  greeting: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
      marginTop: theme.spacing(4),
    },
  },
  heading: {
    textAlign: "left",
    width: "100%",
    color: "#121212",
    fontFamily: "Avenir Next LT Pro",
    fontWeight: "bold",
    letterSpacing: 0,
    margin: 0,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },
  subheading: {
    textAlign: "left",
    width: "100%",
    marginBottom: theme.spacing(4),
    color: "#6E6E73",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },
  form: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(5),
    [theme.breakpoints.between("xs", "md")]: {
      width: "90%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "80%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "75%",
    },
  },
  signUpLink: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(5),
  },
  rightColumn: {
    display: "none", // Hide the right column by default
    [theme.breakpoints.up("md")]: {
      display: "block", // Show the right column on medium screens and up
      backgroundImage: `url(${bigNaassom})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100%",
    },
  },
}));

const ForgotPasswordPage = () => {
  const [resendTimer, setResendTimer] = useState(60); // 60 seconds countdown
  const [canResend, setCanResend] = useState(false);
  const [emailSent, setEmailSent] = useState(false); // New state to track if email has been sent
  const theme = useTheme();
  const classes = useStyles();
  const isFireTV = useIsFireTv();
  const { t } = useTranslation();

  const [recoverPassword, { isLoading }] = useRecoverPasswordMutation();
  const { error, success } = useAlert();

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (emailSent && resendTimer > 0 && !canResend) {
      interval = setInterval(() => {
        setResendTimer((resendTimer) => resendTimer - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setCanResend(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [resendTimer, canResend, emailSent]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      try {
        await recoverPassword({ email: values.email }).unwrap();
        success(`A link to reset your password was sent to ${values.email}`);
        setEmailSent(true);
        setResendTimer(60);
        setCanResend(false);
      } catch (err) {
        console.log(err);
        error("Could not send email, try again");
      }
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        {/** Logo  */}
        <div style={{ width: "100%" }}>
          <img
            src={logo}
            alt="Logo"
            className={classes.logo}
          />
        </div>
        <div className={classes.content}>
          {/** Greeting */}
          <div className={classes.greeting}>
            <Typography
              variant="body2"
              sx={{
                fontSize: ["1rem", null, null, "1rem"],
                mb: theme.spacing(4),
              }}
              width={"100%"}
            >
              {t("ForgotPasswordPage.haveAccount")}{" "}
              <Link to="/auth/login" style={{ color: "#EC674C" }}>
                {t("ForgotPasswordPage.loginHere")}
              </Link>
            </Typography>
            <h1 className={classes.heading}>
              {t("ForgotPasswordPage.forgotPassword")}
            </h1>
            <p className={classes.subheading}>
              {t("ForgotPasswordPage.enterEmail")}
            </p>
          </div>
          {isLoading && <CircularProgress />} {/* Display loading indicator */}
          {/** Form */}
          <form onSubmit={handleSubmit} className={classes.form}>
            {/** Email */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <StyledTextField
                label={t("ForgotPasswordPage.emailLabel")}
                id="email"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                margin="none"
                placeholder={t("ForgotPasswordPage.emailPlaceholder")}
                error={touched.email && Boolean(errors.email)}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  sx={{
                    color: "red",
                    fontSize: "12px",
                    margin: 0,
                  }}
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            <Grid container className={classes.signUpLink}>
              <Grid item xs={12} textAlign={"center"}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  size="large"
                  disabled={isLoading} // Disable button while loading
                  sx={{
                    width: "fit-content",
                    background: "#EC674C",
                    opacity: 1,
                    paddingX: theme.spacing(6),
                    paddingy: theme.spacing(1),
                    textTransform: "none",
                    borderRadius: isFireTV ? "40px" : "30px",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      background: "#EC674C", // Change background color on hover
                    },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : emailSent && !canResend ? (
                    t("ForgotPasswordPage.resendIn", { seconds: resendTimer })
                  ) : (
                    t("ForgotPasswordPage.getCode")
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
          {/** Create Account Link */}
          <div className={classes.footer}>
            <Typography
              variant="body2"
              color={"#6E6E73"}
              width={"100%"}
              mt={2}
              textAlign={"center"}
              sx={{
                opacity: 0.5,
                fontSize: ["1rem", null, null, "1rem"],
              }}
            >
              Licensed Professional Clinical Counseling Corporation
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.rightColumn}></div>
    </div>
  );
};

export default ForgotPasswordPage;
