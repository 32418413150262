import React from "react";
import { List, ListItemText, Typography } from "@mui/material";
import { StyledListItemButton } from "../../../../components/ListButton";
import CheckIcon from '@mui/icons-material/Check';
import { videoResolutions } from "../../../../constants/constants";

interface VideoResolutionSettingsProps {
  selectedResolution: string;
  onResolutionSelect: (code: string) => void;
}

const VideoResolutionSettings = ({
  selectedResolution,
  onResolutionSelect,
}: VideoResolutionSettingsProps) => (
  <>
    <Typography variant="h4" mb={4}>Video Resolution</Typography>
    <List sx={{ width: "60%" }}>
      {videoResolutions.map((resolution) => (
        <StyledListItemButton
          key={resolution.code}
          selected={selectedResolution === resolution.code}
          onClick={() => onResolutionSelect(resolution.code)}
        >
          <ListItemText primary={resolution.name} />
          {selectedResolution === resolution.code && <CheckIcon/>}
        </StyledListItemButton>
      ))}
    </List>
  </>
);

export default VideoResolutionSettings;
