import React, { useState } from "react";
import { SwiperSlide } from "swiper/react";
import SwiperScroll from "../../../../components/SwiperScroll";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import RecommendationsCard from "./RecommendationsCard";
import { Video } from "../../../../types";
import { useTranslation } from "react-i18next";
import { useGetRecommendedVideosQuery } from "../../../../redux/api/videoApi";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
}));

type RecommendationsCard = {
  id: string;
  filmName: string;
  category: string;
  image: string;
  description: string;
  filmLength: string;
};

interface RecommendationsProps {
  videos: Video[];
}

const Recommendations = ({ videos: initialVideos }: RecommendationsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [videos, setVideos] = useState<Video[]>(initialVideos);
  const [page, setPage] = useState(2); // Start at the next page after initial load
  const [shouldLoadMore, setShouldLoadMore] = useState(true);


  const { data: newRecommendations, isFetching } = useGetRecommendedVideosQuery(
    {
      page_size: 10,
      page_number: page,
      sort: "asc",
      published: true,
    }
  );

  const loadMoreVideos = () => {
    if (shouldLoadMore && !isFetching && newRecommendations) {
      setVideos((prevVideos) => [
        ...prevVideos,
        ...newRecommendations.data.filter(
          (newVideo) => !prevVideos.some((video) => video.id === newVideo.id)
        ),
      ]);
      setPage((prevPage) => prevPage + 1);
      // Update shouldLoadMore based on whether more videos can be loaded
      setShouldLoadMore(videos.length + newRecommendations.data.length < newRecommendations.count);
    }
  };

  return (
    <div className={classes.container}>
      <h2 style={{ marginBottom: 2, color: "#121212", fontWeight: "normal" }}>
        {t("HomePage.RecommendationsTitle")}
      </h2>
      <SwiperScroll onReachEnd={shouldLoadMore ? loadMoreVideos : undefined}>
        {videos.map((video) => (
          <SwiperSlide key={video._id} style={{width: "auto"}}>
            <RecommendationsCard video={video} />
          </SwiperSlide>
        ))}
      </SwiperScroll>
    </div>
  );
};

export default Recommendations;
