import { baseApi } from "./baseApi";
import { GET_SPONSOR_BY_ID_ENDPOINT } from "../../constants/endpoints";
import { SponsorResponse } from "../../types";

export const sponsorApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSponsor: builder.query<SponsorResponse, string>({
      query: (sponsorId) => GET_SPONSOR_BY_ID_ENDPOINT(sponsorId),
    }),
  }),
});

export const { useGetSponsorQuery, useLazyGetSponsorQuery } = sponsorApi;