import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItemText,
  DialogActions,
  Button,
  CircularProgress,
  ListItemButton,
  useTheme,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CreatePlaylistDialog from "../CreatePlaylistModal";
import {
  useGetMyPlaylistsQuery,
  useUpdatePlaylistMutation,
} from "../../redux/api/playlistApi";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Playlist } from "../../types";
import { useAlert } from "../../contexts/AlertContext";
import { useTranslation } from "react-i18next";

enum PlaylistStatus {
  Idle = "idle",
  Loading = "loading",
  Added = "added",
}

interface AddToPlaylistModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
  _id: string;
}

interface AddingStatus {
  [key: string]: PlaylistStatus;
}

const AddToPlaylistModal: React.FC<AddToPlaylistModalProps> = ({
  open,
  onClose,
  id,
}) => {
  const theme = useTheme();
  const [addingStatus, setAddingStatus] = useState<AddingStatus>({});
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] =
    useState(false);
  const { currentProgram } = useSelector((state: RootState) => state.program);
  const { error, success } = useAlert();
  const { t } = useTranslation();

  const {
    data: playlistsData,
  } = useGetMyPlaylistsQuery({
    page_size: 10,
    page_number: 1,
    program_id: currentProgram?.id,
    sort: "asc",
    field: "created_at",
  });

  const [updatePlaylist] = useUpdatePlaylistMutation();

  const handleOpenCreatePlaylistModal = () => {
    setIsCreatePlaylistModalOpen(true);
  };

  const handleCloseCreatePlaylistModal = () => {
    setIsCreatePlaylistModalOpen(false);
  };

  const handleAddToPlaylist = async (playlist: Playlist) => {
    setAddingStatus({ ...addingStatus, [playlist.id]: PlaylistStatus.Loading });
  
    try {
      // Check if the video is already in the playlist
      const isVideoAlreadyInPlaylist = playlist.videos.some((video) => video.id === id);
  
      // If the video is already in the playlist, do nothing
      if (isVideoAlreadyInPlaylist) {
        setAddingStatus({ ...addingStatus, [playlist.id]: PlaylistStatus.Idle });
        success(t("Video already in playlist"));
        onClose();
        return; // Exit the function
      }
  
      // If the video is not in the playlist, proceed with adding it
      const updatedVideos = [...playlist.videos.map((video) => ({ id: video.id })), { id }];
  
      // Perform the update
      await updatePlaylist({
        playlistId: playlist.id,
        videos: updatedVideos,
      }).unwrap();
  
      setAddingStatus({ ...addingStatus, [playlist.id]: PlaylistStatus.Added });
  
      // Reset the status after a delay
      setTimeout(() => {
        setAddingStatus({
          ...addingStatus,
          [playlist.id]: PlaylistStatus.Idle,
        });
      }, 2000);
      success(t("AddToPlaylistModal.SuccessAddingToPlaylist"));
  
    } catch (err) {
      // Handle error
      console.log(err);
      setAddingStatus({ ...addingStatus, [playlist.id]: PlaylistStatus.Idle });
      error(t("AddToPlaylistModal.ErrorAddingToPlaylist"));
    }
  };
  

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>{t("AddToPlaylistModal.Title")}</DialogTitle>
        <DialogContent>
          <List style={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: 0 }}>
            {playlistsData?.data.map((playlist, index) => (
              <ListItemButton
                key={playlist.id}
                onClick={() => handleAddToPlaylist(playlist)}
                disabled={
                  addingStatus[playlist.name] === PlaylistStatus.Loading
                }
                style={{
                  borderBottom:
                    index !== playlistsData.data.length - 1
                      ? "1px solid rgba(0, 0, 0, 0.12)"
                      : "", // Add border bottom to each button except the last
                }}
              >
                <ListItemText primary={playlist.name} />
                {addingStatus[playlist.name] === PlaylistStatus.Loading ? (
                  <CircularProgress size={24} />
                ) : addingStatus[playlist.name] === PlaylistStatus.Added ? (
                  <CheckIcon />
                ) : null}
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            sx={{
              fontSize: ["0.5rem", null, null, "1rem"],
              background: "#EC674C",
              mx: theme.spacing(2),
              textTransform: "none",
              borderRadius: theme.shape.borderRadius,
              "&:hover": {
                background: "#EC674C",
              },
            }}
            onClick={handleOpenCreatePlaylistModal}
          >
            {t("AddToPlaylistModal.CreatePlaylistButton")}
          </Button>
        </DialogActions>
      </Dialog>
      <CreatePlaylistDialog
        open={isCreatePlaylistModalOpen}
        onClose={handleCloseCreatePlaylistModal}
      ></CreatePlaylistDialog>
    </>
  );
};

export default AddToPlaylistModal;
