// SubscriptionPage.tsx

import React from "react";
import {
  Box,
  Grid,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetAllSubscriptionPlansQuery } from "../../redux/api/subscriptionApi";
import SubscriptionPlanCard from "./components/SubscriptionPlanCard"; // Adjust the import path
import LoadingView from "../../components/LoadingView";
import ErrorView from "../../components/ErrorView";
import { makeStyles } from "@mui/styles";
import logo from "../../assets/TFLogo1.png";


const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.between("xs", "md")]: {
      width: 150,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
}));

const SubscriptionPage = () => {
  const theme = useTheme();
  const classes = useStyles();

  const {
    data: plansData,
    isLoading,
    isError,
  } = useGetAllSubscriptionPlansQuery({
    page_size: 10,
    page_number: 1,
    active: true,
  });

  if (isLoading) return <LoadingView />;
  if (isError)
    return (
      <ErrorView message="Error loading subscription plans." type="error" />
    );

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Centers vertically if there's enough space
        alignItems: "center", // Centers horizontally
        [theme.breakpoints.up("md")]: {
          paddingX: theme.spacing(20), // Horizontal padding for larger screens
          paddingY: theme.spacing(10),
        },
        paddingX: theme.spacing(5), // Horizontal padding for smaller screens
        paddingY: theme.spacing(5),
      }}
    >
      <div style={{ width: "100%" }}>
        <img
          src={logo}
          alt="Logo"
          className={classes.logo}
        />
      </div>
      <Typography
        variant="h2"
        component="h1"
        mb={theme.spacing(5)}
        sx={{ color: "#121212" }}
        textAlign={"center"}
      >
        Select Your Plan
      </Typography>
      <Typography
        variant="body1"
        component="p"
        mb={theme.spacing(5)}
        sx={{ color: "#121212", width: "100%" }}
        textAlign={"center"}
      >
        Discover the ideal subscription plan tailored to fit your lifestyle and
        needs. Whether you&apos;re looking for a flexible month-to-month option,
        a cost-effective quarterly plan, or the best value with a year-long
        commitment, we have you covered. Each plan is designed to provide you
        with exclusive features and premium content, ensuring you get the most
        out of your experience. Browse through our options and find the perfect
        match for you!
      </Typography>
      <Grid
        container
        spacing={5}
        justifyContent="center"
        alignItems="stretch" // Ensures all Grid items stretch to the same height
      >
        {plansData?.data.map((plan) => (
          <Grid
            item
            key={plan.id}
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: "flex", // Enables Flexbox on Grid item
            }}
          >
            <SubscriptionPlanCard plan={plan} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SubscriptionPage;
