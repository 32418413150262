import React, { useRef } from "react";
import { Avatar, Badge, IconButton, Theme, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";

/**
 * @description Creates JSS styles for the ImagePicker component.
 * @param {Theme} theme - The MUI theme object for theming styles.
 */
const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    // Styles for the avatar image (commented out if not needed)
  },

  fileInput: {
    display: "none", // Hide the file input for custom styling
  },

  editButton: {
    backgroundColor: theme.palette.common.white + " !important",
    color: "#EC674C",
    border: `2px solid #EC674C !important`,
    "&:hover": {
      backgroundColor: theme.palette.grey[100] + " !important",
    },
    borderRadius: "50%",
    padding: "4px",
    boxShadow: theme.shadows[1], // Optional shadow effect
  },
}));

/**
 * @description Props interface for ImagePicker component.
 * @typedef {Object} ImagePickerProps
 * @property {string | ArrayBuffer} image - The current image source.
 * @property {(image: string | ArrayBuffer) => void} onImageChange - Callback function to handle image changes.
 */
interface ImagePickerProps {
  image: string | ArrayBuffer;
  onImageChange: (image:  string | ArrayBuffer, file: File | null) => void;
}

/**
 * @description ImagePicker component for handling image uploads and edits.
 * @param {ImagePickerProps} props - Props for the ImagePicker component.
 * @returns {React.ReactElement} - Rendered component.
 */
const ImagePicker = ({ image, onImageChange }: ImagePickerProps): React.ReactElement => {
  const classes = useStyles();
  const theme = useTheme();

  // Reference for the hidden file input element
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const [selectedFile, setSelectedFile] = useState<File | null>(null);


  /**
   * @description Handles click event for editing the image.
   * Triggers the file input click event.
   */
  const handleImageEdit = () => {
    fileInputRef.current!.click();
  };

  /**
   * @description Handles change event for file input.
   * Reads the selected file and updates the image.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The event object for the file input change.
   */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      if (file.type.startsWith("image")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          onImageChange(reader.result || "", file);
          // setSelectedFile(file);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        className={classes.fileInput}
      />
      <Badge
        overlap="rectangular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <IconButton
            size="small"
            onClick={handleImageEdit}
            className={classes.editButton}
          >
            <EditIcon color="action" style={{ color: "#EC674C" }} />
          </IconButton>
        }
        sx={{ "--Badge-paddingX": "0px" }}
      >
        <Avatar
          variant="rounded"
          alt="Program Image"
          src={typeof image === "string" ? image : undefined}
          className={classes.avatar}
          sx={{
            width: theme.spacing(20),
            height: theme.spacing(20),
          }}
        />
      </Badge>
    </>
  );
};

export default ImagePicker;
