import React, { FunctionComponent } from "react";
import Typography from "@mui/material/Typography";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";

interface PaginationControlProps {
  rowsPerPage: number;
  totalCount: number;
  pageNo: number;
  disablePrevBtn: boolean;
  disableNextBtn: boolean;
  itemsPerPageOptions?: number[];
  handleChangeRowsPerPage: (event: unknown, newValue: number | null) => void;
  handleChangePage: (newPage: number) => void;
  stickyBottom?: boolean; // New prop to determine if pagination should stick to the bottom
}

const PaginationControl: FunctionComponent<PaginationControlProps> = ({
  rowsPerPage,
  totalCount,
  pageNo,
  disablePrevBtn,
  disableNextBtn,
  itemsPerPageOptions = [5, 10, 25],
  stickyBottom,
  handleChangeRowsPerPage,
  handleChangePage,
}) => {
  const getLabelDisplayedRowsTo = () =>
    pageNo * rowsPerPage > totalCount ? totalCount : pageNo * rowsPerPage;

  function labelDisplayedRows({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) {
    return ` ${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  return (
    <Box
      className="flex flex-row grow justify-between items-center cursor-default"
      sx={{
        ...(stickyBottom && {
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
          zIndex: 10,
          padding: 2,
        }),
      }}
    >
      <div className="grow"></div>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          justifyContent: "flex-end",
        }}
      >
        <Box>
          <FormLabel sx={{mr: 4}}>Items per page:</FormLabel>
          <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
            {itemsPerPageOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Typography textAlign="center" sx={{ minWidth: 80 }}>
          {labelDisplayedRows({
            from: totalCount === 0 ? 0 : (pageNo - 1) * rowsPerPage + 1,
            to: getLabelDisplayedRowsTo(),
            count: totalCount === -1 ? -1 : totalCount,
          })}
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => handleChangePage(pageNo - 1)}
            disabled={disablePrevBtn}
            sx={{
              backgroundColor: disablePrevBtn ? "initial" : "#EC674C",
            }}
          >
            <KeyboardArrowLeftIcon
              sx={{ color: disablePrevBtn ? "" : "white" }}
            />
          </IconButton>

          <IconButton
            onClick={() => handleChangePage(pageNo + 1)}
            disabled={disableNextBtn}
            sx={{
              backgroundColor: disableNextBtn ? "initial" : "#EC674C",
            }}
          >
            <KeyboardArrowRightIcon
              sx={{ color: disableNextBtn ? "" : "white" }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PaginationControl;
