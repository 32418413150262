// CompanySlice.ts
import { Company, UpdateCompanyPayload } from "../../types";
import { baseApi } from "./baseApi";
import {
  GET_CURRENT_COMPANY_ENDPOINT,
  UPDATE_CURRENT_COMPANY_ENDPOINT,
  DELETE_CURRENT_COMPANY_ENDPOINT,
  CONFIRM_COMPANY_ENDPOINT,
  SEND_CONFIRMATION_CODE_ENDPOINT,
  CONFIRM_PASSWORD_ENDPOINT,
} from "../../constants/endpoints";

export const companyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentCompany: builder.query<{data: Company}, void>({
      query: () => ({
        url: GET_CURRENT_COMPANY_ENDPOINT,
        method: "GET",
      }),
      providesTags: (result) =>
        result ? [{ type: "Company", id: result.data.id }] : ["Company"],
    }),
    updateCurrentCompany: builder.mutation<Company, {data: UpdateCompanyPayload, headers?: Record<string, string>}>({
      query: ({ data, headers }) => ({
        url: UPDATE_CURRENT_COMPANY_ENDPOINT,
        method: "PATCH",
        body: data,
        headers: headers, // include headers in the request
      }),
      invalidatesTags: [{ type: "Company" }],
    }),
    deleteCurrentCompany: builder.mutation<
      { success: boolean; data: Company },
      void
    >({
      query: () => ({
        url: DELETE_CURRENT_COMPANY_ENDPOINT,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Company" }],
    }),
    sendConfirmationCode: builder.mutation<{message: string}, { email: string }>({
      query: (params) => ({
        url: SEND_CONFIRMATION_CODE_ENDPOINT,
        method: "POST",
        params,
      }),
    }),
    
    confirmCompany: builder.mutation<{message: string}, { code: string }>({
      query: (body) => ({
        url: CONFIRM_COMPANY_ENDPOINT,
        method: "POST",
        body,
      }),
    }),
    verifyPassword: builder.mutation<{result: boolean}, {password: string}>({
      query: (body) => ({
        url: CONFIRM_PASSWORD_ENDPOINT,
        method: "POST",
        body
      })
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCurrentCompanyQuery,
  useUpdateCurrentCompanyMutation,
  useDeleteCurrentCompanyMutation,
  useConfirmCompanyMutation,
  useSendConfirmationCodeMutation,
  useVerifyPasswordMutation
} = companyApi;
