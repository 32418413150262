import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';

// Import common files
import commonEN from './locales/en/common.json';
import commonES from './locales/es/common.json';
import commonFR from './locales/fr/common.json';

// ... other imports


// the translations
const resources = {
  en: {
    translation: translationEN,
    common: commonEN,
  },
  es: {
    translation: translationES,
    common: commonES,
  },
  fr: {
    translation: translationFR,
    common: commonFR,
  },
};


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    // keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'translation', // default namespace
    ns: ['translation', 'common'], // list of namespaces
  });

export default i18n;
