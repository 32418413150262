import React from "react";
import {
  CardActionArea,
  CardMedia,
  Card,
  Typography,
  Theme,
  useTheme,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Program } from "../../../../types";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";

interface ProgramCardProps {
  program: Program;
  mode: "select" | "manage";
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.between("xs", "md")]: {
      width: 241,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 250,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
  heading: {
    margin: 0,
    textAlign: "center",
    color: "#121212",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },
  subheading: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
    color: "#121212",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },
  card: {
    width: "100%", // This makes the card take the full width of the container
    // Using a pseudo-element to create an aspect ratio of 1:1
    "&::before": {
      content: '""',
      display: "block",
      paddingTop: "100%", // This creates the square shape
    },
    position: "relative", // Needed for absolute positioning of the CardMedia
  },
  cardMedia: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: "100%", // This will make the CardMedia fill the entire space of the card
    width: "100%",
  },
  cardName: {
    marginTop: theme.spacing(1),
    textAlign: "center", // This will center the text below the image
  },
  addActionCard: {
    width: "100%",
    "&::before": {
      content: '""',
      display: "inline",
      paddingTop: "100%",
    },
    position: "relative",
    textAlign: "center",
  },
  addButton: {
    width: "100%",
    // Center the content
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // Set the height to match other cards (adjust the value as needed)
    height: "100%", // Assuming other cards have a height that fits the content
    border: "1px dashed",
    borderColor: theme.palette.action.disabled,
    borderRadius: theme.shape.borderRadius,
  },
  editIcon: {
    position: "absolute", // This positions the icon absolutely within the relative container
    top: "50%", // This sets the top position of the icon to the center
    left: "50%", // This sets the left position of the icon to the center
    transform: "translate(-50%, -50%)", // This adjusts the icon to exactly center
    color: "white", // Set the color to match your theme
    fontSize: 30, // Adjust icon size as needed
  },
  addIcon: {
    // If you need to adjust the size, set it here
    fontSize: "large", // Adjust size as needed
  },
}));

const ProgramCard = ({ program, mode, onClick }: ProgramCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const placeholderImage =
    "https://www.treasury.gov.ph/wp-content/uploads/2022/01/male-placeholder-image.jpeg";

  const renderCardContent = () => {
    return (
      <CardActionArea className={classes.card} onClick={onClick}>
        <CardMedia
          className={classes.cardMedia}
          component="img"
          image={program.picture?.url || placeholderImage}
          alt={program.nickname}
        />
        {mode === "manage" && <EditIcon className={classes.editIcon} />}
      </CardActionArea>
    );
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      {renderCardContent()}
      <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
        {program.pin && (
          <LockIcon
            sx={{
              fontSize: "1rem",
              marginRight: theme.spacing(1),
              color: "#EC674C",
            }}
          />
        )}
        <Typography variant="body2" className={classes.cardName}>
          {program.nickname}
        </Typography>
      </Box>
    </Card>
  );
};

export default ProgramCard;
