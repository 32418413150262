import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Button,
  Container,
  useMediaQuery,
  Theme,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import logo from "../../assets/TFLogo1.png";

// Custom Hooks and Contexts
import useBreakpoints from "../../hooks/useBreakPoints";
import { useAlert } from "../../contexts/AlertContext";

// Redux Store and API Hooks
import { RootState } from "../../redux/store";
import {
  setCompanyConfirmed,
  setFeatures,
  setSubscriptionValid,
  updateUser,
  setFreeTrialBannerDismissed,
} from "../../redux/state/userSlice";
import { setCurrentProgram } from "../../redux/state/programSlice";
import {
  useConfirmCompanyMutation,
  useGetCurrentCompanyQuery,
  useSendConfirmationCodeMutation,
} from "../../redux/api/companyApi";
import { useLazyGetProgramsQuery } from "../../redux/api/programApi";
import { useLazyGetSubscriptionQuery } from "../../redux/api/subscriptionApi";

// Types and Models
import { Program } from "../../types";

// Components
import PasscodeModal from "../../components/PasscodeModal";
import ConfirmAccountModal from "../../components/ConfirmAccountModal";
import SubscriptionExpiredDialog from "../../components/SubscriptionExpiryDialog";
import ProgramCard from "./components/ProgramCard";
import ErrorView from "../../components/ErrorView";
import LoadingView from "../../components/LoadingView";
import FreeTrialBanner from "../../components/FreeTrialBanner";
import MobileAppModal from "../../components/MobileAppModal";
import { useIsMobile } from "../../hooks/useIsMobile";
import TopLevelBackButton from "../../components/TopLevelBackButton";

const MAX_PROGRAMS = 5;

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between("xs", "md")]: {
      width: 150,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
  heading: {
    margin: 0,
    textAlign: "center",
    color: "#121212",
    fontFamily: "Avenir Next LT Pro",
    fontWeight: "bold",
    letterSpacing: 0,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },
  subheading: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
    color: "#121212",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },
  card: {
    width: "100%", // This makes the card take the full width of the container
    // Using a pseudo-element to create an aspect ratio of 1:1
    "&::before": {
      content: '""',
      display: "block",
      paddingTop: "100%", // This creates the square shape
    },
    position: "relative", // Needed for absolute positioning of the CardMedia
  },
  cardMedia: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: "100%", // This will make the CardMedia fill the entire space of the card
    width: "100%",
  },
  cardName: {
    marginTop: theme.spacing(1),
    textAlign: "center", // This will center the text below the image
  },
  addActionCard: {
    width: "100%",
    "&::before": {
      content: '""',
      display: "inline",
      paddingTop: "100%",
    },
    position: "relative",
    textAlign: "center",
  },
  addButton: {
    width: "100%",
    // Center the content
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // Set the height to match other cards (adjust the value as needed)
    height: "100%", // Assuming other cards have a height that fits the content
    border: "1px dashed",
    borderColor: theme.palette.action.disabled,
    borderRadius: theme.shape.borderRadius,
  },
  editIcon: {
    position: "absolute", // This positions the icon absolutely within the relative container
    top: "50%", // This sets the top position of the icon to the center
    left: "50%", // This sets the left position of the icon to the center
    transform: "translate(-50%, -50%)", // This adjusts the icon to exactly center
    color: "white", // Set the color to match your theme
    fontSize: 30, // Adjust icon size as needed
  },
  addIcon: {
    // If you need to adjust the size, set it here
    fontSize: "large", // Adjust size as needed
  },
}));

const SelectProgramPage = ({ mode }: { mode: "select" | "manage" }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isXs } = useBreakpoints();
  const { success, error } = useAlert();

  // Local State
  const [isPasscodeModalOpen, setPasscodeModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isSubscriptionExpiryModalOpen, setSubscriptionExpiryModalOpen] =
    useState(false);
  // const [cancellationNotificationOpen, setCancellationNotificationOpen] =
  //   useState(false);
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
  const [temporaryPin, setTemporaryPin] = useState<string | null>(null);
  const [isMobileAppModalOpen, setMobileAppModalOpen] = useState(false); // State for mobile app modal

  // Redux State
  const {
    loading: programLoading,
    error: programError,
    currentProgram,
  } = useSelector((state: RootState) => state.program);

  const userState = useSelector((state: RootState) => state.user);
  const { isCompanyConfirmed, isSubscriptionValid } = userState;

  // API Hooks
  const {
    data: companyResponse,
    isLoading: isLoadingUser,
    refetch,
  } = useGetCurrentCompanyQuery();

  const [
    triggerGetPrograms,
    { data: programs, isLoading: isLoadingPrograms, isError: isProgramsError },
  ] = useLazyGetProgramsQuery();

  const [
    triggerGetSubscription,
    { isLoading: isLoadingSubscription, isError: isSubscriptionError },
  ] = useLazyGetSubscriptionQuery();

  const [sendCode, { isLoading: isSending }] =
    useSendConfirmationCodeMutation();

  const [confirmAccount, { isLoading: isConfirming }] =
    useConfirmCompanyMutation();

  const handleProgramClick = (program: Program) => {
    if (program) {
      if (program.pin) {
        setTemporaryPin(program.pin);
        setPasscodeModalOpen(true);
      } else {
        navigateToDashboard(program);
      }
    }
  };

  const handleNavigateToEdit = (program: Program) => {
    if (program) {
      if (program.pin) {
        setTemporaryPin(program.pin);
        setSelectedProgram(program);
        setPasscodeModalOpen(true);
      } else {
        setSelectedProgram(program);
        navigate(`/programs/update/${program.id}`);
      }
    }
  };

  const navigateToDashboard = (program: Program) => {
    // logic to navigate to dashboard
    if (program) {
      navigate(`/browse`);
    }
  };

  const handlePrograms = (program: Program) => {
    if (!isCompanyConfirmed) {
      setConfirmModalOpen(true);
      return;
    }

    if (!isSubscriptionValid) {
      navigate("/subscription/plans");
      return;
    }
    if (program) {
      if (mode === "select") {
        const currentProgram: Program = {
          nickname: program.nickname,
          company_id: program.company_id,
          created_at: program.created_at,
          picture: program.picture,
          id: program.id,
          language: program.language,
        };
        dispatch(setCurrentProgram(currentProgram));
        if (isMobile) {
          // Show mobile app modal if on mobile
          setMobileAppModalOpen(true);
        } else {
          handleProgramClick(program);
        }
        // handleProgramClick(program);
      } else if (mode === "manage") {
        handleNavigateToEdit(program);
      }
    }
  };

  const validatePasscode = (pin: string, enteredPin: string) => {
    return enteredPin === pin;
  };

  const handlePasscodeSubmit = (passcode: string) => {
    // You would have some logic to validate the passcode here
    const program = mode === "select" ? currentProgram : selectedProgram;

    // Assuming you have a validatePasscode function that returns a boolean
    const isValid = temporaryPin
      ? validatePasscode(temporaryPin, passcode)
      : false;
    if (program) {
      if (isValid) {
        setTemporaryPin(null);
        setPasscodeModalOpen(false);
        if (mode === "select") {
          navigateToDashboard(program);
        } else {
          navigate(`/programs/update/${program?.id}`);
        }
      } else {
        error(t("SelectProgramPage.incorrectPasscode"));
      }
    }
  };

  const handleCloseModal = () => {
    setPasscodeModalOpen(false);
  };

  // Handle rendering of programs
  const renderPrograms = () => {
    return programs?.data.map((program) => (
      <Grid item key={program.id} xs={6} sm={4} md={3} lg={2}>
        <ProgramCard
          program={program}
          mode={mode}
          onClick={() => handlePrograms(program)}
        />
      </Grid>
    ));
  };

  // Modify button handlers to use flags
  const handleAddProgram = () => {
    if (!isCompanyConfirmed) {
      setConfirmModalOpen(true);
      return;
    }

    if (!isSubscriptionValid) {
      navigate("/subscription/plans");
      return;
    }

    if (mode === "select") {
      navigate("create");
    } else {
      navigate("/programs/create");
    }
  };

  const handleManagePrograms = () => {
    if (!isCompanyConfirmed) {
      setConfirmModalOpen(true);
      return;
    }

    if (!isSubscriptionValid) {
      navigate("/subscription/plans");
      return;
    }

    navigate("manage-programs");
  };

  const handleResendCode = useCallback(async () => {
    if (companyResponse?.data) {
      try {
        const response = await sendCode({
          email: companyResponse.data.email,
        }).unwrap();
        success(response.message);
      } catch (err) {
        console.log(err);
        error("Could not send code, try again");
      }
    } else {
      error("Try again later");
    }
  }, [companyResponse?.data, sendCode, success, error]);

  const handleConfirmationSubmit = async (code: string) => {
    try {
      const response = await confirmAccount({ code }).unwrap();
      success(response.message);
      setConfirmModalOpen(false);
      refetch(); // Fetch updated company data
    } catch (err) {
      console.log(err);
      error("Could not confirm account, resend code");
    }
  };

  // Function to check subscription status
  const checkSubscription = useCallback(async () => {
    const subscriptionId = companyResponse.data.stripe.subscription;

    try {
      const subscription = await triggerGetSubscription(
        subscriptionId
      ).unwrap();

      if (subscription.data) {
        const currentDate = new Date();
        const expiresAt = subscription.data.expires_at
          ? new Date(subscription.data.expires_at)
          : null;
        // const canceledAt = subscription.data.canceled_at
        // ? new Date(subscription.data.canceled_at)
        // : null;
        const freeTrialEndDate = subscription.data.free_trial
          ? new Date(subscription.data.free_trial)
          : null;

        // Determine if the subscription is valid
        let isValidSubscription = false;
        if (subscription.data.status === "cancel") {
          if (expiresAt && currentDate < expiresAt) {
            // Subscription canceled but still active until expiresAt
            isValidSubscription = true;
          }
          // else if (canceledAt && expiresAt && expiresAt ===  canceledAt) {

          // }
        } else if (
          freeTrialEndDate &&
          currentDate < freeTrialEndDate &&
          subscription.data.status === "trial"
        ) {
          // Free trial is active
          isValidSubscription = true;
          dispatch(setFreeTrialBannerDismissed(false));
        } else if (subscription.data.status === "active") {
          if (expiresAt && currentDate < expiresAt) {
            // Subscription is active and not expired
            isValidSubscription = true;
          }
        }

        if (isValidSubscription) {
          dispatch(setSubscriptionValid(true));
          dispatch(
            setFeatures({
              devices: subscription.data.details.devices,
              features: subscription.data.details,
              free_trial: freeTrialEndDate
                ? currentDate < freeTrialEndDate &&
                  subscription.data.status === "trial"
                : false,
              free_trial_end_date: freeTrialEndDate
                ? freeTrialEndDate.toISOString()
                : null,
            })
          );
        } else {
          // Update Redux store with subscription features and free trial info
          dispatch(
            setFeatures({
              devices: 0,
              features: null,
              free_trial: null,
              free_trial_end_date: null,
            })
          );
          dispatch(setFreeTrialBannerDismissed(null));
          dispatch(setSubscriptionValid(false));
          navigate("/subscription/plans");
        }
      } else {
        dispatch(setSubscriptionValid(false));
        console.log("No subscription dat");
        navigate("/subscription/plans");
      }
    } catch (err) {
      dispatch(setSubscriptionValid(false));
      console.log(err);
      navigate("/subscription/plans");
    }
  }, [companyResponse, triggerGetSubscription, navigate, dispatch]);

  useEffect(() => {
    // First, check if the account needs confirmation
    const checkAndConfirmAccount = async () => {
      if (companyResponse?.data && !companyResponse.data.confirmed) {
        setConfirmModalOpen(true);
      }
    };

    checkAndConfirmAccount();
  }, [companyResponse?.data]);

  // useEffect(() => {
  //   if (companyResponse?.data) {
  //     dispatch(updateUser(companyResponse.data));

  //     if (companyResponse.data.confirmed) {
  //       dispatch(setCompanyConfirmed(true));
  //     } else {
  //       dispatch(setCompanyConfirmed(false));
  //       setConfirmModalOpen(true);
  //     }
  //   }
  // }, [companyResponse?.data, dispatch]);

  useEffect(() => {
    if (companyResponse?.data) {
      dispatch(updateUser(companyResponse.data));

      if (companyResponse.data.confirmed) {
        dispatch(setCompanyConfirmed(true));

        if (companyResponse.data.stripe?.subscription) {
          // Subscription ID exists, proceed to check subscription
          checkSubscription();
        } else {
          // No subscription ID, navigate to plans page
          dispatch(setSubscriptionValid(false));
          navigate("/subscription/plans");
        }
      } else {
        dispatch(setCompanyConfirmed(false));
        setConfirmModalOpen(true);
      }
    }
  }, [companyResponse?.data, dispatch, navigate, checkSubscription]);

  useEffect(() => {
    // Fetching programs effect based on company ID
    if (companyResponse?.data?.id) {
      triggerGetPrograms({
        page_size: 10,
        page_number: 1,
        sort: "asc",
        field: "created_at",
        company_id: companyResponse.data.id,
      });
    }
  }, [companyResponse?.data?.id, triggerGetPrograms]);

  const isLoading =
    isLoadingUser ||
    isLoadingPrograms ||
    programLoading ||
    isLoadingSubscription;

  const errorMessage = isProgramsError
    ? t("SelectProgramPage.errorLoadingPrograms")
    : programError
    ? programError
    : "";

  if (isLoading) {
    return <LoadingView />;
  }

  if (isProgramsError || isSubscriptionError) {
    return (
      <ErrorView message={errorMessage || "Subscription Error"} type="error" />
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: theme.spacing(5),
        position: "relative",
      }}
    >
      {mode === "manage" ? <TopLevelBackButton /> : null}
      <Box sx={{ alignSelf: "flex-start" }}>
        <img src={logo} alt="Logo" className={classes.logo} />
      </Box>
      <Container sx={{ mb: "20%" }}>
        <h1 className={classes.heading}>
          {mode === "select"
            ? t("SelectProgramPage.selectProgramHeading")
            : t("SelectProgramPage.manageProgramHeading")}
        </h1>

        <p className={classes.subheading}>
          {t("SelectProgramPage.subheading")}
        </p>

        <Grid container spacing={2} justifyContent="center">
          {renderPrograms()}
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: !isXs ? "row" : "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            marginY: theme.spacing(4),
          }}
        >
          {mode === "select" && (
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              sx={{
                width: "fit-content",
                fontSize: isMdUp ? "1rem" : "inherit",
                background: "#EC674C",
                opacity: 1,
                paddingX: theme.spacing(4),
                paddingy: theme.spacing(1),
                // mt: theme.spacing(4),
                textTransform: "none",
                borderRadius: isMdUp ? "40px" : "30px",
                "&:hover": {
                  background: "#EC674C", // Change background color on hover
                },
              }}
              onClick={handleManagePrograms}
            >
              {t("SelectProgramPage.manageProgramsButton")}
            </Button>
          )}
          {programs?.count < MAX_PROGRAMS && (
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              sx={{
                width: "fit-content",
                fontSize: isMdUp ? "1rem" : "inherit",
                background: "#EC674C",
                opacity: 1,
                paddingX: theme.spacing(4),
                paddingy: theme.spacing(1),
                // mt: theme.spacing(4),
                textTransform: "none",
                borderRadius: isMdUp ? "40px" : "30px",
                "&:hover": {
                  background: "#EC674C", // Change background color on hover
                },
              }}
              onClick={handleAddProgram}
            >
              <AddCircleIcon sx={{ mr: theme.spacing(2) }} />{" "}
              {t("SelectProgramPage.addProgramButton")}
            </Button>
          )}
        </Box>
      </Container>
      {/* Passcode Modal */}
      <PasscodeModal
        open={isPasscodeModalOpen}
        onClose={handleCloseModal}
        onPasscodeSubmit={handlePasscodeSubmit}
        program={mode === "select" ? currentProgram : selectedProgram}
      />
      {/** Confirm Account Modal */}
      <ConfirmAccountModal
        open={isConfirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirmationSubmit={handleConfirmationSubmit}
        onCodeResend={handleResendCode}
        loading={isSending || isConfirming}
      />
      <SubscriptionExpiredDialog
        open={isSubscriptionExpiryModalOpen}
        onClose={() => setSubscriptionExpiryModalOpen(false)}
        onRenewClick={() => navigate("/subscription/plans")}
      />

      <MobileAppModal
        isVisible={isMobileAppModalOpen}
        onClose={() => setMobileAppModalOpen(false)}
        title="Download Our Mobile App"
        description="Enhance your experience by downloading our mobile app."
      />

      {/* <SubscriptionCancelledDialog
        open={cancellationNotificationOpen}
        onClose={() => setCancellationNotificationOpen(false)}
        subscription={subscription?.data}
      />  */}
      <FreeTrialBanner />
    </Box>
  );
};

// ProgramCard Component (new)

export default SelectProgramPage;
