import * as Yup from "yup";

export const loginSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
});

export const forgotPasswordSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export const signUpSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  // unitName: Yup.string().required("Unit name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  industry: Yup.string()
    .required("Industry is required")
    .oneOf(
      [
        "Construction",
        "Real Estate",
        "Healthcare",
        "Education",
        "Technology",
        "Finance",
        "Hospitality",
        "Manufacturing",
        "Retail",
        "Transportation",
      ],
      "Invalid industry selection"
    ), // Replace with your industry options
  agreedToTerms: Yup.bool()
    .required("You must agree to the terms and conditions")
    .oneOf([true], "You must agree to the terms and conditions"),
});

export const createProgramSchema = Yup.object().shape({
  nickname: Yup.string().required("Program name is required"),
  language: Yup.string()
    .required("Language is required")
    .oneOf(
      ["english", "spanish", "french"],
      "Valid Languages: English, Spanish, French"
    ),
  pin: Yup.string().length(4, "Pin must be 4 characters long").notRequired(),
});

export const editProgramSchema = Yup.object({
  nickname: Yup.string().nullable(),
  language: Yup.string()
    .required("Language is required")
    .oneOf(
      ["english", "spanish", "french"],
      "Valid Languages: English, Spanish, French"
    ),
  pin: Yup.string().length(4, "Pin must be 4 characters long").notRequired(),
});
