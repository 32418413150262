import React, { forwardRef } from "react";
import StyledTextField from "../Input";

interface PinInputFieldProps {
  isPassword?: boolean;
  value: string;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  handleBackspace: (index: number) => void;
  index: number;
  autoFocus: boolean;
}

const PinInputField = forwardRef<HTMLInputElement, PinInputFieldProps>(
  (
    { value, handleChange, handleBackspace, index, autoFocus, isPassword },
    ref
  ) => {
    return (
      <StyledTextField
        type={isPassword ? "password" : "tel"}
        value={value}
        onChange={(e) => handleChange(e, index)}
        onKeyUp={(e: React.KeyboardEvent) => {
          if (e.key === "Backspace" && !value) {
            handleBackspace(index);
          }
        }}
        inputRef={ref}
        inputProps={{
          maxLength: 1,
          style: {
            textAlign: "center",
            fontSize: isPassword ? "24px" : "16px", // Increase font size for password dots
          },
        }}
        autoFocus={autoFocus}
      />
    );
  }
);

PinInputField.displayName = "PinInputField";

export default PinInputField;
