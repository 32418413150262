import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Theme,
  IconButton,
  Container,
  FormControl,
  FormHelperText,
  InputAdornment,
  Typography,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import StyledTextField from "../../components/Input";
import { editProgramSchema } from "../../constants/validationSchemas";
import ImagePicker from "../../components/ImagePicker";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useUpdateProgramMutation,
  useDeleteProgramMutation,
  useUploadProgramPictureMutation,
  useGetProgramByIdQuery,
} from "../../redux/api/programApi";
import { CreateProgramInput } from "../../types";
// import { useDispatch } from "react-redux";
import { useAlert } from "../../contexts/AlertContext";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingView from "../../components/LoadingView";
import logo from "../../assets/TFLogo1.png";
import TopLevelBackButton from "../../components/TopLevelBackButton";

const placeholderImage =
  "https://www.treasury.gov.ph/wp-content/uploads/2022/01/male-placeholder-image.jpeg";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between("xs", "md")]: {
      width: 150,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
  heading: {
    margin: 0,
    textAlign: "center",
    color: "#121212",
    fontFamily: "Avenir Next LT Pro",
    fontWeight: "bold",
    letterSpacing: 0,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },
  subheading: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    color: "#121212",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },
  form: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },
  divider: {
    backgroundColor: theme.palette.grey[400], // Use theme to set the gray color
    alignSelf: "stretch", // Make sure it takes full height within the flex container
    // marginLeft: theme.spacing(1), // Add some spacing if needed
    marginRight: theme.spacing(1), // Add some spacing if needed
    opacity: 0.5,
  },
}));

const EditProgramPage = () => {
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [programImage, setProgramImage] = useState<string | ArrayBuffer>(
    placeholderImage
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [programImageFile, setProgramImageFile] = useState<File | null>(null);

  const navigate = useNavigate();
  const { error, success } = useAlert();
  const { t } = useTranslation();

  const [updateProgram, { isLoading: isUpdating }] = useUpdateProgramMutation();
  const [deleteProgram, { isLoading: isDeleting }] = useDeleteProgramMutation();
  const [uploadPicture] = useUploadProgramPictureMutation();

  const {
    data: programResponse,
    // isError,
    isLoading,
  } = useGetProgramByIdQuery(id || "");

  const program = programResponse?.data;
  console.log(program);

  const [requiresPin, setRequiresPin] = useState(program?.pin ? true : false);

  const handleImageChange = (
    newImage: string | ArrayBuffer,
    file: File | null
  ) => {
    setProgramImage(newImage);
    setProgramImageFile(file); // Store the file for later upload
  };

  // Function to handle deletion
  const handleConfirmDeleteProgram = async (programId: string) => {
    try {
      const response = await deleteProgram(programId).unwrap();
      console.log("Program deleted successfully", response);
      success(t("EditProgramPage.SuccessDeleteMessage"));
      setIsDeleteDialogOpen(false); // Close dialog before performing action
      navigate("/programs");
    } catch (err) {
      if (err instanceof Error) {
        error(`Could not delete program. ${err.message}`);
        console.error(err);
      } else {
        error(t("EditProgramPage.ErrorDeleteMessage"));
      }
    }
  };

  useEffect(() => {
    if (programResponse?.data.picture?.url) {
      setProgramImage(programResponse.data.picture.url);
    }
  }, [programResponse?.data.picture?.url]);

  const formik = useFormik({
    initialValues: {
      nickname: program ? program.nickname : "",
      language: program ? program.language : "",
      pin: program ? program.pin : "",
    },
    enableReinitialize: true, // Enable reinitialization
    validationSchema: editProgramSchema,
    onSubmit: async (values) => {
      try {
        const updatedProgram: Partial<CreateProgramInput> & {
          program_id: string;
        } =
          values.pin && requiresPin
            ? {
                nickname: values.nickname,
                language: values.language,
                pin: values.pin,
                program_id: program?.id,
              }
            : {
                nickname: values.nickname,
                language: values.language,
                program_id: program?.id,
              };
        await updateProgram(updatedProgram).unwrap();
        // Only upload picture if it has changed
        if (
          program &&
          programImage !== program.picture?.url &&
          programImageFile
        ) {
          await uploadPicture({
            program_id: program.id,
            picture: programImageFile,
          }).unwrap();
        }
        // Navigate or show success message
        success(t("EditProgramPage.SuccessUpdateMessage"));
        navigate("/programs");
      } catch (err) {
        if (err instanceof Error) {
          error(`Could not update program. ${err.message}`);
          console.error(err);
        } else {
          error(t("EditProgramPage.ErrorUpdateMessage"));
        }
      }
    },
  });

  const { errors, touched, handleSubmit } = formik;

  // Redirect or handle undefined programId
  useEffect(() => {
    if (!id) {
      navigate("/error"); // Replace with your error handling or redirection
    }
  }, [id, navigate]);

  useEffect(() => {
    if (program && program.pin) {
      setRequiresPin(true);
    }
  }, [program, program?.pin]);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        position: "relative",
      }}
    >
      <TopLevelBackButton />

      <Box sx={{ alignSelf: "flex-start" }}>
        <img src={logo} alt="Logo" className={classes.logo} />
      </Box>
      <Container
        sx={{
          mx: "auto",
          width: {
            xs: "90%", // 100% width on extra-small devices
            sm: "80%", // 90% width on small devices
            md: "40%", // 80% width on medium devices
            lg: "33%", // 70% width on large devices
            xl: "33%", // 60% width on extra-large devices
          },
        }}
      >
        <h1 className={classes.heading}>
          {t("EditProgramPage.EditProgramsHeading")}
        </h1>

        <p className={classes.subheading}>
          {t("EditProgramPage.EditProgramsSubheading")}
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: theme.spacing(4),
          }}
        >
          <ImagePicker image={programImage} onImageChange={handleImageChange} />
        </div>
        <form onSubmit={handleSubmit}>
          {/** Name */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <StyledTextField
              label={t("AddProgramPage.NameLabel")}
              id="nickname"
              type="text"
              name="nickname"
              value={formik.values.nickname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              margin="none"
              placeholder={t("AddProgramPage.NamePlaceholder")}
              error={touched.nickname && Boolean(errors.nickname)}
            />
            {touched.nickname && errors.nickname && (
              <FormHelperText
                sx={{
                  color: "red",
                  fontSize: "12px",
                  margin: 0,
                }}
              >
                {errors.nickname}
              </FormHelperText>
            )}
          </FormControl>

          {/** Language */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="language">
              {" "}
              {t("AddProgramPage.LanguageLabel")}
            </InputLabel>
            <Select
              labelId="language"
              id="language"
              name="language"
              value={formik.values.language}
              label={t("AddProgramPage.LanguageLabel")}
              placeholder={t("AddProgramPage.LanguagePlaceholder")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={touched.language && Boolean(errors.language)}
            >
              <MenuItem value="">
                <em>{t("AddProgramPage.NoneOption")}</em>
              </MenuItem>
              <MenuItem value={"english"}>
                {t("AddProgramPage.EnglishOption")}
              </MenuItem>
              <MenuItem value={"spanish"}>
                {t("AddProgramPage.SpanishOption")}
              </MenuItem>
              <MenuItem value={"french"}>
                {t("AddProgramPage.FrenchOption")}
              </MenuItem>
            </Select>
            {touched.language && errors.language && (
              <FormHelperText
                sx={{ color: "red", fontSize: "12px", margin: 0 }}
              >
                {errors.language}
              </FormHelperText>
            )}
          </FormControl>

          {/** Passcode */}
          <FormControl fullWidth>
            <StyledTextField
              label={t("AddProgramPage.PasscodeLabel")}
              id="pin"
              type="password"
              name="pin"
              value={formik.values.pin}
              disabled={!requiresPin}
              required={requiresPin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              margin="none"
              placeholder={t("AddProgramPage.PasscodePlaceholder")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box display="flex" alignItems="center">
                      <Divider
                        className={classes.divider}
                        orientation="vertical"
                        flexItem
                      />
                      <Switch
                        checked={requiresPin}
                        onChange={(e) => setRequiresPin(e.target.checked)}
                        name="requiresPasscode"
                        color="primary"
                      />
                      <Typography variant="body2">
                        {requiresPin
                          ? t("AddProgramPage.OnSwitch")
                          : t("AddProgramPage.OffSwitch")}
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
              error={touched.pin && Boolean(errors.pin)}
            />
            {touched.pin && errors.pin && (
              <FormHelperText error>{errors.pin}</FormHelperText>
            )}
          </FormControl>
          <div
            style={{
              marginBottom: theme.spacing(4),
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              disabled={isUpdating}
              sx={{
                width: "fit-content",
                fontSize: isMdUp ? "1rem" : "inherit",
                background: "#EC674C",
                opacity: 1,
                paddingX: theme.spacing(6),
                paddingy: theme.spacing(1),
                my: theme.spacing(4),
                textTransform: "none",
                borderRadius: isMdUp ? "40px" : "30px",
                "&:hover": {
                  background: "#EC674C", // Change background color on hover
                },
              }}
            >
              {isUpdating ? (
                <CircularProgress />
              ) : (
                t("AddProgramPage.SaveButton")
              )}
            </Button>

            {/* Delete Icon with Text */}
            <IconButton
              aria-label="delete"
              onClick={() => setIsDeleteDialogOpen(true)}
              disabled={isDeleting}
            >
              <DeleteIcon sx={{ color: "#121212" }} />
              <Typography variant="body2" sx={{ ml: 1, color: "#121212" }}>
                {t("EditProgramPage.DeleteProgram")}
              </Typography>
            </IconButton>
          </div>
        </form>
      </Container>
      {/** Deletion Confirmation Dialog */}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => handleConfirmDeleteProgram(id!)}
        title={t("confirmationDialog.deleteProgramTitle")}
        content={t("confirmationDialog.deleteProgramContent")}
      />
    </Box>
  );
};

export default EditProgramPage;
