import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Theme,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Thumbnail from "../../../../components/Thumbnail";
import { Playlist as PlaylistType, Video } from "../../../../types";
import {
  useDeletePlaylistMutation,
  useUpdatePlaylistMutation,
} from "../../../../redux/api/playlistApi"; // Adjust import path
import { useAlert } from "../../../../contexts/AlertContext";
import CreatePlaylistDialog from "../../../../components/CreatePlaylistModal";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import {
  setCurrentPlaylist,
  setPlayMode,
} from "../../../../redux/state/videoSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyGetVideoByIdQuery } from "../../../../redux/api/videoApi";
import PlaylistSkeleton from "../../../../components/Skeletons/PlaylistSkeleton";
import SwiperScroll from "../../../../components/SwiperScroll";
import { SwiperSlide } from "swiper/react";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
  playlistTitle: {
    display: "flex",
    alignItems: "center",
    fontWeight: "normal",
    gap: 3,
  },
}));

interface PlaylistProps {
  playlist: PlaylistType;
}

const Playlist = ({ playlist }: PlaylistProps) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [videoToRemove, setVideoToRemove] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // For popover
  const [playlistVideos, setPlaylistVideos] = useState<Video[]>([]);

  const classes = useStyles();
  const theme = useTheme();
  const { name, videos } = playlist;
  const { success, error } = useAlert();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [deletePlaylist] = useDeletePlaylistMutation();
  const [updatePlaylist] = useUpdatePlaylistMutation();

  const handleEdit = () => {
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const openRemoveDialog = (videoId: string) => {
    setVideoToRemove(videoId);
    setIsRemoveDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deletePlaylist(playlist.id).unwrap();
      success(t("PlaylistPage.DeleteSuccess"));
      setIsDeleteDialogOpen(false); // Close dialog before performing action
    } catch (err) {
      console.log(err);
      error(t("PlaylistPage.DeleteError"));
    }
  };

  const handleRemoveConfirm = async () => {
    try {
      const filteredList = playlist.videos.filter(
        (video) => video?.id !== videoToRemove
      );

      const updatedList = filteredList.map((video) => ({ id: video?.id }));
      await updatePlaylist({
        playlistId: playlist?.id,
        videos: updatedList,
      }).unwrap();
      setIsRemoveDialogOpen(false);
      setVideoToRemove("");
      success("Video removed successfully.");
    } catch (err) {
      console.log(err);
      error("Failed to remove video.");
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handlePlaylistPlay = () => {
    dispatch(setCurrentPlaylist(playlistVideos));
    dispatch(setPlayMode("playlist"));
    navigate(`/browse/stream`);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [getVideo, { isLoading }] = useLazyGetVideoByIdQuery();

  const fetchVideoById = async (id: string): Promise<Video | null> => {
    try {
      const response = await getVideo(id).unwrap();
      return response.data;
    } catch (error) {
      console.error("Failed to fetch Video", error);
      return null;
    }
  };

  useEffect(() => {
    const loadPlaylistVideos = async () => {
      if (videos && videos.length) {
        try {
          const videoPromises = videos.map((video) => {
            if (video?.id) return fetchVideoById(video?.id);
          });
          const fetchedVideos = await Promise.all(videoPromises);

          // Filter out null entries before setting state
          const filteredVideos = fetchedVideos.filter(
            (video) => video !== null
          );
          setPlaylistVideos(filteredVideos);
        } catch (error) {
          console.error("Failed to fetch playlists", error);
        }
      }
    };

    loadPlaylistVideos();
  }, [playlist.videos]);

  return (
    <div className={classes.container}>
      {isLoading ? (
        <PlaylistSkeleton />
      ) : (
        <>
          <div className={classes.playlistTitle}>
            <h3 style={{ color: "#121212", fontWeight: "normal" }}>{name}</h3>
            <IconButton onClick={handleClick}>
              <MoreVertIcon sx={{ color: "#121212" }} />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List>
                <ListItem button onClick={handleEdit}>
                  <ListItemIcon>
                    <EditIcon sx={{}} />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("PlaylistPage.EditPlaylistButton")}
                  />
                </ListItem>
                <ListItem button onClick={() => setIsDeleteDialogOpen(true)}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("PlaylistPage.DeletePlaylistButton")}
                  />
                </ListItem>
              </List>
            </Popover>
            {playlistVideos.length > 0 && (
              <Button
                startIcon={
                  <PlaylistPlayIcon color="inherit" fontSize="large" />
                }
                sx={{
                  width: "fit-content",
                  background: "#EC674C",
                  opacity: 1,
                  paddingX: theme.spacing(3),
                  paddingy: theme.spacing(1),
                  textTransform: "none",
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: "none",
                  color: "white",
                  "&:hover": {
                    background: "#EC674C", // Change background color on hover
                    boxShadow: "none",
                    color: "white",
                  },
                }}
                onClick={handlePlaylistPlay}
              >
                Play All
              </Button>
            )}
          </div>

          <SwiperScroll>
            {playlistVideos.map((video) => (
              <SwiperSlide key={video._id} style={{ width: "auto" }}>
                <Thumbnail
                  key={video?.id}
                  video={video}
                  videoId={video?.id}
                  onDelete={() => openRemoveDialog(video?.id)}
                />
              </SwiperSlide>
            ))}
          </SwiperScroll>
        </>
      )}

      {/* Edit Playlist Dialog */}
      <CreatePlaylistDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        editingMode={true}
        initialData={{
          id: playlist?.id,
          name: playlist.name,
          description: playlist.description,
        }}
      />

      {/** Deletion Confirmation Dialog */}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        title={t("confirmationDialog.deletePlaylistTitle")}
        content={t("confirmationDialog.deletePlaylistContent", {
          playlistName: playlist.name,
        })}
      />

      {/** Remove Video Confirmation Dialog */}
      <ConfirmationDialog
        open={isRemoveDialogOpen}
        onClose={() => setIsRemoveDialogOpen(false)}
        onConfirm={handleRemoveConfirm}
        title={t("confirmationDialog.removeVideoTitle")}
        content={t("confirmationDialog.removeVideoContent")}
      />
    </div>
  );
};

export default Playlist;
