import React from 'react';
import { Box, Theme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import useBreakpoints from '../../hooks/useBreakPoints';
import ThumbnailSkeleton from './ThumbnailSkeleton';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(10),
  },
  playlistTitle: {
    display: "flex",
    alignItems: "center",
    fontWeight: "normal",
    gap: 5
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const PlaylistSkeleton = () => {
  const classes = useStyles();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();

  const numberOfSkeletons = isXs
    ? 1
    : isSm
    ? 3
    : isMd
    ? 3
    : isLg
    ? 4
    : isXl
    ? 4
    : 1;
    
  return (
    <div className={classes.container}>
      <div className={classes.playlistTitle}>
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="circular" width={24} height={24} />
      </div>
      <Box className={classes.skeletonContainer}>
        {Array.from({ length: numberOfSkeletons }).map((_, index) => (
          <ThumbnailSkeleton key={index} />
        ))}
      </Box>
    </div>
  );
};

export default PlaylistSkeleton;
