import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import config from "../../config";
import { GET_NEW_ACCESS_TOKEN_ENDPOINT } from "../../constants/endpoints";
import { login, logout } from "../state/userSlice";

const baseUrl = config.apiUrl;

{
  /**
Since HTTP-only cookies are not accessible via JavaScript for security reasons, 
you cannot directly retrieve the refresh token from the cookie and set it in the 
header in client-side code.
*/
}

// Directly call the API to refresh the access token
// Directly call the API to refresh the access token
async function refreshAccessToken(api: BaseQueryApi) {
  try {
    // Retrieve the refresh token from the Redux state
    const refreshToken = (api.getState() as RootState).user.refresh_token;

    // Check if the refresh token exists
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }

    const response = await fetch(`${baseUrl + GET_NEW_ACCESS_TOKEN_ENDPOINT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh_token: refreshToken }), // Include refresh token in the request body
    });

    if (!response.ok) {
      throw new Error("Failed to refresh token");
    }

    const data: { access_token: string } = await response.json();
    return { ...data, refresh_token: refreshToken };
  } catch (error) {
    console.error("Error refreshing access token:", error);
    return null;
  }
}

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.access_token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

  interface CustomExtraOptions {
    skipAuth?: boolean;
    // other custom properties if needed
  }
  

  const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  CustomExtraOptions
> = async (args, api, extraOptions) => {
  // Check if skipAuth is set in extraOptions
  if (extraOptions?.skipAuth) {
    return await baseQuery(args, api, extraOptions);
  }

  let result = await baseQuery(args, api, extraOptions);

  if (
    "error" in result &&
    result.error?.status === 401 &&
    isErrorData(result.error.data) &&
    result.error.data["error"]["message"] === "Access token is invalid or expired"
  ) {
    const newToken = await refreshAccessToken(api);

    if (newToken) {
      api.dispatch(login(newToken));

      // Retry the original query with the new token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }

  return result;
};

// Type guard to check if result.error.data has the expected structure
function isErrorData(data: unknown): data is { error: { message: string } } {
  return (
    typeof data === 'object' &&
    data !== null &&
    'error' in data &&
    typeof data.error === 'object' &&
    data.error !== null &&
    'message' in data.error &&
    typeof data.error.message === 'string'
  );
}

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    "Program",
    "Video",
    "Company",
    "Playlist",
    "Category",
    "Notifications",
    "ContinueWatching",
    "Subscription"
  ], // Include your tagTypes here
  endpoints: () => ({}), // Placeholder for extending later
});
