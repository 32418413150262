import { baseApi } from "./baseApi";
import { Instructor } from "../../types";
import { GET_INSTRUCTOR_BY_ID_ENDPOINT } from "../../constants/endpoints";

export const instructorApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstructor: builder.query<{data: Instructor}, string>({
      query: (instructorId) => GET_INSTRUCTOR_BY_ID_ENDPOINT(instructorId),
    }),
  }),
});

export const { useGetInstructorQuery, useLazyGetInstructorQuery } = instructorApi;