import styled from "@emotion/styled";
import { Select } from "@mui/material";


const StyledSelect = styled(Select)`
  /* Add your custom styles here */
  margin-bottom: 10px;
  & .MuiOutlinedInput-notchedOutline {
    border-color: #121212;
    opacity: 0.5;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #121212;
    opacity: 0.5;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ec674c;
    opacity: 0.8;
  }
  & input {
    background: transparent; /* or "white" for a white background */
  }
  @media (min-width: 1920px) {
    /* Apply styles for screens 1280px or larger */
    height: 100px; /* Increase the input height */
    font-size: 40px; /* Increase the font size */
  }
`;

export default StyledSelect