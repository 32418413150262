import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import GooglePlayIcon from "@mui/icons-material/Android"; // Android icon for Play Store
import AppleIcon from "@mui/icons-material/Apple"; // Apple icon for App Store
import config from "../../config";
import { Box } from "@mui/material";

interface MobileAppModalProps {
  isVisible: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  playStoreLink?: string;
  appStoreLink?: string;
}

const MobileAppModal: React.FC<MobileAppModalProps> = ({
  isVisible,
  onClose,
  title = "Get the App!",
  description = "For a better experience, use our mobile app.",
  playStoreLink = config.playstoreLink,
  appStoreLink = config.iosLink,
}) => {
  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{description}</Typography>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          alignSelf: "center",
          pb: 5
        }}
      >
        <Button
          variant="contained"
          fullWidth
          href={playStoreLink}
          target="_blank"
          startIcon={<GooglePlayIcon />}
          sx={{
            textTransform: "none",
            "&:hover": {
              background: "#EC674C",
              boxShadow: "none",
            },
            boxShadow: "none",
            width: "100%",
            my: 2
          }}
        >
          Download from Play Store
        </Button>
        <Button
          variant="contained"
          fullWidth
          href={appStoreLink}
          target="_blank"
          startIcon={<AppleIcon />}
          sx={{
            textTransform: "none",
            "&:hover": {
              background: "#EC674C",
              boxShadow: "none",
            },
            boxShadow: "none",
            width: "100%"
          }}
        >
          Download from App Store
        </Button>
      </Box>
    </Dialog>
  );
};

export default MobileAppModal;
