import React, { ReactNode, useEffect, useState } from "react";
import { Swiper } from "swiper/react";
// import {Swiper as ISwiper} from "swiper";
import {
  Navigation,
  A11y,
  Keyboard,
} from "swiper/modules";

// Importing Swiper styles for navigation, pagination, and scrollbar
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./css/swiper.css"

/**
 * @description Props interface for SwiperScroll component.
 * @typedef {Object} SwiperScrollProps
 * @property {ReactNode} children - React nodes to be rendered as Swiper slides.
 * @property {number} slidesPerView - Number of slides to show per view.
 */
interface SwiperScrollProps {
  children: ReactNode;
  slidesPerView?: number | "auto";
  spaceBetween?: number;
  keyboardEnabled?: boolean;
  navigationEnabled?: boolean;
  onReachEnd?: () => void;
}

/**
 * @description SwiperScroll component providing a customizable Swiper slider.
 * Utilizes the Swiper library for creating responsive and interactive carousels.
 * @see {@link https://swiperjs.com/react | Swiper React Documentation}
 * @param {SwiperScrollProps} props - Props for the SwiperScroll component.
 * @returns {React.ReactElement} - Rendered Swiper component with the provided children as slides.
 */
const SwiperScroll: React.FC<SwiperScrollProps> = ({
  children,
  slidesPerView = "auto",
  spaceBetween = 10,
  keyboardEnabled = true,
  navigationEnabled = true,
  onReachEnd,
}: SwiperScrollProps): React.ReactElement => {

  const [isFetching, setIsFetching] = useState(false);

  const handleReachEnd = () => {
    
    if (onReachEnd && !isFetching) {
      setIsFetching(true);
      onReachEnd();
    }
  }

  // Reset fetching state when children change (new data is loaded)
  useEffect(() => {
    setIsFetching(false);
  }, [children]);
  
  return (
    <Swiper
      // Setting the direction of Swiper to horizontal.
      direction="horizontal"
      // Including modules like Navigation, Accessibility, and Keyboard Control.
      modules={[Navigation, A11y, Keyboard]}
      // Space between slides.
      spaceBetween={spaceBetween}
      // Number of slides per view.
      slidesPerView={slidesPerView}
      // Enabling keyboard control.
      keyboard={{ enabled: keyboardEnabled }}
      // Enabling navigation (next/prev buttons).
      navigation={navigationEnabled}
      onReachEnd={handleReachEnd}
      // Enabling mousewheel control.
      mousewheel={false}
      // pagination={{enabled: true, clickable: true}}
    >
      {children} {/* Rendering children as individual slides */}
    </Swiper>
  );
};

export default SwiperScroll;
