// videoSlice.js
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Video } from "../../types";

interface VideoState {
  currentVideo: Video | null;
  currentPlaylist: Video[] | null;
  continueWatchingId?: string | null;
  programId?: string;
  progress?: number;
  total?: number;
  playMode: "single" | "playlist";
}

const initialState: VideoState = {
  currentVideo: null,
  currentPlaylist: null,
  continueWatchingId: null,
  playMode: "single"
};

interface SetCurrentVideoPayload {
  video: Video | null;
  programId?: string;
  progress?: number;
  total?: number;
  continueWatchingId?: string | null;
}

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setCurrentVideo: (state, action: PayloadAction<SetCurrentVideoPayload>) => {
      const { video, programId, progress, total, continueWatchingId } = action.payload;
      state.currentVideo = video;
      if (programId !== undefined) state.programId = programId;
      if (progress !== undefined) state.progress = progress;
      if (total !== undefined) state.total = total;
      if (continueWatchingId !== undefined) state.continueWatchingId = continueWatchingId;
    },
    
    clearCurrentVideo: (state) => {
      state.currentVideo = null;
      state.programId = undefined;
      state.progress = undefined;
      state.total = undefined;
      state.playMode = "single";
    },

    setCurrentPlaylist: (state, action: PayloadAction<Video[] | null>) => {
      state.currentPlaylist = action.payload;
      state.playMode = "playlist";
      state.currentVideo = null;
    },

    setPlayMode: (state, action: PayloadAction<"single" | "playlist">) => {
      state.playMode = action.payload
    },

    setContinueWatchingId: (state, action: PayloadAction<string>) => {
      state.continueWatchingId = action.payload;
    },
  },
});

export const {
  setCurrentVideo,
  clearCurrentVideo,
  setContinueWatchingId,
  setCurrentPlaylist,
  setPlayMode
} = videoSlice.actions;

export default videoSlice.reducer;
