import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Account = {
  theme: string;
};
export type Subtitle = {
  enabled: boolean;
  language: 'en' | 'fr' | 'es';
}

// Define a type for the settings slice state
interface SettingsState {
  appSettings: {
    appLanguage: 'en' | 'fr' | 'es';
    appSubtitle: Subtitle;
    appVideoResolution: string;
    isDarkMode: boolean
  };
  personalSettings: {
    account: Account;
    personalSubtitle: Subtitle;
    personalVideoResolution: string;
    isDarkMode: boolean
  };
}

// Define the initial state using that type
const initialState: SettingsState = {
  appSettings: {
    appLanguage: "en",
    appSubtitle: {
      enabled: false,
      language: "en"
    },
    appVideoResolution: "1080p",
    isDarkMode: false
  },
  personalSettings: {
    account: {
      theme: "",
    },
    personalSubtitle: {
      enabled: false,
      language: "en"
    },
    personalVideoResolution: "1080p",
    isDarkMode: false
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<'en' | 'fr' | 'es'>) => {
      state.appSettings.appLanguage = action.payload;
    },
    setAppSubtitle: (state, action: PayloadAction<Subtitle>) => {
      state.appSettings.appSubtitle = action.payload;
    },
    setAppVideoResolution: (state, action: PayloadAction<string>) => {
      state.appSettings.appVideoResolution = action.payload;
    },
    setAccount: (state, action: PayloadAction<Account>) => {
      state.personalSettings.account = action.payload;
    },
    setPersonalSubtitle: (state, action: PayloadAction<Subtitle>) => {
      state.personalSettings.personalSubtitle = action.payload;
    },
    setPersonalVideoResolution: (state, action: PayloadAction<string>) => {
      state.personalSettings.personalVideoResolution = action.payload;
    },
    toggleAppTheme: (state) => {
      state.appSettings.isDarkMode = !state.appSettings.isDarkMode
    },
    togglePersonalTheme: (state) => {
      state.personalSettings.isDarkMode = !state.personalSettings.isDarkMode
    }

    // Additional reducers can be added to manage other settings
  },
});

// Destructure and export the actions
export const {
  setLanguage,
  setAppSubtitle,
  setAppVideoResolution,
  setAccount,
  setPersonalVideoResolution,
  setPersonalSubtitle,
  toggleAppTheme,
  togglePersonalTheme
} = settingsSlice.actions;

// Export the reducer
export default settingsSlice.reducer;
