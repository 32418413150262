import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Levels } from '../../types';



export const useThemeMode = (level: Levels): boolean => {
  const appIsDarkMode = useSelector((state: RootState) => state.settings.appSettings.isDarkMode);
  const personalIsDarkMode = useSelector((state: RootState) => state.settings.personalSettings.isDarkMode);

  return level === Levels.app ? appIsDarkMode : personalIsDarkMode;
};
