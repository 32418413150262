import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsIcon from '@mui/icons-material/Settings';
import Badge from '@mui/material/Badge';

// Define a type for navigation items
type NavItemConfig = {
  path: string;
  info: string;
  title: string;
  icon: (badgeContent?: number) => JSX.Element;
  children?: NavItemConfig[]; // Optional for nested items/dropdowns
};

// Define your navigation items array
export const navItems: NavItemConfig[] = [
    {
        title: 'nav.home',
        info: 'nav.homeInfo',
        path: '/browse/home',
        icon: () => <HomeIcon fontSize='inherit'/>,
      },
      {
        title: 'nav.search',
        info: 'nav.searchInfo',
        path: '/browse/search',
        icon: () => <SearchIcon fontSize='inherit'/>,
      },
      {
        title: 'nav.playlists',
        info: 'nav.playlistsInfo',
        path: '/browse/playlists',
        icon: () => <FavoriteIcon fontSize='inherit'/>,
      },
      {
        title: 'nav.categories',
        info: 'nav.categoriesInfo',
        path: '/browse/categories',
        icon: () => <VideoLibraryIcon fontSize='inherit'/>,
      },
      {
        title: 'nav.notifications',
        info: 'nav.notificationsInfo',
        path: '/browse/notifications',
        icon: (badgeContent?: number) => (
          <Badge
            badgeContent={badgeContent ? " " : null} // Show dot if badgeContent is provided
            color={badgeContent ? "error" : "default"} // Orange color if badgeContent is provided, otherwise default
            variant="dot"
            // overlap="circular"
          >
            <NotificationsNoneIcon fontSize="inherit" />
          </Badge>
        ),
      },
      {
        title: 'nav.settings',
        info: 'nav.settingsInfo',
        path: '/browse/settings',
        icon: () => <SettingsIcon fontSize='inherit'/>,
      },
];
