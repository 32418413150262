import { baseApi } from "./baseApi";
import { Industry, IndustryQueryParams } from "../../types";
import { GET_ALL_INDUSTRIES } from "../../constants/endpoints";

export const industryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getIndustries: builder.query<
      { data: Industry[]; count: number },
      IndustryQueryParams
    >({
      query: (params) => ({
        url: GET_ALL_INDUSTRIES,
        method: "GET",
        params,
      }),
      extraOptions: {
        skipAuth: true, // Skip token for this request
      },
    }),
  }),
});

export const { useGetIndustriesQuery, useLazyGetIndustriesQuery } = industryApi;
