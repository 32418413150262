import React, { useEffect, useState } from "react";
import bigNaassom from "../../assets/big-naassom.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  Theme,
  useTheme,
  CircularProgress,
  Alert,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@mui/styles";
import { loginSchema } from "../../constants/validationSchemas";
import { useFormik } from "formik";
import useIsFireTv from "../../hooks/useIsFireTv";
import StyledTextField from "../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { login, setError, setLoading } from "../../redux/state/userSlice";
import { useAlert } from "../../contexts/AlertContext";
import { useLoginMutation } from "../../redux/api/authApi";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";
import logo from "../../assets/TFLogo1.png";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    display: "grid",
    gridTemplateColumns: "1fr", // Single column by default
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr", // Two columns on medium screens and up
    },
  },
  logo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between("xs", "md")]: {
      width: 150,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
  leftColumn: {
    display: "grid",
    gridTemplateRows:
      "auto 1fr" /* Two rows: auto size for the logo, 1fr for the content */,
    height: "100%" /* Full height */,
    overflowY: "auto" /* Keep your existing overflow styles */,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center" /* Center vertically */,
    alignItems: "center" /* Center horizontally */,
    overflowY: "auto" /* If you want to make the content scrollable */,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  greeting: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
      // marginTop: theme.spacing(4),
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
      // marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
      // marginTop: theme.spacing(4),
    },
  },

  heading: {
    textAlign: "left",
    width: "100%",
    color: "#121212",
    margin: 0,
    fontFamily: "Avenir Next LT Pro",
    fontWeight: "bold",
    letterSpacing: 0,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },

  subheading: {
    textAlign: "left",
    width: "100%",
    marginBottom: theme.spacing(4),
    color: "#6E6E73",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },

  form: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },

  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(5),
    [theme.breakpoints.between("xs", "md")]: {
      width: "90%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "80%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "75%",
    },
  },

  signUpLink: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(5),
  },

  rightColumn: {
    display: "none", // Hide the right column by default
    [theme.breakpoints.up("md")]: {
      display: "block", // Show the right column on medium screens and up
      backgroundImage: `url(${bigNaassom})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100%",
    },
  },
}));

const LoginPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const isFireTV = useIsFireTv();
  const navigate = useNavigate();
  const { error: loginError, loading } = useSelector(
    (state: RootState) => state.user
  );
  const { success, error } = useAlert();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  const [loginCompany, { isLoading }] = useLoginMutation();

  const handleCloseError = () => {
    dispatch(setError(null));
  };

  useEffect(() => {
    return () => {
      dispatch(setError(null)); // Reset error state when component unmounts
    };
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const { email, password } = values;
      dispatch(setError(null)); // Reset error state
      dispatch(setLoading(true)); // Start loading
      try {
        const loginResponse = await loginCompany({ email, password }).unwrap();
        // Fallback to a default path if no redirect path was saved
        dispatch(setLoading(false)); // Stop loading
        dispatch(login(loginResponse));
        success("Login Successful, Welcome Back!");
        navigate("/programs");
      } catch (err) {
        dispatch(setLoading(false)); // Stop loading
        const errorMessage =
          err.data?.error?.message ||
          "An unexpected error occurred. Please try again.";
        dispatch(setError(errorMessage)); // Set error message
        error(errorMessage);
      }
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        {/** Logo  */}
        <div style={{ width: "100%" }}>
          <img
            src={logo}
            alt="Logo"
            className={classes.logo}
          />
        </div>
        <div className={classes.content}>
          {/** Greeting */}
          <div className={classes.greeting}>
            <h1 className={classes.heading}>{t("LoginPage.welcomeHeading")}</h1>
            <p className={classes.subheading}>
              {t("LoginPage.credentialsSubheading")}
            </p>
          </div>
          {loading && <CircularProgress />} {/* Display loading indicator */}
          {loginError && (
            <Alert
              severity="error"
              onClose={handleCloseError}
              sx={{ my: theme.spacing(2) }}
            >
              {loginError}
            </Alert>
          )}{" "}
          {/** Form */}
          <form onSubmit={handleSubmit} className={classes.form}>
            {/** Email */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <StyledTextField
                label={t("LoginPage.emailLabel")}
                id="email"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                margin="none"
                placeholder={t("LoginPage.emailPlaceholder")}
                error={touched.email && Boolean(errors.email)}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  sx={{
                    color: "red",
                    fontSize: "12px",
                    margin: 0,
                  }}
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            {/** Password */}
            <FormControl fullWidth margin="none">
              <StyledTextField
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                label={t("LoginPage.passwordLabel")}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                margin="none"
                placeholder={t("LoginPage.passwordPlaceholder")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t("LoginPage.togglePasswordVisibility")}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={touched.password && Boolean(errors.password)}
              />
              {touched.password && errors.password && (
                <FormHelperText
                  sx={{ color: "red", fontSize: "12px", margin: 0 }}
                >
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>

            <Grid container className={classes.signUpLink}>
              <Grid item xs={7} lg={4}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  size="large"
                  // disabled={isLoading || !values.email || !values.password}
                  sx={{
                    width: "fit-content",
                    background: "#EC674C",
                    opacity: 1,
                    paddingX: theme.spacing(6),
                    paddingy: theme.spacing(1),
                    textTransform: "none",
                    borderRadius: isFireTV ? "40px" : "30px",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      background: "#EC674C", // Change background color on hover
                    },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    t("LoginPage.signInButton")
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Typography
                  component={Link}
                  to="/auth/forgot-password"
                  sx={{
                    color: "#EC674C",
                    fontSize: ["1rem", null, null, "1rem"],
                  }}
                >
                  {t("LoginPage.needHelp")}
                </Typography>
              </Grid>
            </Grid>
          </form>
          {/** Create Account Link */}
          <div className={classes.footer}>
            <Typography
              variant="body2"
              sx={{ fontSize: ["1rem", null, null, "1rem"] }}
              width={"100%"}
              textAlign={"center"}
            >
              {t("LoginPage.noAccount")}{" "}
              <Link to="/auth/signup" style={{ color: "#EC674C" }}>
                {t("LoginPage.createAccount")}
              </Link>
            </Typography>
            <Typography
              variant="body2"
              color={"#6E6E73"}
              width={"100%"}
              mt={2}
              textAlign={"center"}
              sx={{
                opacity: 0.5,
                fontSize: ["1rem", null, null, "1rem"],
              }}
            >
              {t("LoginPage.footerText")}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.rightColumn}></div>
    </div>
  );
};

export default LoginPage;
