import React from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const ProfileComponent: React.FC<{ name: string; avatarUrl?: string }> = ({
  name,
  avatarUrl,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Avatar
        src={avatarUrl}
        alt={name}
        variant="rounded"
        sx={{ bgcolor: "#EC674C", width: 50, height: 50 }}
      >
        {!avatarUrl && name?.charAt(0).toUpperCase()}
      </Avatar>
      <Box>
        <Typography variant="h6" fontWeight={"bold"}>
          {name}
        </Typography>
        <Link
          component="button" // This makes it more semantically correct since it's a button action, not a hyperlink
          onClick={() => navigate("/programs")}
          sx={{ color: "#EC674C", cursor: "pointer", textDecoration: "none" }}
        >
          {t("ProfileComponent.SwitchProgram")}
        </Link>
      </Box>
    </Box>
  );
};

export default ProfileComponent;
