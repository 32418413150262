import React from 'react';
import { AppBar, Toolbar, IconButton, useTheme, useMediaQuery } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../redux/state/sidebarSlice"; // Import the action

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerToggle = () => {
    dispatch(toggleSidebar());
  };

  if (isMdUp) {
    return null; // Don't render the header on larger screens
  }

  return (
    <AppBar position="fixed" sx={{ background: 'transparent', boxShadow: 'none', height: "auto", zIndex: 1000 }}>
      <Toolbar sx={{justifyContent: "flex-end"}}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
        >
          <MenuIcon style={{color: theme.palette.primary.main}}/>
        </IconButton>
        {/* Other header content */}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
