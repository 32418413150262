import React, { useState } from "react";
import {
  Box,
  Theme,
  Chip,
  useTheme,
  Button,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddToPlaylistModal from "../../../../components/AddToPlaylistModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContinueWatching } from "../../../../hooks/useContinueWatching";
import { setCurrentPlaylist, setCurrentVideo, setPlayMode } from "../../../../redux/state/videoSlice";
import { useAlert } from "../../../../contexts/AlertContext";

const useStyles = makeStyles<Theme>((theme) => ({
  heroContainer: {
    position: "relative",
    backdropFilter: "blur (0px)",
    WebkitBackdropFilter: "blur (0px)",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #FFFFFF 100%)`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 1,
    },
    paddingBottom: "10%",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
      paddingTop: theme.spacing(15),
    },
  },
  heading: {
    textAlign: "left",
    color: "white",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    letterSpacing: "0px",
    opacity: 1,
    fontFamily: "Roboto",
    marginTop: 0,
    fontSize: "3rem",
  },
  chip: {
    "& .MuiChip-label": {
      color: "white",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
      letterSpacing: "0px",
      lineHeight: "50px",
      fontFamily: "Roboto",
    },
  },
  chipContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  description: {
    textAlign: "left",
    color: "white",
    fontSize: "1.4rem",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    letterSpacing: "0px",
    lineHeight: "35px",
    opacity: 1,
    [theme.breakpoints.up("xs")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "60%",
    },
  },
}));

interface HeroProps {
  heading: string;
  videoLength: string;
  videoYear: string;
  videoCategory: string;
  videoDescription: string;
  image: string;
}

const Hero: React.FC<HeroProps> = ({
  heading,
  videoCategory,
  videoDescription,
  videoLength,
  videoYear,
  image,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentVideo } = useSelector((state: RootState) => state.video);
  const { t } = useTranslation();
  const { info } = useAlert();
  const { currentProgram } = useSelector((state: RootState) => state.program);

  const { fetchVideoContinueWatching, create } = useContinueWatching(
    currentProgram.id
  );

  const navigate = useNavigate();

  const handlePlayVideo = async () => {
    if (currentVideo && currentVideo.medias.length) {
      try {
        // Fetch the latest video continue watching state
        const { data: updatedData } = await fetchVideoContinueWatching({
          program_id: currentProgram.id,
          video_id: currentVideo.id,
          sort: "asc",
          field: "created_at",
          page_number: 1,
          page_size: 1,
        });

        if (updatedData && updatedData.count > 0) {
          const continueWatchingEntry = updatedData.data[0];
          // Set the video with the updated progress
          dispatch(
            setCurrentVideo({
              video: currentVideo,
              programId: continueWatchingEntry.program_id,
              progress: continueWatchingEntry.current_position,
              total: currentVideo.medias[0].length,
              continueWatchingId: continueWatchingEntry.id
            })
          );
        } else {
          // If the video is not in the continue watching list, create a new entry
          const response = await create({
            program_id: currentProgram.id,
            video_id: currentVideo.id,
            current_position: 0,
            is_complete: false,
          });
          console.log(response)

          dispatch(
            setCurrentVideo({
              video: currentVideo,
              programId: currentProgram.id,
              progress: 0,
              total: currentVideo.medias[0].length,
              continueWatchingId: response.data.id
            })
          );
        }

        dispatch(setPlayMode("single"));
        dispatch(setCurrentPlaylist(null))
        navigate(`/browse/stream}`);

      } catch (error) {
        console.error("Error handling play video:", error);
      }
    } else {
      info("Video not available");
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      id="more-info-hero"
      className={classes.heroContainer}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div style={{ position: "relative", zIndex: 2 }}>
        <h1 className={classes.heading}>{heading}</h1>
        <div className={classes.chipContainer}>
          <Chip
            label={videoYear}
            className={classes.chip}
            sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
          />
          {videoLength ? (
            <Chip
              label={videoLength}
              className={classes.chip}
              sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
            />
          ) : (
            ""
          )}
          {videoCategory ? (
            <Chip
              label={videoCategory}
              className={classes.chip}
              sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
            />
          ) : (
            ""
          )}
        </div>
        <p className={classes.description}>{videoDescription}</p>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              width: "fit-content",
              fontSize: isMdUp ? "1rem" : "inherit",
              background: "#EC674C",
              paddingX: isMdUp ? theme.spacing(6) : theme.spacing(2),
              paddingy: theme.spacing(1),
              mr: theme.spacing(2),
              textTransform: "none",
              borderRadius: isMdUp ? "40px" : "30px",
              boxShadow: "none",
              "&:hover": {
                background: "#EC674C",
                boxShadow: "none",
              },
            }}
            onClick={handlePlayVideo}
          >
            {t("MoreInfoPage.watchNow")}
          </Button>
          <Button
            variant="outlined"
            size="large"
            sx={{
              width: "fit-content",
              fontSize: isMdUp ? "1rem" : "inherit",
              paddingX: isMdUp ? theme.spacing(6) : theme.spacing(2),
              paddingy: theme.spacing(1),
              textTransform: "none",
              borderRadius: isMdUp ? "40px" : "30px",
              borderColor: "white",
              color: "white",
              boxShadow: "none",
              "&:hover": {
                background: "none",
                boxShadow: "none",
                borderColor: "white",
              },
            }}
            onClick={handleOpenModal}
          >
            {t("MoreInfoPage.addToPlaylist")}
          </Button>
          <AddToPlaylistModal
            open={isModalOpen}
            onClose={handleCloseModal}
            id={currentVideo?.id}
            _id={currentVideo?._id}
          />
        </div>
      </div>
    </Box>
  );
};

export default Hero;
