import { useMediaQuery, useTheme } from '@mui/material';

/**
 * @description Defines the structure for the breakpoints object.
 */
interface Breakpoints {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  is4k: boolean;
  is8k: boolean;
}

/**
 * @description Custom hook to provide responsive breakpoints using Material-UI's useMediaQuery.
 * Utilizes the theme breakpoints for responsive design checks.
 * @see {@link https://mui.com/material-ui/react-use-media-query/ | MUI useMediaQuery Documentation}
 * @see {@link https://mui.com/material-ui/customization/breakpoints/ | MUI Breakpoints Documentation}
 * @returns {Breakpoints} Object containing boolean values for each breakpoint (xs, sm, md, lg, xl, is4k, is8k).
 */
const useBreakpoints = (): Breakpoints => {
  const theme = useTheme();

  const breakpoints: Breakpoints = {
    isXs: useMediaQuery(theme.breakpoints.down("sm")),
    isSm: useMediaQuery(theme.breakpoints.between("sm", "md")),
    isMd: useMediaQuery(theme.breakpoints.between("md", "lg")),
    isLg: useMediaQuery(theme.breakpoints.between("lg", "xl")),
    isXl: useMediaQuery(theme.breakpoints.up("xl")),
    is4k: useMediaQuery(theme.breakpoints.up(3840)),
    is8k: useMediaQuery(theme.breakpoints.up(7680))
  };

  return breakpoints;
};

export default useBreakpoints;
