import styled from "@emotion/styled";
import { InputLabel } from "@mui/material";

const StyledInputLabel = styled(InputLabel)`
  ${({ focused }) =>
    focused &&
    `
    &.MuiInputLabel-shrink {
      color: #ec674c; /* Set the label color to match the focused input outline color */
    }
  `}
`;

export default StyledInputLabel;
