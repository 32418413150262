import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { signUpSchema } from "../../constants/validationSchemas";
import { useFormik } from "formik";
import StyledTextField from "../../components/Input";
import { useSignupMutation } from "../../redux/api/authApi";
import { useTranslation } from "react-i18next";
import logo from "../../assets/TFLogo1.png";


// Image assets
import bigNaassom from "../../assets/big-naassom.png";
import { SignUpPayload } from "../../types";
import { useAlert } from "../../contexts/AlertContext";
import { useGetIndustriesQuery } from "../../redux/api/industryApi";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    display: "grid",
    gridTemplateColumns: "1fr", // Single column by default
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr", // Two columns on medium screens and up
    },
  },
  logo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between("xs", "md")]: {
      width: 150,
      // height: 40,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 200,
      // height: 40,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
      // height: 40,
    },
  },

  leftColumn: {
    display: "grid",
    gridTemplateRows:
      "auto 1fr" /* Two rows: auto size for the logo, 1fr for the content */,
    height: "100%" /* Full height */,
    overflowY: "auto" /* Keep your existing overflow styles */,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start" /* Center vertically */,
    alignItems: "center" /* Center horizontally */,
    overflowY: "auto" /* If you want to make the content scrollable */,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  greeting: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
      marginTop: theme.spacing(4),
    },
  },
  heading: {
    textAlign: "left",
    color: "#121212",
    margin: 0,
    width: "100%",
    fontFamily: "Avenir Next LT Pro",
    fontWeight: "bold",
    letterSpacing: 0,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },
  subheading: {
    textAlign: "left",
    marginBottom: theme.spacing(4),
    color: "#6E6E73",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },
  form: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(10),
    [theme.breakpoints.between("xs", "md")]: {
      width: "90%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "80%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "75%",
    },
  },
  signUpLink: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(5),
  },
  rightColumn: {
    display: "none", // Hide the right column by default
    [theme.breakpoints.up("md")]: {
      display: "block", // Show the right column on medium screens and up
      backgroundImage: `url(${bigNaassom})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100%",
    },
  },
}));

const SignUpPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [pageSize] = useState(10);
  const [pageNumber] = useState(1);
  const { success, error } = useAlert();
  const { t } = useTranslation();

  const {
    data: allIndustries,
    error: industriesError,
    isLoading: isLoadingIndustries,
  } = useGetIndustriesQuery({
    page_number: pageNumber,
    page_size: pageSize,
    sort: "asc",
    field: "created_at",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };
  // Add navigate hook for redirection after successful signup
  const navigate = useNavigate();

  // Signup mutation hook
  const [signup, { isLoading }] = useSignupMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      companyName: "",
      industry: "",
      agreedToTerms: false,
    },
    validationSchema: signUpSchema,
    onSubmit: async (values) => {
      try {
        const signUpPayload: SignUpPayload = {
          email: values.email,
          name: values.companyName,
          industry: values.industry,
          password: values.password,
        };
        await signup(signUpPayload).unwrap();
        // Navigate to a success page or show a success message
        success("Account Created Successfully");
        navigate("/auth/login");
      } catch (err) {
        error("Failed to sign up");
        console.error("Failed to sign up:", err);
        // Handle signup failure (e.g., show error message)
      }
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <div style={{ width: "100%" }}>
          <img
            src={logo}
            alt="Logo"
            className={classes.logo}
          />
        </div>
        <div className={classes.content}>
          <div className={classes.greeting}>
            <Typography
              variant="body2"
              marginBottom={2}
              sx={{ fontSize: ["1rem", null, null, "1rem"] }}
              textAlign={"left"}
              width={"100%"}
            >
              {t("SignUpPage.AlreadyHaveAccount")}{" "}
              <Link to="/auth/login" style={{ color: "#EC674C" }}>
                {t("SignUpPage.SignIn")}
              </Link>
            </Typography>
            <h1 className={classes.heading}>
              {t("SignUpPage.CreateAccountHeading")}
            </h1>
          </div>
          <form onSubmit={handleSubmit} className={classes.form}>
            {/* Company Name */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <StyledTextField
                label={t("SignUpPage.CompanyNameLabel")}
                id="company"
                type="text"
                name="companyName"
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                margin="none"
                placeholder={t("SignUpPage.CompanyNamePlaceholder")}
                error={touched.companyName && Boolean(errors.companyName)}
              />
              {touched.companyName && errors.companyName && (
                <FormHelperText
                  sx={{
                    color: "red",
                    fontSize: "12px",
                    margin: 0,
                  }}
                >
                  {errors.companyName}
                </FormHelperText>
              )}
            </FormControl>

            {/** Email */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <StyledTextField
                label={t("SignUpPage.EmailLabel")}
                id="email"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                margin="none"
                placeholder={t("SignUpPage.EmailPlaceholder")}
                error={touched.email && Boolean(errors.email)}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  sx={{
                    color: "red",
                    fontSize: "12px",
                    margin: 0,
                  }}
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            {/** Password */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <StyledTextField
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                label={t("SignUpPage.PasswordLabel")}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                margin="none"
                placeholder={t("SignUpPage.PasswordPlaceholder")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t("SignUpPage.TogglePasswordVisibility")}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={touched.password && Boolean(errors.password)}
              />
              {touched.password && errors.password && (
                <FormHelperText
                  sx={{ color: "red", fontSize: "12px", margin: 0 }}
                >
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>

            {/* Confirm Password */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <StyledTextField
                id="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                label={t("SignUpPage.ConfirmPasswordLabel")}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                margin="none"
                placeholder={t("SignUpPage.ConfirmPasswordPlaceholder")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t(
                          "SignUpPage.ToggleConfirmPasswordVisibility"
                        )}
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
              />
              {touched.confirmPassword && errors.confirmPassword && (
                <FormHelperText
                  sx={{ color: "red", fontSize: "12px", margin: 0 }}
                >
                  {errors.confirmPassword}
                </FormHelperText>
              )}
            </FormControl>

            {/** Industry Selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="industry-label">
                {t("SignUpPage.IndustryLabel")}
              </InputLabel>
              <Select
                labelId="industry-label"
                id="industry"
                name="industry"
                value={values.industry}
                label={t("SignUpPage.IndustryLabel")}
                placeholder={t("SignUpPage.SelectIndustry")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.industry && Boolean(errors.industry)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {isLoadingIndustries ? (
                  <MenuItem disabled>
                    <em>Loading...</em>
                  </MenuItem>
                ) : industriesError ? (
                  [
                    <MenuItem value="Construction" key={"Construction"}>Construction</MenuItem>,
                    <MenuItem value="Real Estate" key={"Real Estate"}>Real Estate</MenuItem>,
                    <MenuItem value="Healthcare" key={"Healthcare"}>Healthcare</MenuItem>,
                    <MenuItem value="Education" key={"Education"}>Education</MenuItem>,
                    <MenuItem value="Technology" key={"Technology"}>Technology</MenuItem>,
                    <MenuItem value="Finance" key={"Finance"}>Finance</MenuItem>,
                    <MenuItem value="Hospitality" key={"Hospitality"}>Hospitality</MenuItem>,
                    <MenuItem value="Manufacturing" key={"Manufacturing"}>Manufacturing</MenuItem>,
                    <MenuItem value="Retail" key={"Retail"}>Retail</MenuItem>,
                    <MenuItem value="Transportation" key={"Transportation"}>Transportation</MenuItem>,
                  ]
                ) : (
                  allIndustries?.data?.map((industry) => (
                    <MenuItem key={industry.id} value={industry.name}>
                      {industry.name}
                    </MenuItem>
                  ))
                )}
              </Select>
              {touched.industry && errors.industry && (
                <FormHelperText
                  sx={{ color: "red", fontSize: "12px", margin: 0 }}
                >
                  {errors.industry}
                </FormHelperText>
              )}
            </FormControl>

            {/** Terms And Agreement */}
            <FormControl fullWidth sx={{ mb: theme.spacing(4) }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreedToTerms"
                      color="primary"
                      checked={values.agreedToTerms}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {t("SignUpPage.AgreeTerms")}{" "}
                      <Link
                        to="/terms-and-conditions"
                        style={{ color: theme.palette.primary.main }}
                      >
                        {t("SignUpPage.TermsOfUse")}
                      </Link>{" "}
                    </Typography>
                  }
                />
                {errors.agreedToTerms && (
                  <FormHelperText
                    sx={{ color: "red", fontSize: "12px", margin: 0 }}
                  >
                    {errors.agreedToTerms}
                  </FormHelperText>
                )}
              </div>
            </FormControl>
            {/** Sign Up Button */}
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginBottom: theme.spacing(4),
              }}
            >
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading || !values.agreedToTerms}
                sx={{
                  width: "fit-content",
                  fontSize: isMdUp ? "1rem" : "inherit",
                  background: "#EC674C",
                  opacity: 1,
                  textTransform: "none",
                  borderRadius: isMdUp ? "40px" : "30px",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    background: "#EC674C", // Change background color on hover
                  },
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t("SignUpPage.CreateAccountButton")
                )}
              </Button>
            </div>
          </form>
          <Typography
            variant="body2"
            color={"#6E6E73"}
            width={"100%"}
            my={2}
            textAlign={"center"}
            sx={{
              opacity: 0.5,
              fontSize: ["1rem", null, null, "1rem"],
            }}
          >
            {t("SignUpPage.FooterText")}
          </Typography>
        </div>
      </div>
      <div className={classes.rightColumn}></div>
    </div>
  );
};

export default SignUpPage;
