// subscriptionApi.ts
import { baseApi } from "./baseApi"; // Adjust the path accordingly
import {
  GET_SUBSCRIPTION_PLAN_ENDPOINT,
  GET_ALL_SUBSCRIPTION_PLANS_ENDPOINT,
  GET_SUBSCRIPTION_ENDPOINT,
  SUBSCRIBE_SUBSCRIPTION_ENDPOINT,
  UNSUBSCRIBE_SUBSCRIPTION_ENDPOINT,
} from "../../constants/endpoints";
import {
  SubscriptionPlan,
  GetAllSubscriptionsParams,
  Subscription,
  SessionResponse,
  CreateSessionPayload,
} from "../../types";

export const subscriptionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createPaymentSession: builder.mutation<
      SessionResponse, // Using the newly defined interface
      CreateSessionPayload
    >({
      query: (body) => ({
        url: "/payment-session", // The endpoint URL
        method: "POST",
        body,
      }),
    }),

    getSubscriptionPlan: builder.query<SubscriptionPlan, string>({
      query: (subscriptionPlanId) =>
        GET_SUBSCRIPTION_PLAN_ENDPOINT(subscriptionPlanId),
    }),

    getAllSubscriptionPlans: builder.query<
      { data: SubscriptionPlan[]; count: number },
      GetAllSubscriptionsParams
    >({
      query: (params) => ({ url: GET_ALL_SUBSCRIPTION_PLANS_ENDPOINT, params }),
    }),

    getSubscription: builder.query<{ data: Subscription }, string>({
      query: (subscriptionId) => GET_SUBSCRIPTION_ENDPOINT(subscriptionId),
      providesTags: ["Subscription"]
    }),

    subscribeSubscription: builder.mutation<
      Subscription,
      { subscription_plan_id: string }
    >({
      query: (body) => ({
        url: SUBSCRIBE_SUBSCRIPTION_ENDPOINT,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Subscription"]
    }),

    unsubscribeSubscription: builder.mutation<
      Subscription,
      { subscriptionId: string; immediately?: boolean }
    >({
      query: ({ subscriptionId, immediately }) => ({
        url: UNSUBSCRIBE_SUBSCRIPTION_ENDPOINT(subscriptionId),
        method: "POST",
        body: immediately ? {immediately} : undefined
      }),
      invalidatesTags: ["Subscription"]
    }),
    // ... other endpoints if necessary
  }),
  // Add overrideExisting flag if needed
});

// Export hooks for using these endpoints in components
export const {
  useCreatePaymentSessionMutation,
  useGetSubscriptionPlanQuery,
  useGetAllSubscriptionPlansQuery,
  useGetSubscriptionQuery,
  useLazyGetSubscriptionQuery,
  useSubscribeSubscriptionMutation,
  useUnsubscribeSubscriptionMutation,
} = subscriptionApi;
