import { useCallback } from "react";
import {
  useGetContinueWatchingQuery,
  useCreateContinueWatchingMutation,
  useDeleteContinueWatchingMutation,
  useLazyGetContinueWatchingQuery,
  useUpdateContinueWatchingMutation,
} from "../../redux/api/continueWatchingApi";
import {
  ContinueWatchingCreatePayload,
  ContinueWatchingUpdatePayload,
} from "../../types";

export const useContinueWatching = (programId: string) => {
  const {
    data: continueWatchingData,
    error: fetchError,
    isLoading: isFetching,
    refetch
  } = useGetContinueWatchingQuery({
    program_id: programId,
    page_number: 1,
    page_size: 10,
    sort: "desc",
    field: "created_at",
  });

  const [fetchVideoContinueWatching] = useLazyGetContinueWatchingQuery();

  const [
    createContinueWatching,
    { error: createError, isLoading: isCreating },
  ] = useCreateContinueWatchingMutation();

  const [
    updateContinueWatching,
    { error: updateError, isLoading: isUpdating },
  ] = useUpdateContinueWatchingMutation();

  const [
    deleteContinueWatching,
    { error: deleteError, isLoading: isDeleting },
  ] = useDeleteContinueWatchingMutation();

   // Encapsulate create logic with error handling
  const create = useCallback(
    async (payload: ContinueWatchingCreatePayload) => {
      try {
        const response = await createContinueWatching(payload).unwrap();
        await refetch(); // Manually triggers a re-fetch to update the data after creating
        return response;
      } catch (error) {
        console.error("Error creating continue watching entry:", error);
        throw error; // Re-throw the error to be handled further up if necessary
      }
    },
    [createContinueWatching, refetch]
  );

  // Encapsulate update logic with error handling
  const update = useCallback(
    async (id: string, payload: ContinueWatchingUpdatePayload) => {
      try {
        await updateContinueWatching({ id, payload }).unwrap();
        if (refetch) {
          await refetch(); // Manually triggers a re-fetch to update the data after updating
        } else {
          console.warn("Refetch is not available.");
        }
      } catch (error) {
        console.error("Error updating continue watching entry:", error);
        throw error; // Re-throw the error for higher-level handling if needed
      }
    },
    [updateContinueWatching, refetch]
  );

  // Encapsulate delete logic with error handling
  const remove = useCallback(
    async (id: string) => {
      try {
        await deleteContinueWatching(id).unwrap();
        await refetch(); // Manually triggers a re-fetch to update the data after deletion
      } catch (error) {
        console.error("Error deleting continue watching entry:", error);
        throw error; // Re-throw to be handled by the caller if needed
      }
    },
    [deleteContinueWatching, refetch]
  );
  

  return {
    continueWatchingData,
    fetchError,
    isFetching,
    create,
    createError,
    isCreating,
    update,
    updateError,
    isUpdating,
    remove,
    deleteError,
    isDeleting,
    fetchVideoContinueWatching, // New function to fetch specific video continue watching data
  }
};
