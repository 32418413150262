import React, { useState } from "react";
import {
  Card,
  CardMedia,
  Chip,
  LinearProgress,
  Box,
  Theme,
  linearProgressClasses,
  CircularProgress,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles, styled } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import useBreakpoints from "../../../../../hooks/useBreakPoints";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentVideo,
  setPlayMode,
} from "../../../../../redux/state/videoSlice";
import { Video } from "../../../../../types";
import { useContinueWatching } from "../../../../../hooks/useContinueWatching";
import { RootState } from "../../../../../redux/store";

const BorderLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFFFFF66",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#FFFFFF",
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: "relative",
    backdropFilter: "blur(0px)",
    WebkitBackdropFilter: "blur(0px)",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background:
        "transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 0,
    },
    "&:hover": {
      outline: "4px solid #EC674C",
      outlineOffset: "-2px",
      cursor: "pointer",
    },
  },
  media: {
    height: "100%",
  },
  infoContainer: {
    position: "absolute",
    bottom: 10,
    left: "5%",
    width: "90%",
  },
  chip: {
    "& .MuiChip-label": {
      color: "#FFFFFF",
      lineHeight: "50px",
      fontFamily: "Roboto",
      letterSpacing: "0px",
    },
  },
  // Style for the category chip
  categoryChip: {
    "& .MuiChip-label": {
      color: "#FFFFFF",
      lineHeight: "50px",
      fontFamily: "Roboto",
      letterSpacing: "0px",
    },
  },
  chipContainer: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden",
    gap: theme.spacing(1),
  },
  title: {
    color: "white",
    marginBottom: theme.spacing(1),
    fontWeight: "normal",
  },
  progressBar: {
    width: "100%",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
}));

export interface ContinueWatchingCardProps {
  id: string;
  progress: number;
  total: number;
  video: Video;
  continueWatchingId: string;
}

export default function ContinueWatchingCard({
  progress,
  total,
  id,
  video,
  continueWatchingId,
}: ContinueWatchingCardProps) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSm, isMd, isLg, isXl } = useBreakpoints();
  const { currentProgram } = useSelector((state: RootState) => state.program);

  // Calculates responsive sizing for the card based on breakpoints
  const cardHeight = isXl ? 302 : isLg ? 252 : isMd ? 252 : isSm ? 175 : 175;
  const cardWidth = isXl ? 325 : isLg ? 275 : isMd ? 275 : isSm ? 225 : 225;

  const { fetchVideoContinueWatching, remove, create } = useContinueWatching(
    currentProgram.id
  );

  const handleRemoveClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation(); // This will stop the event from bubbling up
    await remove(continueWatchingId);
  };

  // Handler for click event
  const handleClick = async () => {
    setLoading(true); // Start loading

    try {
      // Fetch the latest video state
      const updatedData = await fetchVideoContinueWatching({
        program_id: currentProgram.id,
        video_id: id,
        sort: "asc",
        field: "created_at",
        page_number: 1,
        page_size: 10,
      }).unwrap();

      if (updatedData && updatedData.count > 0 && updatedData.data) {
        dispatch(
          setCurrentVideo({
            video,
            programId: currentProgram.id,
            progress: updatedData.data[0].current_position,
            total,
            continueWatchingId: updatedData.data[0].id,
          })
        );
      } else {
        // If the video is not in the continue watching list, create a new entry
        const response = await create({
          program_id: currentProgram.id,
          video_id: video.id,
          current_position: 0,
          is_complete: false,
        });

        dispatch(
          setCurrentVideo({
            video,
            programId: currentProgram.id,
            progress: 0,
            total: video.medias[0].length,
            continueWatchingId: response.data.id,
          })
        );
      }
      dispatch(setPlayMode("single"));
      navigate(`/browse/stream`);
    } catch (error) {
      console.error("Error fetching video continue watching data:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const renderChips = () => {
    const chipsToRender = [];

    for (let i = 0; i < video?.category.length; i++) {
      chipsToRender.push(
        <Chip
          key={i}
          label={video?.category[i]}
          className={classes.categoryChip}
          sx={{
            backgroundColor: "#EC674C40",
            borderRadius: 2,
            mr: theme.spacing(1),
          }}
        />
      );

      if (i === 1) {
        break;
      }
    }

    return chipsToRender;
  };

  return (
    <Card
      className={classes.card}
      sx={{ width: cardWidth, height: cardHeight, borderRadius: "10px" }}
      onClick={handleClick}
    >
      {loading && (
        <Box className={classes.loaderContainer}>
          <CircularProgress color="inherit" />
        </Box>
      )}
      <CardMedia
        className={classes.media}
        image={video?.thumbnails?.[0]?.url || "/images/thumbnail-placeholder.jpg"}
        title={video?.title}
      />
      <Tooltip title={"Remove Video"}>
        <IconButton
          onClick={handleRemoveClick}
          style={{ position: "absolute", top: 0, left: 0, zIndex: 9 }}
        >
          <DeleteIcon sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
      <Box className={classes.infoContainer}>
        <div className={classes.chipContainer}>{renderChips()}</div>
        <h2 className={classes.title}>{video?.title || ""}</h2>
        <BorderLinearProgress
          variant="determinate"
          value={total ? (progress / total) * 100 : 45}
          className={classes.progressBar}
        />
      </Box>
    </Card>
  );
}
