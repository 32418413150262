import React, { useState } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Theme,
  Container,
  FormControl,
  FormHelperText,
  InputAdornment,
  Typography,
  Divider,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import StyledTextField from "../../components/Input";
import { createProgramSchema } from "../../constants/validationSchemas";
import ImagePicker from "../../components/ImagePicker";
import Switch from "@mui/material/Switch";
import {
  useCreateProgramMutation,
  useUploadProgramPictureMutation,
} from "../../redux/api/programApi"; // Import the mutation hook
import { CreateProgramInput } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useAlert } from "../../contexts/AlertContext";
import InputLabel from "../../components/InputLabel";
import Select from "../../components/Select";
import { useTranslation } from "react-i18next";
import logo from "../../assets/TFLogo1.png";
import TopLevelBackButton from "../../components/TopLevelBackButton";

const placeholderImage =
  "https://www.treasury.gov.ph/wp-content/uploads/2022/01/male-placeholder-image.jpeg"; // Add your placeholder image path here

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between("xs", "md")]: {
      width: 150,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
  heading: {
    margin: 0,
    textAlign: "center",
    color: "#121212",
    fontFamily: "Avenir Next LT Pro",
    fontWeight: "bold",
    letterSpacing: 0,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },
  subheading: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    color: "#121212",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },
  form: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },
  divider: {
    backgroundColor: theme.palette.grey[400], // Use theme to set the gray color
    alignSelf: "stretch", // Make sure it takes full height within the flex container
    // marginLeft: theme.spacing(1), // Add some spacing if needed
    marginRight: theme.spacing(1), // Add some spacing if needed
    opacity: 0.5,
  },
}));

const AddProgramPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [programImage, setProgramImage] = useState<string | ArrayBuffer>(
    placeholderImage
  );
  const [programImageFile, setProgramImageFile] = useState<File | null>(null);
  const [requiresPin, setRequiresPin] = useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const company_id = useSelector((state: RootState) => state.user.user.id);

  const [createProgram, { isLoading }] = useCreateProgramMutation();
  const [uploadPicture] = useUploadProgramPictureMutation();
  const { error, success } = useAlert();
  const { t } = useTranslation();

  const handleImageChange = (
    newImage: string | ArrayBuffer,
    file: File | null
  ) => {
    setProgramImage(newImage);
    setProgramImageFile(file); // Store the file for later upload
  };

  const formik = useFormik({
    initialValues: {
      nickname: "",
      language: "",
      pin: "",
    },
    validationSchema: createProgramSchema,
    onSubmit: async (values) => {
      try {
        const newProgram: CreateProgramInput = {
          nickname: values.nickname,
          language: values.language,
          pin: requiresPin ? values.pin : undefined,
          company_id,
          // Add other necessary fields
        };
        const payload = await createProgram(newProgram).unwrap();

        // If image file is selected, upload it
        if (programImageFile && payload.data.id) {
          await uploadPicture({
            program_id: payload.data.id,
            picture: programImageFile,
          }).unwrap();
        }
        success(t("AddProgramPage.SuccessMessage"));
        navigate("/programs");
      } catch (err) {
        if (err instanceof Error) {
          error(`Could not create program. ${err.message}`);
          console.error(err);
        } else {
          error(t("AddProgramPage.ErrorMessage"));
        }
      }
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        position: "relative",
      }}
    >
      <TopLevelBackButton />
      <Box sx={{ alignSelf: "flex-start" }}>
        <img src={logo} alt="Logo" className={classes.logo} />
      </Box>
      <Container
        sx={{
          mx: "auto",
          width: {
            xs: "90%", // 100% width on extra-small devices
            sm: "80%", // 90% width on small devices
            md: "40%", // 80% width on medium devices
            lg: "33%", // 70% width on large devices
            xl: "33%", // 60% width on extra-large devices
          },
        }}
      >
        <h1 className={classes.heading}>
          {t("AddProgramPage.AddProgramsHeading")}
        </h1>

        <p className={classes.subheading}>
          {t("AddProgramPage.AddProgramsSubheading")}
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: theme.spacing(4),
          }}
        >
          <ImagePicker image={programImage} onImageChange={handleImageChange} />
        </div>
        <form onSubmit={handleSubmit}>
          {/** Name */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <StyledTextField
              label={t("AddProgramPage.NameLabel")}
              id="nickname"
              type="text"
              name="nickname"
              value={values.nickname}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              margin="none"
              placeholder={t("AddProgramPage.NamePlaceholder")}
              error={touched.nickname && Boolean(errors.nickname)}
            />
            {touched.nickname && errors.nickname && (
              <FormHelperText
                sx={{
                  color: "red",
                  fontSize: "12px",
                  margin: 0,
                }}
              >
                {errors.nickname}
              </FormHelperText>
            )}
          </FormControl>

          {/** Language */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="language">
              {t("AddProgramPage.LanguageLabel")}
            </InputLabel>
            <Select
              labelId="language"
              id="language"
              name="language"
              value={values.language}
              label={t("AddProgramPage.LanguageLabel")}
              placeholder={t("AddProgramPage.LanguagePlaceholder")}
              margin="none"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.language && Boolean(errors.language)}
            >
              <MenuItem value="">
                <em>{t("AddProgramPage.NoneOption")}</em>
              </MenuItem>
              <MenuItem value={"english"}>
                {t("AddProgramPage.EnglishOption")}
              </MenuItem>
              <MenuItem value={"spanish"}>
                {t("AddProgramPage.SpanishOption")}
              </MenuItem>
              <MenuItem value={"french"}>
                {t("AddProgramPage.FrenchOption")}
              </MenuItem>
            </Select>
            {touched.language && errors.language && (
              <FormHelperText
                sx={{ color: "red", fontSize: "12px", margin: 0 }}
              >
                {errors.language}
              </FormHelperText>
            )}
          </FormControl>

          {/** Passcode */}
          <FormControl fullWidth>
            <StyledTextField
              label={t("AddProgramPage.PasscodeLabel")}
              id="pin"
              type="password"
              name="pin"
              value={values.pin}
              disabled={!requiresPin}
              required={requiresPin}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              margin="none"
              placeholder={t("AddProgramPage.PasscodePlaceholder")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box display="flex" alignItems="center">
                      <Divider
                        className={classes.divider}
                        orientation="vertical"
                        flexItem
                      />
                      <Switch
                        checked={requiresPin}
                        onChange={(e) => setRequiresPin(e.target.checked)}
                        name="requiresPin"
                        id="requiresPin"
                        color="primary"
                      />
                      <Typography variant="body2">
                        {requiresPin
                          ? t("AddProgramPage.OnSwitch")
                          : t("AddProgramPage.OffSwitch")}
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
              error={touched.pin && Boolean(errors.pin)}
            />
            {touched.pin && errors.pin && (
              <FormHelperText
                sx={{ color: "red", fontSize: "12px", margin: 0 }}
              >
                {errors.pin}
              </FormHelperText>
            )}
          </FormControl>
          <div style={{ textAlign: "center", marginBottom: theme.spacing(4) }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              disabled={isLoading} // Disable button when loading
              sx={{
                width: "fit-content",
                fontSize: isMdUp ? "1rem" : "inherit",
                background: "#EC674C",
                opacity: 1,
                paddingX: theme.spacing(6),
                paddingy: theme.spacing(1),
                mt: theme.spacing(4),
                ml: isMdUp ? theme.spacing(2) : 0,
                textTransform: "none",
                borderRadius: isMdUp ? "40px" : "30px",
                "&:hover": {
                  background: "#EC674C", // Change background color on hover
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("AddProgramPage.SaveButton")
              )}
            </Button>
          </div>
        </form>
      </Container>
    </Box>
  );
};

export default AddProgramPage;
