import React, { useCallback, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import Hero from "./components/Hero";
import CategoryList from "./components/CategoryList";
import useBreakpoints from "../../hooks/useBreakPoints";
import { useGetCategoriesQuery } from "../../redux/api/categoryApi";
import ErrorView from "../../components/ErrorView";
import { useTranslation } from "react-i18next";
import { useGetAllVideosQuery, useGetRecommendedVideosQuery } from "../../redux/api/videoApi";
import CategoryHeroSkeleton from "../../components/Skeletons/CategoryHeroSkeleton";
import { Video } from "../../types";

interface CategorySections {
  [key: string]: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
  categoriesContainer: {
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  appBar: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(12),
    },
  },
  categoryTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",

    marginRight: theme.spacing(2),
  },
  chipRow: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    width: "100%",
  },
  navChip: {
    margin: theme.spacing(1),
  },
}));

const CategoriesPage = () => {
  // const theme = useTheme();
  const [isAppBarFixed, setIsAppBarFixed] = useState(false);
  const [activeCategory, setActiveCategory] = useState("new releases");
  const { isXs, isSm } = useBreakpoints();
  const { t } = useTranslation();
  const classes = useStyles({ isAppBarFixed });

  const [categoriesWithVideos, setCategoriesWithVideos] = useState<string[]>(
    []
  );

  const {
    data: categoriesData,
    isError,
    // isLoading,
  } = useGetCategoriesQuery({
    page_number: 1,
    page_size: 10,
    field: "created_at",
    sort: "asc",
  });

  const {
    data: recommendationsData,
    isLoading: isFetchingRecommendations,
    // isError: isRecommendationsError,
  } = useGetRecommendedVideosQuery({
    page_size: 10,
    page_number: 1,
    sort: "asc",
    field: "",
    published: true,
  });

  const {
    data: allVideos,
    // isLoading: iaFetchingAllVideos,
    // isError: isAllVideosError,
  } = useGetAllVideosQuery({
    page_size: 10,
    page_number: 1,
    sort: "asc",
    field: "created_at",
    published: true,
  });

  const featuredVideo: Video =
    recommendationsData?.count > 0
      ? recommendationsData?.data[0]
      : allVideos?.data[0]

  const formatCateoryName = (name: string) => {
    return name.toLowerCase().replace(/ /g, "-");
  };

  const categories = categoriesData?.data || [];

  // Dynamically create category sections based on API data
  const categorySections = categories.reduce((sections, category) => {
    sections[formatCateoryName(category.name)] = formatCateoryName(
      category.name
    );
    return sections;
  }, {} as CategorySections);

  // Add a callback to update categoriesWithVideos
  const updateCategoryWithVideos = useCallback((categoryName: string) => {
    setCategoriesWithVideos((prev) => [...prev, categoryName]);
  }, []);

  // Filter the displayed categories to only show those with videos
  const filteredCategories = categories.filter((category) =>
    categoriesWithVideos.includes(formatCateoryName(category.name))
  );

  const handleChipClick = (category: string) => {
    setActiveCategory(category);
    const appBarHeight =
      document.querySelector("#main-app-bar")?.clientHeight || 0;
    const sectionId = categorySections[category];
    const section = document.getElementById(sectionId);
    if (section) {
      const sectionTop =
        section.getBoundingClientRect().top + window.scrollY - appBarHeight;
      window.scrollTo({ top: sectionTop, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      // Get the current AppBar height
      const appBarHeight =
        document.querySelector("#main-app-bar")?.clientHeight || 0;

      // Check if hero element top position is above the viewport
      const heroElement = document.getElementById("categories-hero");
      if (heroElement) {
        const heroTop = heroElement.getBoundingClientRect().top;
        setIsAppBarFixed(heroTop < 0);
      }

      // Update active category based on scroll
      for (const category in categorySections) {
        const sectionId = categorySections[category];
        const section = document.getElementById(sectionId);
        if (section) {
          const bounds = section.getBoundingClientRect();
          if (
            bounds.top < window.innerHeight - appBarHeight &&
            bounds.bottom >= appBarHeight
          ) {
            setActiveCategory(category);
            break;
          }
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [categorySections]);

  if (isError) {
    return (
      <ErrorView
        message={t("CategoriesPage.ErrorLoadingCategories")}
        type="error"
      />
    );
  }

  return (
    <div>
      {/* Categories container */}
      {!isXs && !isSm && (
        <AppBar
          id="main-app-bar"
          position={"fixed"}
          color={isAppBarFixed ? "inherit" : "transparent"}
          elevation={isAppBarFixed ? 1 : 0}
          className={classes.appBar}
        >
          <Toolbar>
            <h2
              className={classes.categoryTitle}
              style={{ color: isAppBarFixed ? "black" : "white" }}
            >
              {t("CategoriesPage.AppBarTitle")}
            </h2>
            <div className={classes.chipRow}>
              {filteredCategories.length
                ? filteredCategories.map((category) => {
                    const isActive =
                      formatCateoryName(category.name) === activeCategory;
                    return (
                      <Chip
                        key={category.id}
                        label={category.name}
                        onClick={() =>
                          handleChipClick(formatCateoryName(category.name))
                        }
                        className={classes.navChip}
                        sx={{
                          backgroundColor: isActive ? "#EC674C" : "transparent",
                          color: isActive
                            ? "#FFF"
                            : isAppBarFixed
                            ? "black"
                            : "white",
                          border: isActive
                            ? "none"
                            : `1px solid ${isAppBarFixed ? "black" : "white"}`,
                        }}
                      />
                    );
                  })
                : ""}
            </div>
          </Toolbar>
        </AppBar>
      )}
      {isFetchingRecommendations ? (
        <CategoryHeroSkeleton />
      ) : (
        <Hero heroDetails={featuredVideo}>
          {categories.length ? (
            <CategoryList
              videos={recommendationsData?.data ?? allVideos?.data}
              isChild={true}
              category={categories[0]}
              sectionId={
                categorySections[formatCateoryName(categories[0].name)]
              }
            />
          ) : undefined}
        </Hero>
      )}
      {/* Dynamic category sections */}
      {categories.length
        ? categories
            .slice(1)
            .map((category) => (
              <CategoryList
                key={category.id}
                category={category}
                sectionId={formatCateoryName(category.name)}
                onVideosFetched={updateCategoryWithVideos}
              />
            ))
        : ""}
    </div>
  );
};

export default CategoriesPage;
