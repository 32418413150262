import { baseApi } from "./baseApi";
import { CategoryQueryParams, CategoryListResponse } from "../../types";
import { GET_ALL_CATEGORIES_ENDPOINT } from "../../constants/endpoints";

export const categoriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<CategoryListResponse, CategoryQueryParams>({
      query: (params) => ({
        url: GET_ALL_CATEGORIES_ENDPOINT,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Category" as const,
                id,
              })),
              { type: "Category", id: "LIST" },
            ]
          : [{ type: "Category", id: "LIST" }],
    }),
  }),
});

export const { useGetCategoriesQuery } = categoriesApi;
