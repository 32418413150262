// notificationApi.ts
import { baseApi } from "./baseApi"; // Adjust the path accordingly
import {
  GET_ALL_MY_NOTIFICATIONS_ENDPOINT,
  SEEN_ALL_MY_NOTIFICATIONS_ENDPOINT,
  DELETE_NOTIFICATION_ENDPOINT,
  INTERACT_WITH_NOTIFICATION_ENDPOINT,
} from "../../constants/endpoints";
import {
  GetAllNotificationsParams,
  Notification,
  SeenNotificationsParams,
} from "../../types";

export const notificationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({

    // Query to get all notifications
    getAllMyNotifications: builder.query<
      { data: Notification[]; count: number },
      GetAllNotificationsParams
    >({
      query: (params) => ({ url: GET_ALL_MY_NOTIFICATIONS_ENDPOINT, params }),
      providesTags: (result) =>
          result
            ? [
                ...result.data.map(({ id }) => ({
                  type: "Notifications" as const,
                  id,
                })),
                { type: "Notifications", id: "LIST" },
              ]
            : [{ type: "Notifications", id: "LIST" }],
    }),

    // Mutation to mark notifications as seen
    seenAllMyNotifications: builder.mutation<
      { message: string },
      SeenNotificationsParams
    >({
      query: (body) => ({
        url: SEEN_ALL_MY_NOTIFICATIONS_ENDPOINT,
        method: "POST",
        body,
      }),
      invalidatesTags: ['Notifications'],
    }),

    // Mutation to delete a notification
    deleteNotification: builder.mutation<{success: boolean}, string>({
      query: (notificationId) => ({
        url: DELETE_NOTIFICATION_ENDPOINT(notificationId),
        method: "DELETE",
      }),
      invalidatesTags: (result, error, notificationId) => [{ type: 'Notifications', id: notificationId }],
    }),
    
    // Mutation to interact with a notification
    interactWithNotification: builder.mutation<{ success: boolean }, string>({
      query: (notificationId) => ({
        url: INTERACT_WITH_NOTIFICATION_ENDPOINT(notificationId),
        method: "POST",
      }),
      invalidatesTags: ['Notifications'],
    }),
    // ... other endpoints if necessary
  }),
  // Add overrideExisting flag if needed
});



// Export hooks for using these endpoints in components
export const {
  useGetAllMyNotificationsQuery,
  useSeenAllMyNotificationsMutation,
  useDeleteNotificationMutation,
  useInteractWithNotificationMutation,
} = notificationApi;
