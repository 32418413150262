// Import necessary dependencies
import { baseApi } from "./baseApi";
import type {
  Video,
  VideoListResponse,
  VideoQueryParams,
  VideoSearchQueryParams,
} from "../../types";
import {
  GET_ALL_VIDEOS_ENDPOINT,
  SEARCH_VIDEOS_ENDPOINT,
  RECOMMENDED_VIDEOS_ENDPOINT,
  GET_VIDEO_BY_ID_ENDPOINT,
} from "../../constants/endpoints";

// Implement the videoApi
export const videoApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllVideos: builder.query<{count: number, data: Video[]}, VideoQueryParams>({
      query: (params) => ({
        url: GET_ALL_VIDEOS_ENDPOINT,
        params
      }),
      // Add tag types if necessary
    }),
    searchVideos: builder.query<{data: Video[], count: number}, VideoSearchQueryParams>({
      query: (params) => ({
        url: SEARCH_VIDEOS_ENDPOINT,
        params,
      }),
    }),
    getRecommendedVideos: builder.query<VideoListResponse, VideoQueryParams>({
      query: (params) => ({
        url: RECOMMENDED_VIDEOS_ENDPOINT,
        params,
      }),
      // Add tag types if necessary
    }),
    getVideoById: builder.query<{data: Video}, string>({
      query: (videoId) => GET_VIDEO_BY_ID_ENDPOINT(videoId),
      // Add tag types if necessary
    }),
  }),
  // Add overrideExisting flag if needed
});

export const {
  useGetAllVideosQuery,
  useSearchVideosQuery,
  useGetRecommendedVideosQuery,
  useGetVideoByIdQuery,
  useLazyGetVideoByIdQuery
} = videoApi;
