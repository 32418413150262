import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";

interface SubscriptionExpiredDialogProps {
  open: boolean;
  onClose: () => void;
  onRenewClick: () => void;
}
const SubscriptionExpiredDialog = ({
  open,
  onClose,
  onRenewClick,
}: SubscriptionExpiredDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="subscription-expired-dialog-title"
    >
      <DialogTitle id="subscription-expired-dialog-title">
        Subscription Expired
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="subscription-expired-dialog-description"
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <ErrorOutlineIcon color="error" sx={{ fontSize: 40 }} />
          Your subscription has expired. Please renew your subscription to
          continue accessing all features.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={onRenewClick}
          variant="contained"
          color="primary"
          autoFocus
        >
          Renew Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionExpiredDialog;
