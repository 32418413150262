import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../redux/store";
import { PROFILE_PROTECTED_ROUTES } from "../constants/constants";

interface ProtectedRouteProps {
  handleBaseRedirect?: boolean;
  children: JSX.Element; // Ensure children is always provided.
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ handleBaseRedirect, children }) => {
  const { loggedIn, access_token } = useSelector((state: RootState) => state.user);
  const { currentProgram } = useSelector((state: RootState) => state.program)
  const location = useLocation();

  if (!loggedIn || !access_token) {
    // Redirect to login
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  } else if (!currentProgram && PROFILE_PROTECTED_ROUTES.includes(location.pathname)) {
    // Redirect to choose-program if the user has no current profile
    return <Navigate to="/programs" state={{ from: location }} replace />;
  } else if (loggedIn && access_token && currentProgram && handleBaseRedirect) {
    // Redirect to dashboard if the user is authenticated and has a current profile
    return <Navigate to="/browse" state={{ from: location }} replace />;
  } else if (loggedIn && access_token && !currentProgram && handleBaseRedirect) {
    // Redirect to dashboard if the user is authenticated and has a current profile
    return <Navigate to="/programs" state={{ from: location }} replace />;
  }
  

  // If all checks pass, return the children wrapped in JSX
  return children  || null;
};

export default ProtectedRoute;
