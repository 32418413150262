import React from "react";
// import { makeStyles } from "@mui/styles";
import Sidebar from "../SideBarLayout";
import Header from "../Header";
// import { Theme } from "@mui/material";
import { useLocation } from "react-router-dom";

/**
 * @description Creates JSS styles for the DashboardLayout component.
 * @param {Theme} theme - The MUI theme object for theming styles.
 * @returns {ClassNameMap} A collection of class names generated for styling.
 */
// const useStyles = makeStyles((theme: Theme) => ({
//   // Style for the main container of the dashboard layout
//   dashboardContainer: {
//     height: "100%",
//     display: "flex",
//     flexDirection: "column",
//     backgroundColor: theme.palette.background.default, // Using theme value for background color
//     // Additional styles can be added here as needed
//   },
//   // Define more styles for other elements if necessary
// }));

/**
 * @description Props interface for DashboardLayout component.
 * @typedef {Object} DashBoardProps
 * @property {JSX.Element} children - Child components to be rendered inside the layout.
 */
interface DashBoardProps {
  children: JSX.Element;
}

/**
 * @description DashboardLayout component to structure the main dashboard area.
 * It includes the Header and Sidebar components and renders child components.
 * @param {DashBoardProps} props - Props for the DashboardLayout.
 * @returns {React.ReactElement} - Rendered DashboardLayout component.
 */
const DashboardLayout: React.FC<DashBoardProps> = ({ children }) => {
  const location = useLocation(); // Get the current location

  // Check if the pathname includes 'stream'
  const isStreaming = location.pathname.includes('stream');

  return (
    <div >
      {!isStreaming && <Header />} {/* Conditionally render Header */}
      {!isStreaming && <Sidebar />} {/* Conditionally render Sidebar */}
      {children} {/* Always render children */}
    </div>
  );
};

export default DashboardLayout;
