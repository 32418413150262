import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  useTheme,
} from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import {
  useGetSubscriptionQuery,
  useUnsubscribeSubscriptionMutation,
} from "../../../../redux/api/subscriptionApi";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux/store";
import { setFeatures, setFreeTrialBannerDismissed } from "../../../../redux/state/userSlice";
import useBreakpoints from "../../../../hooks/useBreakPoints";
import { useAlert } from "../../../../contexts/AlertContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SubscriptionSettings = () => {
  const subscriptionId = useSelector(
    (state: RootState) => state.user.user.stripe?.subscription
  );
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { isMd, isLg, isXl } = useBreakpoints();
  const theme = useTheme();
  const { error, success } = useAlert();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch subscription details
  const { data: subscription, isLoading } = useGetSubscriptionQuery(
    subscriptionId || ""
  );

  // Get user data for the free trial banner
  const { user } = useSelector((state: RootState) => state.user);
  const { free_trial, free_trial_end_date } = user;

  // Unsubscribe mutation
  const [unsubscribe, { isLoading: isUnsubscribing }] =
    useUnsubscribeSubscriptionMutation();

    const handleUnsubscribe = async (cancelImmediately: boolean) => {
      if (subscriptionId) {
        if (cancelImmediately) {
          try {
            await unsubscribe({ subscriptionId, immediately: true }).unwrap();
            success(t("Settings.SubscriptionSettings.SuccessUnsubscribe"));
          } catch (err) {
            console.log(err)
            error(t("Settings.SubscriptionSettings.ErrorUnsubscribe"));
          }
        } else {
          try {
            await unsubscribe({ subscriptionId }).unwrap();
            success(t("Settings.SubscriptionSettings.SuccessUnsubscribe"));
          } catch (err) {
            console.log(err)
            error(t("Settings.SubscriptionSettings.ErrorUnsubscribe"));
          }
        }
       
        setShowConfirmationDialog(false);
      }
    };

  if (isLoading) {
    return <div>{t("Settings.SubscriptionSettings.LoadingMessage")}</div>;
  }

  const displaySubscription = subscription?.data;

  // Function to format date
  const formatDate = (dateString: string | number | Date | undefined) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Render the free trial banner
  const renderFreeTrialBanner = () => {
    if (
      !free_trial ||
      !free_trial_end_date ||
      new Date() >= new Date(free_trial_end_date)
    ) {
      return null;
    }

    const formattedDate = new Date(free_trial_end_date).toLocaleDateString(
      undefined,
      {
        year: "numeric",
        month: "long",
        day: "numeric",
      }
    );

    const handleCloseBanner = () => {
      dispatch(setFreeTrialBannerDismissed(true));
    };

    return (
      <Box
        sx={{
          padding: theme.spacing(1),
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          textAlign: "center",
          position: "relative",
          borderRadius: theme.spacing(1),
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(5),
        }}
      >
        <CampaignIcon
          fontSize="large"
          sx={{ color: "white", marginRight: theme.spacing(1) }}
        />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="body1" textAlign={"left"}>
            {t("Settings.SubscriptionSettings.FreeTrial")}
          </Typography>
          <Typography variant="body2" textAlign={"left"}>
            {t("Settings.SubscriptionSettings.Ends")} {formattedDate}
          </Typography>
        </Box>
        <IconButton
          size="small"
          onClick={handleCloseBanner}
          sx={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.primary.contrastText,
          }}
        >
          <CampaignIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  // Function to check subscription status
  const checkSubscription = useCallback(() => {
    if (subscription?.data) {
      const currentDate = new Date();
        const expiresAt = subscription.data.expires_at
          ? new Date(subscription.data.expires_at)
          : null;
      if (expiresAt === null && subscription.data.status === "cancel") {
        dispatch(
          setFeatures({
            devices: subscription.data.details?.devices ?? 0,
            features: subscription.data.details ?? null,
            free_trial: null,
            free_trial_end_date: null,
          })
        );
        navigate("/programs");
      }  else if (subscription.data.status === "cancel") {
        if (expiresAt && currentDate >= expiresAt) {
          dispatch(
            setFeatures({
              devices: subscription.data.details?.devices ?? 0,
              features: subscription.data.details ?? null,
              free_trial: null,
              free_trial_end_date: null,
            })
          );
          navigate("/programs");
        }
      }
    }
  }, [subscription, navigate]);

  useEffect(() => {
    checkSubscription();
  }, [checkSubscription]);


  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" mb={5}>
        {t("Settings.SubscriptionSettings.Title")}
      </Typography>
      {renderFreeTrialBanner()}


      <div style={{ display: "flex", marginBottom: 10, width: "100%" }}>
        <Typography variant="body1" mr={1}>
          {t("Settings.SubscriptionSettings.Status")}
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: displaySubscription?.status === "active" ? "green" : "red",
          }}
        >
          {displaySubscription?.status || "N/A"}
        </Typography>
      </div>

      <Typography variant="body1" gutterBottom>
        {t("Settings.SubscriptionSettings.Starts")}{" "}
        {formatDate(displaySubscription?.starts_at)}
      </Typography>

      <Typography variant="body1" gutterBottom>
        {t("Settings.SubscriptionSettings.Expires")}{" "}
        {formatDate(displaySubscription?.expires_at)}
      </Typography>

      {displaySubscription?.canceled_at && (
        <Typography variant="body1" gutterBottom>
          {t("Settings.SubscriptionSettings.CanceledAt")}{" "}
          {formatDate(displaySubscription.canceled_at)}
        </Typography>
      )}

      <Typography variant="h6" mt={2}>
        {t("Settings.SubscriptionSettings.Features")}
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            {t("Settings.SubscriptionSettings.VideoResource")}:{" "}
            {displaySubscription?.details?.video_resource
              ? t("Settings.SubscriptionSettings.Enabled")
              : t("Settings.SubscriptionSettings.Disabled")}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {t("Settings.SubscriptionSettings.Recommendations")}:{" "}
            {displaySubscription?.details?.recommendations
              ? t("Settings.SubscriptionSettings.Enabled")
              : t("Settings.SubscriptionSettings.Disabled")}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {t("Settings.SubscriptionSettings.Playlist")}:{" "}
            {displaySubscription?.details?.playlist
              ? t("Settings.SubscriptionSettings.Enabled")
              : t("Settings.SubscriptionSettings.Disabled")}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {t("Settings.SubscriptionSettings.Devices")}:{" "}
            {displaySubscription?.details?.devices || 0}
          </Typography>
        </li>
      </ul>

      <Button
        onClick={() => setShowConfirmationDialog(true)}
        variant="contained"
        type="submit"
        fullWidth
        disabled={isUnsubscribing}
        color="primary"
        size="large"
        sx={{
          fontSize: isMd || isLg || isXl ? "1rem" : "inherit",
          background: "#EC674C",
          opacity: 1,
          paddingX: theme.spacing(6),
          paddingY: theme.spacing(1),
          my: theme.spacing(4),
          textTransform: "none",
          borderRadius: isMd || isLg || isXl ? "40px" : "30px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            background: "#EC674C",
          },
        }}
      >
        {t("Settings.SubscriptionSettings.UnsubscribeButton")}
      </Button>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
      >
        <DialogTitle>
          {t("Settings.SubscriptionSettings.UnsubscribeTitle")}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t("Settings.SubscriptionSettings.UnsubscribeMessage")}
          </Typography>
          <Box mt={2} mb={2}>
            <Typography variant="subtitle1">
              {t("Settings.SubscriptionSettings.Option1Title")}
            </Typography>
            <Typography variant="body2">
              {t("Settings.SubscriptionSettings.Option1Description")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUnsubscribe(true)}
              disabled={isUnsubscribing}
              sx={{ mt: 1, textTransform: "none", boxShadow: "none" }}
            >
              {t("Settings.SubscriptionSettings.Option1Button")}
            </Button>
          </Box>
          <Box mt={2} mb={2}>
            <Typography variant="subtitle1">
              {t("Settings.SubscriptionSettings.Option2Title")}
            </Typography>
            <Typography variant="body2">
              {t("Settings.SubscriptionSettings.Option2Description", {
                expirationDate: formatDate(displaySubscription?.expires_at),
              })}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUnsubscribe(false)}
              disabled={isUnsubscribing}
              sx={{ mt: 1, textTransform: "none", boxShadow: "none" }}
            >
              {t("Settings.SubscriptionSettings.Option2Button")}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowConfirmationDialog(false)}
            color="primary"
          >
            {t("Settings.SubscriptionSettings.CancelButton")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubscriptionSettings;
