import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  // Switch,
  Typography,
  // Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  // InputAdornment,
  useTheme,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  CircularProgress,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import StyledTextField from "../../../../components/Input";
import { useFormik } from "formik";
import { editProgramSchema } from "../../../../constants/validationSchemas";
import useBreakpoints from "../../../../hooks/useBreakPoints";
import { logout } from "../../../../redux/state/userSlice";
import { t } from "i18next";
import { makeStyles } from "@mui/styles";
import { useUpdateProgramMutation } from "../../../../redux/api/programApi";
import { CreateProgramInput } from "../../../../types";
import { useAlert } from "../../../../contexts/AlertContext";
import { setCurrentProgram } from "../../../../redux/state/programSlice";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import {
  useDeleteCurrentCompanyMutation,
  useVerifyPasswordMutation,
} from "../../../../redux/api/companyApi";
import PasswordModal from "../../../../components/PasswordModal";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    backgroundColor: theme.palette.grey[400], // Use theme to set the gray color
    alignSelf: "stretch", // Make sure it takes full height within the flex container
    // marginLeft: theme.spacing(1), // Add some spacing if needed
    marginRight: theme.spacing(1), // Add some spacing if needed
    opacity: 0.5,
  },
  logoutRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccountSettings = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const program = useSelector(
    (state: RootState) => state.program.currentProgram
  );

  // const [requiresPin, setRequiresPin] = useState(
  //   program?.pin?.length ? true : false
  // );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);

  const { isMd, isLg, isXl } = useBreakpoints();
  const { error, success } = useAlert();

  const [updateProgram, { isLoading: isUpdating }] = useUpdateProgramMutation();
  const [verifyPassword] = useVerifyPasswordMutation();
  const [deleteAccount] = useDeleteCurrentCompanyMutation();

  // const handleToggleTheme = () => {
  //   dispatch(togglePersonalTheme());
  // };

  const handleLogout = () => {
    dispatch(setCurrentProgram(null));
    dispatch(logout());
  };

  // const handleToggle = () => {
  //   dispatch(togglePersonalTheme());
  // };

  const handleDeleteAccount = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDeleteAccount = () => {
    setIsDeleteDialogOpen(false);
    setIsPasswordDialogOpen(true); // Open password modal after confirmation
  };

  const handlePasswordVerification = async (password: string) => {
    try {
      // Assuming verifyPassword API takes { password } and returns { verified: boolean }
      const response = await verifyPassword({ password }).unwrap();
      if (response.result) {
        const deleteResponse = await deleteAccount().unwrap(); // Assuming deleteAccount is an async thunk
        if (deleteResponse.success) {
          dispatch(logout()); // Logout user after account deletion
          success("Account Deleted");
        } else {
          error("Could Not Delete Account");
        }
      } else {
        error("Password verification failed.");
      }
    } catch (e) {
      console.error("Failed to verify password:", e);
      error("An error occurred during password verification.");
    }
    setIsPasswordDialogOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      nickname: program?.nickname,
      language: program?.language,
      requiresPin: program?.pin ? true : false,
      pin: program?.pin,
    },
    validationSchema: editProgramSchema,
    onSubmit: async (values) => {
      try {
        const updatedProgram: Partial<CreateProgramInput> & {
          program_id: string;
        } = {
          nickname: values.nickname,
          language: values.language,
          pin: values.pin || undefined,
          program_id: program?.id,
        };
        const newProgram = await updateProgram(updatedProgram).unwrap();
        console.log("Updated program data:", newProgram); // Debugging log

        if (newProgram && newProgram.success) {
          dispatch(setCurrentProgram(newProgram.data));
          success(t("EditProgramPage.SuccessUpdateMessage"));
        }
      } catch (err) {
        console.log(err);
        error(t("EditProgramPage.ErrorUpdateMessage"));
      }
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Typography variant="h4" mb={4}>
          {t("AccountSettings.Title")}
        </Typography>
        {/** Name */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <StyledTextField
            label={t("AddProgramPage.NameLabel")}
            id="nickname"
            type="text"
            name="nickname"
            value={values.nickname}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            margin="none"
            placeholder={t("AddProgramPage.NamePlaceholder")}
            error={touched.nickname && Boolean(errors.nickname)}
          />
          {touched.nickname && errors.nickname && (
            <FormHelperText
              sx={{
                color: "red",
                fontSize: "12px",
                margin: 0,
              }}
            >
              {errors.nickname}
            </FormHelperText>
          )}
        </FormControl>

        {/** Language */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="language">
            {t("AddProgramPage.LanguageLabel")}
          </InputLabel>
          <Select
            labelId="language"
            id="language"
            name="language"
            value={values.language}
            label={t("AddProgramPage.LanguageLabel")}
            placeholder={t("AddProgramPage.LanguagePlaceholder")}
            margin="none"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.language && Boolean(errors.language)}
          >
            <MenuItem value="">
              <em>{t("AddProgramPage.NoneOption")}</em>
            </MenuItem>
            <MenuItem value={"english"}>
              {t("AddProgramPage.EnglishOption")}
            </MenuItem>
            <MenuItem value={"spanish"}>
              {t("AddProgramPage.SpanishOption")}
            </MenuItem>
            <MenuItem value={"french"}>
              {t("AddProgramPage.FrenchOption")}
            </MenuItem>
          </Select>
          {touched.language && errors.language && (
            <FormHelperText sx={{ color: "red", fontSize: "12px", margin: 0 }}>
              {errors.language}
            </FormHelperText>
          )}
        </FormControl>

        {/** Passcode */}
        {/* <FormControl fullWidth>
          <StyledTextField
            label={t("AddProgramPage.PasscodeLabel")}
            id="pin"
            type="password"
            name="pin"
            value={values.pin}
            disabled={!requiresPin}
            required={requiresPin}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            margin="none"
            placeholder={t("AddProgramPage.PasscodePlaceholder")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box display="flex" alignItems="center">
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                      flexItem
                    />
                    <Switch
                      checked={requiresPin}
                      onChange={(e) => setRequiresPin(e.target.checked)}
                      name="requiresPin"
                      id="requiresPin"
                      color="primary"
                    />
                    <Typography variant="body2">
                      {requiresPin
                        ? t("AddProgramPage.OnSwitch")
                        : t("AddProgramPage.OffSwitch")}
                    </Typography>
                  </Box>
                </InputAdornment>
              ),
            }}
            error={touched.pin && Boolean(errors.pin)}
          />
          {touched.pin && errors.pin && (
            <FormHelperText sx={{ color: "red", fontSize: "12px", margin: 0 }}>
              {errors.pin}
            </FormHelperText>
          )}
        </FormControl> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            sx={{
              width: "fit-content",
              fontSize: isMd || isLg || isXl ? "1rem" : "inherit",
              background: "#EC674C",
              opacity: 1,
              paddingX: theme.spacing(6),
              paddingy: theme.spacing(1),
              my: theme.spacing(4),
              textTransform: "none",
              borderRadius: isMd || isLg || isXl ? "40px" : "30px",
              "&:hover": {
                background: "#EC674C", // Change background color on hover
              },
            }}
          >
            {isUpdating ? <CircularProgress /> : t("AddProgramPage.SaveButton")}
          </Button>
        </div>
        <Divider />
        <div className={classes.logoutRow}>
          {/** Dark Mode Switch */}
          {/* <FormGroup sx={{ mt: theme.spacing(2) }}>
            <FormControlLabel
              control={<Switch checked={isDarkMode} onChange={handleToggle} />}
              label={t("AccountSettings.DarkMode")}
            />
          </FormGroup> */}

          {/** Logout Button */}
          <IconButton
            onClick={handleLogout}
            sx={{
              mt: theme.spacing(2),
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <LogoutIcon />
            <Typography variant="body2" ml={theme.spacing(2)}>
              {t("AccountSettings.LogoutButton")}
            </Typography>
          </IconButton>
        </div>

        {/** Delete Account Button */}
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleDeleteAccount();
          }}
          variant="contained"
          type="submit"
          fullWidth
          color="primary"
          size="large"
          sx={{
            fontSize: isMd || isLg || isXl ? "1rem" : "inherit",
            background: "#EC674C",
            opacity: 1,
            paddingX: theme.spacing(6),
            paddingy: theme.spacing(1),
            my: theme.spacing(4),
            textTransform: "none",
            borderRadius: isMd || isLg || isXl ? "40px" : "30px",
            "&:hover": {
              background: "#EC674C", // Change background color on hover
            },
          }}
        >
          {t("AccountSettings.DeleteAccountButton")}
        </Button>
      </form>

      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => handleConfirmDeleteAccount()}
        title={t("AccountSettings.DeleteAccountDialog.Title")}
        content={t("AccountSettings.DeleteAccountDialog.Content")}
      />
      <PasswordModal
        open={isPasswordDialogOpen}
        onClose={() => setIsPasswordDialogOpen(false)}
        onPasswordSubmit={handlePasswordVerification}
      />
    </>
  );
};

export default AccountSettings;
