import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  Container,
  Theme,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PinInputField from "../PinInput"; // Assuming this is a component for input fields
import useBreakpoints from "../../hooks/useBreakPoints";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.between("xs", "md")]: {
      width: 241,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 250,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
  heading: {
    margin: 0,
    textAlign: "center",
    color: "#121212",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },
  subheading: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
    color: "#121212",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },
  card: {
    width: "100%", // This makes the card take the full width of the container
    // Using a pseudo-element to create an aspect ratio of 1:1
    "&::before": {
      content: '""',
      display: "block",
      paddingTop: "100%", // This creates the square shape
    },
    position: "relative", // Needed for absolute positioning of the CardMedia
  },
  cardMedia: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: "100%", // This will make the CardMedia fill the entire space of the card
    width: "100%",
  },
  cardName: {
    marginTop: theme.spacing(1),
    textAlign: "center", // This will center the text below the image
  },
  addActionCard: {
    width: "100%",
    "&::before": {
      content: '""',
      display: "inline",
      paddingTop: "100%",
    },
    position: "relative",
    textAlign: "center",
  },
  addButton: {
    width: "100%",
    // Center the content
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // Set the height to match other cards (adjust the value as needed)
    height: "100%", // Assuming other cards have a height that fits the content
    border: "1px dashed",
    borderColor: theme.palette.action.disabled,
    borderRadius: theme.shape.borderRadius,
  },
  addIcon: {
    // If you need to adjust the size, set it here
    fontSize: "large", // Adjust size as needed
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface ConfirmAccountModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmationSubmit: (code: string) => void;
  onCodeResend: () => void;
  codeLength?: number; // Optional prop for code length
  loading: boolean;
}

const ConfirmAccountModal: React.FC<ConfirmAccountModalProps> = ({
  open,
  onClose,
  onConfirmationSubmit,
  onCodeResend,
  codeLength = 5, // Default length for a confirmation code
  loading
}) => {
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [codes, setCodes] = useState(Array(codeLength).fill(""));
  const codeInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const classes = useStyles();
  const theme = useTheme();
  const { isXs, isSm } = useBreakpoints();
  const { t } = useTranslation();

  useEffect(() => {
    if (codes.every((code) => code.length === 1)) {
      onConfirmationSubmit(codes.join(""));
      setCodes(Array(codeLength).fill(""));
    }
  }, [codes, onConfirmationSubmit, codeLength]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (resendDisabled) {
      setCountdown(60); // Start with 60 seconds
      timer = setInterval(() => {
        setCountdown((prevTime) => prevTime - 1);
      }, 1000);
    }

    // Cleanup timer on component unmount
    return () => clearInterval(timer);
  }, [resendDisabled]);

  // Update the countdown state based on timer
  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(countdown);
      setResendDisabled(false); // Re-enable the button
    }
  }, [countdown]);

  const handleResendClick = () => {
    onCodeResend(); // Call the resend function
    setResendDisabled(true); // Disable the button
  };


  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newCodes = [...codes];
    newCodes[index] = event.target.value.slice(0, 1);
    setCodes(newCodes);

    // Focus next pin input after current input is filled
    if (index < codeLength - 1 && event.target.value) {
      codeInputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index: number) => {
    if (index > 0) {
      const newCodes = [...codes];
      newCodes[index - 1] = "";
      setCodes(newCodes);
      codeInputRefs.current[index - 1]?.focus();
    }
  };

  const handleClose = () => {
    setCodes(Array(codeLength).fill("")); // Reset with dynamic length
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="account-confirmation-modal"
      aria-describedby="Enter confirmation code to verify your account"
    >
      <Box sx={{ ...style }}>
        <h1 className={classes.heading}>{t("ConfirmAccountModal.Title")}</h1>
        <p className={classes.subheading}>
          {t("ConfirmAccountModal.Subtitle")}
        </p>

        <Container
          sx={{
            mx: "auto",
            width: {
              xs: "90%", // 100% width on extra-small devices
              sm: "80%", // 90% width on small devices
              md: "70%", // 80% width on medium devices
              lg: "70%", // 70% width on large devices
              xl: "50%", // 60% width on extra-large devices
            },
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            {codes.map((code, index) => (
              <Grid item xs={12 / codeLength} key={`pin-box-${index}`}>
                <PinInputField
                  value={code}
                  handleChange={handleChange}
                  handleBackspace={handleBackspace}
                  index={index}
                  autoFocus={index === 0}
                  ref={(el) => (codeInputRefs.current[index] = el)} // Assign the element to the ref array
                />
              </Grid>
            ))}
          </Grid>
        </Container>
        <div style={{ textAlign: "center", marginBottom: theme.spacing(4) }}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            onClick={handleResendClick}
            disabled={resendDisabled || loading}
            sx={{
              width: "fit-content",
              fontSize: !isSm || !isXs ? "1rem" : "inherit",
              background: "#EC674C",
              opacity: 1,
              paddingX: theme.spacing(6),
              paddingy: theme.spacing(1),
              mt: theme.spacing(4),
              textTransform: "none",
              borderRadius: !isSm || !isXs ? "40px" : "30px",
              "&:hover": {
                background: "#EC674C", // Change background color on hover
              },
            }}
          >
            {resendDisabled ? `${countdown} seconds` : t("ConfirmAccountModal.ResendCode")}

          </Button>
        </div>

        {/* If you need a button for resending the code or other actions, add it here */}
      </Box>
    </Modal>
  );
};

export default ConfirmAccountModal;
