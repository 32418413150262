import React from 'react';
import { Button, Typography, Box, Container, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from 'react-router-dom'; // If you're using react-router
import useBreakpoints from '../../hooks/useBreakPoints';

interface PaymentStatusPageProps {
  status: 'successful' | 'unsuccessful'
}

const PaymentStatusPage = ({ status }: PaymentStatusPageProps) => {
  
  const theme = useTheme();
  const { isXs, isSm } = useBreakpoints();
  const navigate = useNavigate();

  const isMdUp = !isSm && !isXs;
  const isSuccess = status === 'successful';

  const handleNavigate = () => {
    if (isSuccess) {
      navigate('/programs');
    } else {
      navigate('/subscription/plans'); 
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '2rem' }}>
      {isSuccess ? (
        <CheckCircleIcon sx={{ fontSize: 60, color: 'green', marginBottom: '1rem' }} />
      ) : (
        <ErrorIcon sx={{ fontSize: 60, color: 'red', marginBottom: '1rem' }} />
      )}

      <Box sx={{ marginBottom: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          {isSuccess ? 'Payment Successful!' : 'Payment Unsuccessful'}
        </Typography>
        <Typography variant="body1">
          {isSuccess
            ? 'Thank you for your payment. You can now proceed to select your program.'
            : 'Your payment did not go through. Please try again or contact support for more information.'}
        </Typography>
      </Box>

      <Button
        variant="contained"
        color={isSuccess ? 'primary' : 'secondary'}
        onClick={handleNavigate}
        sx={{
          width: "fit-content",
          fontSize: isMdUp ? "1rem" : "inherit",
          background: "#EC674C",
          opacity: 1,
          paddingX: theme.spacing(4),
          paddingy: theme.spacing(1),
          mt: theme.spacing(4),
          textTransform: "none",
          borderRadius: isMdUp ? "40px" : "30px",
          "&:hover": {
            background: "#EC674C", // Change background color on hover
          },
        }}
      >
        {isSuccess ? 'Go to Program Selection' : 'View Plans'}
      </Button>
    </Container>
  );
};

export default PaymentStatusPage;
