import React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

interface ErrorViewProps {
  message: string;
  type: 'error' | 'info';
}

const ErrorView: React.FC<ErrorViewProps> = ({ message, type }) => {
  
  const getIcon = () => {
    switch (type) {
      case 'error':
        return <ErrorIcon color="error" fontSize='large'/>;
      case 'info':
        return <InfoIcon color="primary" fontSize='large'/>;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "47%",
        left: "47%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box mb={2}>
        {getIcon()}
      </Box>
      <Typography variant="h6">{message}</Typography>
    </Box>
  );
};

export default ErrorView;
