import React from "react";
import {
  Box,
  Theme,
  Chip,
  // useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Video } from "../../../../types";
import { formatVideoLength } from "../../../../utils/formatVideoLength";

const useStyles = makeStyles<Theme>((theme) => ({
  heroContainer: {
    backdropFilter: "blur(0px)",
    WebkitBackdropFilter: "blur(0px)", // for Safari
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #FFFFFF 100%)`, // Updated gradient
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: -1,
    },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(6),
      // paddingBottom: "5%",
    },
    [theme.breakpoints.up("sm")]: {
      // paddingBottom: "5%",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
      paddingTop: theme.spacing(15),
      // paddingBottom: "5%",
    },
  },
  heading: {
    textAlign: "left",
    color: "white",
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    letterSpacing: "0px",
    opacity: 1,
    fontFamily: "Roboto",
    marginTop: 0,
    fontSize: "3rem"
  },
  chip: {
    "& .MuiChip-label": {
      color: "white",
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
      letterSpacing: "0px",
      lineHeight: "50px",
      fontFamily: "Roboto",
    },
  },
  chipContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  description: {
    textAlign: "left",
    color: "white",
    fontSize: "1.4rem",
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    letterSpacing: "0px",
    lineHeight: "35px",
    opacity: 1,
    [theme.breakpoints.up("xs")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "60%",
    },
  },
}));

export type HeroDetails = {
  heading: string;
  filmLength: string;
  filmYear: string;
  filmCategory: string;
  filmDescription: string;
  image: string;
};

interface HeroProps {
  heroDetails?: Video;
  children?: JSX.Element;
}

const Hero: React.FC<HeroProps> = ({ heroDetails, children }) => {
  // const theme = useTheme();
  const classes = useStyles();

  return (
    <Box
      className={classes.heroContainer}
      style={{
        backgroundImage: `url(${heroDetails?.thumbnails?.[0]?.url || ""})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      id="categories-hero"
    >
      <h1 className={classes.heading}>{heroDetails?.title || ""}</h1>
      <div className={classes.chipContainer}>
        <Chip
          label={`${heroDetails?.created_at.slice(0, 4) || ""}`}
          className={classes.chip}
          sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
        />
        {heroDetails?.category && heroDetails?.category.length
          ? heroDetails?.category.map((category, index) => (
              <Chip
                key={index}
                label={`${category}`}
                className={classes.chip}
                sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
              />
            ))
          : ""}
        {heroDetails?.medias && heroDetails.medias.length ? (
          heroDetails.medias[0].length > 0 ? (
            <Chip
              label={formatVideoLength(heroDetails.medias[0].length)}
              className={classes.chip}
              sx={{ backgroundColor: "#EC674C40", borderRadius: 2 }}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
      <p className={classes.description}>{heroDetails?.description || ""}</p>
      <div id="new-releases">{children}</div>
    </Box>
  );
};

export default Hero;
