import React, { createContext, useContext } from "react";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { ClassNameMap, Theme } from "@mui/material";

/**
 * @description Creates JSS styles for different alert types.
 * @param {Theme} theme - The MUI theme object for theming styles.
 */
export const useStyles = makeStyles((theme: Theme) => ({
  success: { backgroundColor: theme.palette.success.main },
  error: { backgroundColor: theme.palette.error.main },
  warning: { backgroundColor: theme.palette.warning.main },
  info: { backgroundColor: theme.palette.info.main },
}));

/**
 * @description Interface for alert context, defining various alert types.
 */
interface IAlertContext {
  success: (message: string) => void;
  error: (message: string) => void;
  warning: (message: string) => void;
  info: (message: string) => void;
}

/**
 * @description Props interface for AlertProvider component.
 * @typedef {Object} AlertProviderProps
 * @property {React.ReactNode} children - Child components that will have access to the alert context.
 */
interface AlertProviderProps {
  children: React.ReactNode;
}

/**
 * @description Context for handling alerts throughout the application.
 */
const AlertContext = createContext<IAlertContext>({
  success: () => {},
  error: () => {},
  warning: () => {},
  info: () => {},
});

/**
 * @description Custom hook to access the alert context.
 * @returns {IAlertContext} - The alert context object with alert functions.
 */
export const useAlert = (): IAlertContext => useContext(AlertContext);

/**
 * @description AlertProvider component to provide alert functionality to its children.
 * @param {AlertProviderProps} props - Props for AlertProvider.
 * @returns {React.ReactElement} - Rendered AlertProvider component.
 */
export const AlertProvider = ({ children }: AlertProviderProps): React.ReactElement => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      // SnackbarProvider from notistack is used to manage the alert notifications.
      // See: https://iamhosseindhv.com/notistack
    >
      <InnerAlertProvider>{children}</InnerAlertProvider>
    </SnackbarProvider>
  );
};

/**
 * @description InnerAlertProvider component for providing context to its children.
 * @param {AlertProviderProps} props - Props for InnerAlertProvider.
 * @returns {React.ReactElement} - Rendered InnerAlertProvider component.
 */
const InnerAlertProvider = ({ children }: AlertProviderProps): React.ReactElement => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * @description Shows an alert with the specified type and message.
   * @param {VariantType} type - Type of the alert (success, error, warning, info).
   * @param {string} message - Message to display in the alert.
   */
  const showAlert = (type: VariantType, message: string) => {
    enqueueSnackbar(message, {
      variant: type,
      className: determineClass(type, classes),
    });
  };

  const value: IAlertContext = {
    success: (message) => showAlert("success", message),
    error: (message) => showAlert("error", message),
    warning: (message) => showAlert("warning", message),
    info: (message) => showAlert("info", message),
  };

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

/**
 * @description Determines the CSS class based on the alert type.
 * @param {VariantType} type - Type of the alert.
 * @param {ClassNameMap} classes - Style classes.
 * @returns {string} - The CSS class for the alert type.
 */
const determineClass = (
  type: VariantType,
  classes: ClassNameMap<"error" | "success" | "warning" | "info">
): string => {
  switch (type) {
    case "success":
      return classes.success;
    case 'error':
        return classes.error;
    case 'warning':
        return classes.warning;
    case 'info':
        return classes.info;
    default:
      return classes.success
  }
};
