import React from "react";

interface ProgressBarProps {
  currentTime: number;
  duration: number;
  onProgressChange: (value: number) => void;
  onMouseDown: () => void;
  onMouseUp: () => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentTime,
  duration,
  onProgressChange,
  onMouseDown,
  onMouseUp,
}) => {
  const progressPercentage = (currentTime / duration) * 100;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onProgressChange(Number(e.target.value));
  };

  return (
    <input
      id="player-bar"
      type="range"
      min="0"
      max="100"
      value={progressPercentage}
      onChange={handleChange}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      style={{ width: "100%", cursor: "pointer" }}
    />
  );
};

export default ProgressBar;
