import React, { useEffect } from "react";
import { Box, Typography, IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setFreeTrialBannerDismissed } from "../../redux/state/userSlice";

const FreeTrialBanner: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.user);
  const { free_trial, free_trial_end_date, free_trial_banner_dismissed } = user;

  useEffect(() => {
    // If free trial has ended, ensure banner is dismissed
    if (free_trial_end_date) {
      const currentDate = new Date();
      const freeTrialEndDate = new Date(free_trial_end_date);
      if (currentDate >= freeTrialEndDate && !free_trial_banner_dismissed) {
        dispatch(setFreeTrialBannerDismissed(true));
      }
    }
  }, [free_trial_end_date, free_trial_banner_dismissed, dispatch]);

  const handleCloseBanner = () => {
    dispatch(setFreeTrialBannerDismissed(true));
  };

  if (
    !free_trial ||
    free_trial_banner_dismissed ||
    !free_trial_end_date ||
    new Date() >= new Date(free_trial_end_date)
  ) {
    return null;
  }

  // Format the free trial end date
  const formattedDate = new Date(free_trial_end_date).toLocaleDateString(
    undefined,
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1),
        zIndex: 1000,
      }}
    >
      <Typography
        variant="body1"
        sx={{ flexGrow: 1, textAlign: "center", marginRight: theme.spacing(4) }}
      >
        You are currently on a free trial that ends on {formattedDate}. Your paid
        plan will automatically start at the end of the free period.
      </Typography>
      <IconButton
        color="inherit"
        onClick={handleCloseBanner}
        sx={{ position: "absolute", right: theme.spacing(1) }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default FreeTrialBanner;
