import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function useIsFireTv() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));

  return matches;
}

export default useIsFireTv

