import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Program } from "../../types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

interface ProgramState {
  programs: Program[];
  currentProgram: Program | null;
  loading: boolean; // State for tracking loading status
  error: string | null; // State for tracking errors
}

const initialState: ProgramState = {
  programs: [],
  currentProgram: null,
  loading: false,
  error: null,
};

const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    setCurrentProgram: (state, action: PayloadAction<Program | null>) => {
      state.currentProgram = action.payload;
    },
    setPrograms: (state, action: PayloadAction<Program[]>) => {
      state.programs = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setCurrentProgram,
  setPrograms,
  setLoading,
  setError,
} = programSlice.actions;
// export default programSlice.reducer;


// Define the persist config specifically for the program slice
const programPersistConfig = {
  key: 'program',
  storage: storage,
  whitelist: ['currentProgram']  // only currentProgram will be persisted
};

// Apply persistReducer with the config to programSlice.reducer
const persistedProgramReducer = persistReducer(programPersistConfig, programSlice.reducer);
export default persistedProgramReducer;
