export const PROFILE_PROTECTED_ROUTES = [
  "/browse",
  "/browse/notifications",
  "/browse/settings",
  "/browse/playlists",
  "/browse/categories",
  "/browse/home",
  "/browse/search",
  "/browse/stream/",
];

export const languages: { code: "en" | "fr" | "es"; name: string }[] = [
  { code: "en", name: "English" },
  { code: "fr", name: "French" },
  { code: "es", name: "Spanish" },
  // Add other languages here
];

export const videoResolutions = [
  { name: "720p", code: "720p" },
  { name: "1080p", code: "1080p" },
  { name: "4K", code: "4K" },
  // add more resolutions as needed
];

export const termsAndConditions: { heading: string; subheading: string }[] = [
  {
    heading: "General Terms",
    subheading:
      "Welcome to Our Video Streaming Service. By accessing our service, you agree to be bound by these Terms and Conditions. Please read them carefully.",
  },
  {
    heading: "Account Usage",
    subheading:
      "Your account on our platform is personal to you and should not be shared with others. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.",
  },
  {
    heading: "Content Usage",
    subheading:
      "The content provided on our service is for your personal and non-commercial use only. You agree not to use the service for public performances.",
  },
  {
    heading: "Privacy Policy",
    subheading:
      "We are committed to protecting your privacy and data. We collect and use your data in accordance with our Privacy Policy, which explains how we collect, use, and disclose information that pertains to your privacy.",
  },
  {
    heading: "Changes to Terms",
    subheading:
      "We reserve the right to change these terms at any time. Your continued use of the service following any changes means that you accept the new Terms and Conditions.",
  },
  {
    heading: "Contact Us",
    subheading:
      "If you have any questions about these Terms, please contact us at support@takingflight.com.",
  },
];
