import React, { useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  Chip,
  Box,
  Theme,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import useBreakpoints from "../../hooks/useBreakPoints";
import VideoPreviewModal from "../VideoPreviewModal";
import { Video } from "../../types";
import { useGetVideoByIdQuery } from "../../redux/api/videoApi";
import { formatVideoLength } from "../../utils/formatVideoLength";
// import ThumbnailSkeleton from "../Skeletons/ThumbnailSkeleton";

/**
 * @description Creates JSS styles for the Thumbnail component.
 * @param {Theme} theme - The MUI theme object for theming styles.
 */
const useStyles = makeStyles((theme: Theme) => ({
  // Style for the card container
  card: {
    position: "relative",
    backdropFilter: "blur(0px)",
    WebkitBackdropFilter: "blur(0px)",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background:
        "transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 0,
    },
    transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition for transform and shadow
    "&:hover": {
      outline: "4px solid #EC674C",
      outlineOffset: "-2px",
      cursor: "pointer",
    },
  },
  // Style for the media/image part of the card
  media: {
    height: "100%",
  },
  // Style for the container holding the information
  infoContainer: {
    position: "absolute",
    bottom: 5,
    left: "5%",
    width: "90%",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden",
    gap: theme.spacing(1),
  },
  // Style for the category chip
  categoryChip: {
    "& .MuiChip-label": {
      color: "#FFFFFF",
      lineHeight: "50px",
      fontFamily: "Roboto",
      letterSpacing: "0px",
    },
  },
  // Style for the length chip
  lengthChip: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    "& .MuiChip-label": {
      color: "#FFFFFF",
      lineHeight: "50px",
      fontFamily: "Roboto",
      letterSpacing: "0px",
    },
  },
  // Style for the title text
  title: {
    color: "white",
    marginBottom: theme.spacing(1),
    fontWeight: "normal",
  },
}));

/**
 * @description Interface for Thumbnail component props.
 * @property {string} id - The unique identifier for the film.
 * @property {string} filmName - The name of the film.
 * @property {string} [category] - The category of the film (optional).
 * @property {string} image - The URL of the film's image.
 * @property {string} filmLength - The length of the film.
 */
export interface ThumbnailProps {
  videoId: string;
  video?: Video;
  onDelete?: (videoId: string) => void; // Optional deletion callback
  length?: number;
}

/**
 * @description Thumbnail component for displaying film details in a card format.
 * @param {ThumbnailProps} props - Props for the Thumbnail component.
 * @returns {React.ReactElement} - Rendered component.
 */
export default function Thumbnail({
  videoId,
  video,
  onDelete,
}: ThumbnailProps): React.ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const { isSm, isMd, isLg, isXl } = useBreakpoints();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [vid, setVid] = useState<Video>();

  // Calculates responsive sizing for the card based on breakpoints
  const cardHeight = isXl ? 302 : isLg ? 252 : isMd ? 252 : isSm ? 175 : 175;
  const cardWidth = isXl ? 325 : isLg ? 275 : isMd ? 275 : isSm ? 225 : 225;

  // Fetch video data if 'video' prop is not provided
  const { data: fetchedVideo } = useGetVideoByIdQuery(videoId, {
    skip: video ? true : false,
  });

  const handleRemoveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // This will stop the event from bubbling up
    if (onDelete) {
      onDelete(videoId);
    }
  };

  const handleClick = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };


  useEffect(() => {
    if (fetchedVideo && fetchedVideo.data) {
      setVid(fetchedVideo.data);
    } else if (video) {
      setVid(video);
    }
  }, [fetchedVideo, fetchedVideo?.data, video]);

  const renderChips = () => {
    const chipsToRender = [];

    for (let i = 0; i < vid.category.length; i++) {
      chipsToRender.push(
        <Chip
          key={i}
          label={vid.category[i]}
          className={classes.categoryChip}
          sx={{
            backgroundColor: "#EC674C40",
            borderRadius: 2,
            mr: theme.spacing(1),
          }}
        />
      );

      if (i === 1) {
        break;
      }
    }

    return chipsToRender;
  };

  if (!vid) {
    return <></>;
  }

  return (
    <>
      <Card
        className={classes.card}
        sx={{ width: cardWidth, height: cardHeight, borderRadius: "10px" }}
        onClick={handleClick}
      >
        {/* CardMedia component for displaying the film image */}
        <CardMedia
          className={classes.media}
          image={vid?.thumbnails?.[0]?.url || "/images/thumbnail-placeholder.jpg"}
          title={vid?.title}
        />
        {onDelete && (
          <Tooltip title={"Remove Video"}>
            <IconButton
              onClick={handleRemoveClick}
              style={{ position: "absolute", top: 0, left: 0, zIndex: 9 }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}

        {vid?.medias && vid?.medias?.length ? (
          vid?.medias[0].length ? (
            <Chip
              label={formatVideoLength(vid?.medias[0].length)}
              className={classes.categoryChip}
              sx={{
                backgroundColor: "#EC674C40",
                borderRadius: 2,
                position: "absolute",
                top: 5,
                right: 5,
                zIndex: 9,
              }}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {/* Box container for additional film information */}
        <Box className={classes.infoContainer}>
          <div className={classes.chipContainer}>{renderChips()}</div>

          {/* Typography component for displaying the film title */}
          <h2 className={classes.title}>{vid?.title}</h2>
        </Box>
      </Card>
      <VideoPreviewModal
        open={isPreviewOpen}
        onClose={handleClosePreview}
        video={video || vid}
      />
    </>
  );
}
