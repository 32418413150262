import React from "react";
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  Divider,
  Typography,
  ListItemAvatar,
  Avatar,
  Switch,
} from "@mui/material";
import { SkipPrevious, SkipNext, Shuffle, ShuffleOn } from "@mui/icons-material";

import { Video } from "../../types";
import { formatVideoLength } from "../../utils/formatVideoLength";

interface PlaylistPopoverProps {
  videos: Video[];
  currentVideoIndex: number;
  anchorEl: HTMLElement | null;
  isShuffled: boolean;
  autoplayEnabled: boolean; // New autoplay state
  onClose: () => void;
  onSelectVideo: (index: number) => void;
  onNext: () => void;
  onPrevious: () => void;
  onShuffle: () => void;
  onToggleAutoplay: () => void; // New autoplay toggle handler
}

const PlaylistPopover: React.FC<PlaylistPopoverProps> = ({
  videos,
  currentVideoIndex,
  anchorEl,
  isShuffled,
  autoplayEnabled,
  onClose,
  onSelectVideo,
  onNext,
  onPrevious,
  onShuffle,
  onToggleAutoplay
}) => {
  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      PaperProps={{
        style: { height: "70vh", width: "300px", overflowY: "auto" }, // Set max height and enable scroll
        sx: {
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar in WebKit-based browsers
          },
        },
      }}
    >
      {/* Controls at the top */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={1}
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 1, // Ensures the controls stay on top of list items
        }}
      >
        <IconButton onClick={onPrevious}>
          <SkipPrevious />
        </IconButton>
        <IconButton onClick={onShuffle}>
          {isShuffled ? <ShuffleOn /> : <Shuffle /> }
        </IconButton>
        <IconButton onClick={onNext}>
          <SkipNext />
        </IconButton>
         {/* Autoplay Toggle */}
         <Box display="flex" alignItems="center">
          <Typography variant="body2">Autoplay</Typography>
          <Switch
            checked={autoplayEnabled}
            onChange={onToggleAutoplay}
            color="primary"
          />
        </Box>
      </Box>
      <Divider />
      {/* Video list */}
      <List>
        {videos.map((video, index) => (
          <ListItem
            key={video?.id}
            button
            onClick={() => onSelectVideo(index)}
            selected={index === currentVideoIndex}
            style={{
              backgroundColor:
                index === currentVideoIndex ? "#e0f7fa" : "transparent",
            }}
          >
            <ListItemAvatar>
              <Avatar
                src={video?.thumbnails?.[0]?.url}
                alt={video?.title}
                variant="square"
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "bold",
                    color: index === currentVideoIndex ? "blue" : "black",
                  }}
                >
                  {video?.title}
                </Typography>
              }
              secondary={
                <Box display="flex" flexDirection="column">
                  <Typography variant="body2" color="textSecondary">
                    {video?.category?.join(", ")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatVideoLength(video?.medias?.[0]?.length)}
                  </Typography>
                </Box>
              }
              style={{ marginLeft: "8px" }}
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default PlaylistPopover;
