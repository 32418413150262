import React from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  useTheme,
  Tooltip,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import useBreakpoints from "../../hooks/useBreakPoints";

type NavItemConfig = {
  path: string;
  info: string;
  title: string;
  icon: (badgeContent?: number) => JSX.Element;
  children?: NavItemConfig[]; // Optional for nested items/dropdowns
};

const NavItem: React.FC<{
  item: NavItemConfig;
  isExpanded: boolean;
  badgeContent?: number;
}> = ({ item, isExpanded, badgeContent }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === item.path;
  const theme = useTheme();
  const { isXs, isSm, isMd, isLg, isXl } = useBreakpoints();

  const iconWidth = isXl ? 37 : isLg ? 30 : isMd ? 25 : "auto";
  // const iconHeight = isXl ? 37 : isLg ? 30 : isMd ? 25 : "auto";

  const handleClick = () => {
    if (item.children && item.children.length > 0) {
      setOpen(!open);
    } else {
      navigate(item.path);
    }
  };

  return (
    <>
      <Tooltip title={item.title} placement="right">
        <ListItemButton
          onClick={handleClick}
          sx={{
            backgroundColor:
              isExpanded && isActive
                ? "#EC674C80"
                : !isExpanded && isActive
                ? "#EC674C"
                : "transparent",
            // "&:hover": {
            //   backgroundColor: "#EC674C", // Apply the color on hover
            // },
            "& .MuiListItemIcon-root": {
              color:
                !isExpanded && !isActive
                  ? "#a3a3a3"
                  : !isExpanded && isActive
                  ? "#FFFFFF"
                  : isExpanded && isActive
                  ? "#FFFFFF"
                  : "#121212",
            },
            "& .MuiListItemText-primary": {
              color:
                isExpanded && isActive
                  ? "#FFFFFF"
                  : isExpanded && !isActive
                  ? "#121212"
                  : "",
            },
            marginBottom: isXs || isSm ? theme.spacing(0.5) : theme.spacing(2),
            borderRadius: theme.spacing(1),
            justifyContent: isExpanded ? "initial" : "center", // Center the icon when not expanded
          }}
        >
          <ListItemIcon sx={{ minWidth: iconWidth, fontSize: iconWidth }}>
            {item.icon(badgeContent)}
          </ListItemIcon>
          {isExpanded && (
            <ListItemText primary={item.title} sx={{ ml: theme.spacing(1) }} />
          )}
          {item.children &&
            (isExpanded ? open ? <ExpandLess /> : <ExpandMore /> : null)}
        </ListItemButton>
      </Tooltip>
      {item.children && (
        <Collapse in={isExpanded && open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((childItem) => (
              // Recursively render child items
              <NavItem
                key={childItem.path}
                item={childItem}
                isExpanded={isExpanded}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default NavItem;
