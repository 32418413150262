import React from "react";
import { Container, Typography, Paper, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { termsAndConditions } from "../../constants/constants";

const useStyles = makeStyles(() => ({
  title: {
    color: "#121212",
    fontWeight: 700,
  },

  subheadings: {
    color: "#121212",
    fontWeight: 550,
  },
}));

const TermsAndConditionsPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Container maxWidth="xl">
      <Paper
        elevation={3}
        sx={{ paddingY: theme.spacing(5), paddingLeft: theme.spacing(2), marginY: theme.spacing(3) }}
      >
        <Typography variant="h4" gutterBottom className={classes.title}>
          Terms and Conditions
        </Typography>
      </Paper>
      {termsAndConditions.map((term, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`term-${index + 1}`}
          >
            <Typography
              variant="h6"
              gutterBottom
              className={classes.subheadings}
            >
              {term.heading}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{term.subheading}</AccordionDetails>
        </Accordion>
      ))}
      <Typography
        variant="body2"
        color={"#6E6E73"}
        width={"100%"}
        my={theme.spacing(4)}
        textAlign={"center"}
        sx={{
          opacity: 0.5,
          fontSize: ["1rem", null, null, "1rem"],
        }}
      >
        Licensed Professional Clinical Counseling Corporation
      </Typography>
    </Container>
  );
};

export default TermsAndConditionsPage;
