import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#EC674C',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#2196f3',
    },
    background: {
      default: '#FFFFFF', // Light theme background
      paper: '#ffffff',
    },
    text: {
      primary: '#212121', // Primary text color for light theme
      secondary: '#757575',
    },
  },
  // Add other theme customizations here
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2196F3',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#2196f3',
    },
    background: {
      default: '#121212', // Dark theme background
      paper: '#424242',
    },
    text: {
      primary: '#ffffff', // Primary text color for dark theme
      secondary: '#bdbdbd',
    },
  },
  // Add other theme customizations here
});
