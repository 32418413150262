import React from "react";
import Playlist from "./components/Playlist";
import { Theme, Skeleton, useTheme, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useGetMyPlaylistsQuery } from "../../redux/api/playlistApi"; // Import the hook
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ErrorView from "../../components/ErrorView";
import { useTranslation } from "react-i18next";
import PlaylistSkeleton from "../../components/Skeletons/PlaylistSkeleton";

const useStyles = makeStyles<Theme>((theme) => ({
  heading: {
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
    marginBottom: 2,
    color: "#121212",
  },
  container: {
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
}));

const PlaylistPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  // const [page, setPage] = useState(1); // Current page number
  // const [pageSize, setPageSize] = useState(10); // Number of items per page
  // const [sort, setSort] = useState("asc"); // Sorting order
  // const [sortField, setSortField] = useState("created_at"); // Field to sort by
  const { currentProgram } = useSelector((state: RootState) => state.program);
  const { t } = useTranslation();

  // const testLoading = true;
  // Use the hook with query parameters
  const {
    data: playlistResponse,
    isLoading,
    isError,
  } = useGetMyPlaylistsQuery({
    page_size: 10,
    page_number: 1,
    sort: "asc",
    field: "created_at",
    program_id: currentProgram?.id,
  });

  if (isLoading) {
    return (
      <Box
        sx={{
          paddingLeft: theme.spacing(2),
          [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(15),
          },
        }}
      >
        <div >
          <Skeleton
            variant="text"
            width={200}
            height={40}
            sx={{
              marginTop: theme.spacing(5),
              marginBottom: 2,
            }}
          />
        </div>

        <PlaylistSkeleton />
        <PlaylistSkeleton />
        <PlaylistSkeleton />
      </Box>
    );
  }

  if (isError) {
    return <ErrorView message={t("PlaylistPage.FetchError")} type="error" />;
  }

  return (
    <div>
      <h1 className={classes.heading}>{t("PlaylistPage.Title")}</h1>
      {playlistResponse &&
      playlistResponse.data &&
      playlistResponse.data.length ? (
        playlistResponse.data.map((playlist, index) => (
          <Playlist key={index} playlist={playlist} />
        ))
      ) : (
        <ErrorView message={t("PlaylistPage.NoNotifications")} type="info" />
      )}
    </div>
  );
};

export default PlaylistPage;
