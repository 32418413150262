import React, { useEffect, useState } from "react";
import useBreakpoints from "../../../../hooks/useBreakPoints";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { makeStyles } from "@mui/styles";
import { Theme, useTheme } from "@mui/material";
import Thumbnail from "../../../../components/Thumbnail";
import { Category, Video } from "../../../../types";
import { useGetAllVideosQuery } from "../../../../redux/api/videoApi";
// import SliderWrapper from "../../../../components/SliderWrapper";
import CategoryListSkeleton from "../../../../components/Skeletons/CategoryListSkeleton";
import { useAlert } from "../../../../contexts/AlertContext";
import SwiperScroll from "../../../../components/SwiperScroll";
import { SwiperSlide } from "swiper/react";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(5),
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
  },
  message: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

interface CategoryListProps {
  videos?: Video[];
  category: Category;
  sectionId: string;
  isChild?: boolean;
  onVideosFetched?: (name: string) => void;
}

const CategoryList = ({
  videos,
  category,
  sectionId,
  isChild,
  onVideosFetched,
}: CategoryListProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [fetchedVideos, setFetchedVideos] = useState<Video[]>([]);
  const [page, setPage] = useState(1);
  const { isXs, isSm } = useBreakpoints();
  const { error } = useAlert();
  const { name } = category;
  const [shouldLoadMore, setShouldLoadMore] = useState(true);

  const {
    data: categoryVideos,
    isError,
    isLoading,
  } = useGetAllVideosQuery({
    page_size: 10,
    page_number: page,
    sort: "asc",
    field: "created_at",
    category: name,
    published: true,
  });

  const formatCateoryName = (name: string) => {
    return name.toLowerCase().replace(/ /g, "-");
  };

  useEffect(() => {
    if (videos) {
      setFetchedVideos(videos);
    } else if (categoryVideos) {
      // Append the new data to the existing fetchedVideos array
      setFetchedVideos((prevVideos) => [
        ...prevVideos,
        ...categoryVideos.data.filter(
          (newVideo) => !prevVideos.some((video) => video._id === newVideo._id)
        ),
      ]);

      // Check if we should load more
      if (categoryVideos.count === fetchedVideos.length) {
        setShouldLoadMore(false);
      }
    }
  }, [videos, categoryVideos]);

  useEffect(() => {
    if (onVideosFetched && videos) {
      onVideosFetched(formatCateoryName("new-releases")); // Notify parent component
    }
    if (onVideosFetched && categoryVideos && categoryVideos.data.length > 0) {
      onVideosFetched(formatCateoryName(category.name)); // Notify parent component
    }
  }, [categoryVideos, onVideosFetched, category.name, videos]);

  const loadMoreVideos = () => {
    if (shouldLoadMore && categoryVideos && categoryVideos.data.length > 0) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  if (isError) {
    error(`Failed to load Videos for ${name} category`);
    return null;
  }

  if (isLoading) {
    return <CategoryListSkeleton isChild={isChild} />;
  }

  if (fetchedVideos.length === 0) {
    return null; // or render nothing or any placeholder you might want
  }

  return (
    <div
      className={classes.container}
      id={sectionId}
      style={{
        paddingLeft: isChild
          ? theme.spacing(0)
          : isXs || isSm
          ? theme.spacing(2)
          : theme.spacing(15),
      }}
    >
      <h2
        style={{
          marginBottom: 2,
          color: !videos ? "#121212" : "white",
          fontWeight: "normal",
        }}
      >
        {videos ? "New Releases" : name}
      </h2>
      <SwiperScroll onReachEnd={shouldLoadMore ? loadMoreVideos : undefined}>
        {fetchedVideos.map((video) => (
          <SwiperSlide key={video._id} style={{ width: "auto" }}>
            <Thumbnail video={video} videoId={video._id} />
          </SwiperSlide>
        ))}
      </SwiperScroll>
    </div>
  );
};

export default CategoryList;
