import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

// Utility function to detect mobile devices
const isMobileDevice = () => {
  return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|BlackBerry/i.test(
    navigator.userAgent
  );
};

// Custom hook to determine if the current device is mobile
export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Update the state based on user agent detection and screen size
    setIsMobile(isMobileDevice() && isSmallScreen);
  }, [isSmallScreen]);

  return isMobile;
};
