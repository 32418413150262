interface Media {
  _id: string;
  url: string;
  type: string;
  resolution: string; // Assuming this should be a string rather than empty quotes
  length: number;

}

export function selectMedia(
  medias: Media[],
  forDownload: boolean = false
): Media | undefined {
  if (medias.length === 0) {
    return undefined;
  }

  let preferredMedia: Media | undefined;

  // If download is preferred, look for 'mp4' type first
  if (forDownload) {
    preferredMedia = medias.find((media) => media.type === "mp4");
    if (preferredMedia) return preferredMedia;
  }

  // Look for 'm3u8' type for streaming purposes
  preferredMedia = medias.find((media) => media.type === "m3u8");

  // If no 'm3u8' and not looking specifically for downloadable content, or no 'mp4' was found when downloading was preferred
  return preferredMedia || medias[0];
}

