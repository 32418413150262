import React, { useRef, useState } from "react";
import bigNaassom from "../../assets/big-naassom.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Theme,
  useTheme,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import logo from "../../assets/TFLogo1.png";


const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#EC674C",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#707070",
      opacity: 0.5,
    },
    "&:hover fieldset": {
      borderColor: "#707070",
      opacity: 0.5,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EC674C",
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      paddingTop: "4",
      paddingBottom: "4",
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    display: "grid",
    gridTemplateColumns: "1fr", // Single column by default
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr", // Two columns on medium screens and up
    },
  },
  logo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between("xs", "md")]: {
      width: 150,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto", // Enable scrolling for left column
    scrollbarWidth: "none", // For Firefox
    msOverflowStyle: "none", // For Internet Explorer 10+
    "&::-webkit-scrollbar": {
      display: "none", // For Chrome, Safari, and Opera
    },
  },
  greeting: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
      marginTop: theme.spacing(4),
    },
  },
  heading: {
    textAlign: "left",
    width: "100%",
    color: "#121212",
    margin: 0,
    fontFamily: "Avenir Next LT Pro",
    fontWeight: "bold",
    letterSpacing: 0,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },
  subheading: {
    textAlign: "left",
    width: "100%",
    marginBottom: theme.spacing(4),
    color: "#6E6E73",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },
  form: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.between("xs", "md")]: {
      width: "90%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "80%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "75%",
    },
  },
  rightColumn: {
    display: "none", // Hide the right column by default
    [theme.breakpoints.up("md")]: {
      display: "block", // Show the right column on medium screens and up
      backgroundImage: `url(${bigNaassom})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100%",
    },
  },
}));

const VerifyAccountPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const PIN_LENGTH = 5;

  const [pin, setPin] = useState(Array(PIN_LENGTH).fill(""));
  const pinInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handlePinChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newPin = [...pin];
    newPin[index] = event.target.value;
    setPin(newPin);

    // Focus next pin input after current input is filled
    if (index < PIN_LENGTH - 1 && event.target.value) {
      pinInputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index: number) => {
    if (index > 0) {
      const newPins = [...pin];
      newPins[index - 1] = "";
      setPin(newPins);
      pinInputRefs.current[index - 1]?.focus();
    }
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const pinValue = pin.join("");
    console.log("PIN entered:", pinValue);

    // Add logic to verify the PIN here before navigating
    // ...
    navigate("/auth/change-password");
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        {/** Logo  */}
        <div style={{ width: "100%" }}>
          <img
            src={logo}
            alt="Logo"
            className={classes.logo}
          />
        </div>

        {/** Greeting */}
        <div className={classes.greeting}>
          <Typography
            variant="body2"
            sx={{
              fontSize: ["1rem", null, null, "1rem"],
              mb: theme.spacing(4),
            }}
            width={"100%"}
          >
            Have an account?{" "}
            <Link to="/auth/login" style={{ color: "#EC674C" }}>
              Login here
            </Link>
          </Typography>
          <h1 className={classes.heading}>Verify Your Account</h1>
          <p className={classes.subheading}>
            We just sent you an email with a verification code to confirm your
            account.
          </p>
          <p className={classes.subheading}>Enter the code below</p>
        </div>

        <form onSubmit={handleFormSubmit} className={classes.form}>
          <Grid container spacing={1} justifyContent="center">
            {pin.map((value, index) => (
              <Grid item key={index} xs={isMdUp ? 1.2 : 1.5}>
                <StyledTextField
                  variant="outlined"
                  value={value}
                  onChange={(event) => handlePinChange(event, index)}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center" },
                  }}
                  onKeyUp={(e: React.KeyboardEvent) => {
                    if (e.key === "Backspace" && !value) {
                      handleBackspace(index);
                    }
                  }}
                  autoFocus={index === 0}
                  inputRef={(el) => (pinInputRefs.current[index] = el)}
                />
              </Grid>
            ))}
          </Grid>

          <div style={{ textAlign: "center", marginBottom: theme.spacing(2) }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              sx={{
                width: "fit-content",
                fontSize: isMdUp ? "1rem" : "inherit",
                background: "#EC674C",
                opacity: 1,
                paddingX: theme.spacing(6),
                paddingy: theme.spacing(1),
                mt: theme.spacing(4),
                textTransform: "none",
                borderRadius: isMdUp ? "40px" : "30px",
                "&:hover": {
                  background: "#EC674C", // Change background color on hover
                },
              }}
            >
              Verify
            </Button>
          </div>
        </form>

        <div className={classes.footer}>
          <Typography
            variant="body2"
            color={"#6E6E73"}
            width={"100%"}
            textAlign={"center"}
            sx={{
              opacity: 0.5,
              fontSize: ["1rem", null, null, "1rem"],
            }}
          >
            Licensed Professional Clinical Counseling Corporation
          </Typography>
        </div>
      </div>
      <div className={classes.rightColumn}></div>
    </div>
  );
};

export default VerifyAccountPage;
