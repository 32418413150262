import React, { useEffect, useState } from "react";
import bigNaassom from "../../assets/big-naassom.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  Theme,
  useTheme,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@mui/styles";
import { changePasswordSchema } from "../../constants/validationSchemas";
import { useFormik } from "formik";
import useIsFireTv from "../../hooks/useIsFireTv";
import StyledTextField from "../../components/Input";
import { useSearchParams } from "react-router-dom";
import { useUpdateCurrentCompanyMutation } from "../../redux/api/companyApi";
import { UpdateCompanyPayload } from "../../types";
import { useAlert } from "../../contexts/AlertContext";
import logo from "../../assets/TFLogo1.png";


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    display: "grid",
    gridTemplateColumns: "1fr", // Single column by default
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr", // Two columns on medium screens and up
    },
  },
  logo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between("xs", "md")]: {
      width: 150,
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 250,
    },
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto", // Enable scrolling for left column
    scrollbarWidth: "none", // For Firefox
    msOverflowStyle: "none", // For Internet Explorer 10+
    "&::-webkit-scrollbar": {
      display: "none", // For Chrome, Safari, and Opera
    },
  },
  greeting: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },
  heading: {
    textAlign: "left",
    width: "100%",
    color: "#121212",
    margin: 0,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.375rem",
    },
  },
  subheading: {
    textAlign: "left",
    width: "100%",
    marginBottom: theme.spacing(4),
    color: "#6E6E73",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
  },
  form: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "80%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(5),
    [theme.breakpoints.between("xs", "md")]: {
      width: "90%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "80%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "75%",
    },
  },
  signUpLink: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(5),
  },
  rightColumn: {
    display: "none", // Hide the right column by default
    [theme.breakpoints.up("md")]: {
      display: "block", // Show the right column on medium screens and up
      backgroundImage: `url(${bigNaassom})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100%",
    },
  },
}));

const ChangePasswordPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfrimPassword] = useState(false);
  const [token, setToken] = useState("");
  const isFireTV = useIsFireTv();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { success, error } = useAlert();

  const [changePassword, { isLoading }] = useUpdateCurrentCompanyMutation();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfrimPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    const tokenParam = searchParams.get("token");
    if (tokenParam) {
      setToken(tokenParam);
      // Here, you can also handle the token (e.g., verify it, fetch user data, etc.)
    }
  }, [searchParams]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      if (token) {
        try {
          const headers = {
            Authorization: `Bearer ${token}`, // Set the authorization header
          };
          const updatedCompany: UpdateCompanyPayload = {
            password: values.password,
          };
          await changePassword({
            data: updatedCompany,
            headers,
          }).unwrap();
          success("Password changed successfully");
          navigate("/auth/login");
        } catch (err) {
          console.log(err);
          error("Could not change password, try again");
        }
      }
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        {/** LOGO */}
        <div style={{ width: "100%" }}>
          <img
            src={logo}
            alt="Logo"
            className={classes.logo}
          />
        </div>

        {/** Greeting */}
        <div className={classes.greeting}>
          <Typography
            variant="body2"
            sx={{
              fontSize: ["1rem", null, null, "1rem"],
              mb: theme.spacing(4),
            }}
            width={"100%"}
          >
            Have an account?{" "}
            <Link to="/auth/login" style={{ color: "#EC674C" }}>
              Login here
            </Link>
          </Typography>
          <h1 className={classes.heading}>Change Password</h1>
          <p className={classes.subheading}>Please enter new password below.</p>
        </div>

        {/** Form */}
        <form onSubmit={handleSubmit} className={classes.form}>
          {/** Password */}
          <FormControl fullWidth sx={{ mb: theme.spacing(2) }}>
            <StyledTextField
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              margin="none"
              placeholder="Enter password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={touched.password && Boolean(errors.password)}
            />
            {touched.password && errors.password && (
              <FormHelperText
                sx={{ color: "red", fontSize: "12px", margin: 0 }}
              >
                {errors.password}
              </FormHelperText>
            )}
          </FormControl>

          {/** Confirm Password */}
          <FormControl fullWidth margin="none">
            <StyledTextField
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              label="Confirm Password"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              margin="none"
              placeholder="Confirm password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <FormHelperText
                sx={{ color: "red", fontSize: "12px", margin: 0 }}
              >
                {errors.confirmPassword}
              </FormHelperText>
            )}
          </FormControl>

          <Grid container className={classes.signUpLink}>
            <Grid item xs={12} textAlign={"center"}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                disabled={isLoading}
                sx={{
                  width: "fit-content",
                  height: isFireTV ? 80 : "100%",
                  fontSize: isFireTV ? 30 : "inherit",
                  background: "#EC674C",
                  opacity: 1,
                  paddingX: theme.spacing(6),
                  paddingy: theme.spacing(1),
                  textTransform: "none",
                  borderRadius: isFireTV ? "40px" : "30px",
                  "&:hover": {
                    background: "#EC674C", // Change background color on hover
                  },
                }}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </form>

        {/** Footer */}
        <div className={classes.footer}>
          <Typography
            variant="body2"
            color={"#6E6E73"}
            width={"100%"}
            mt={2}
            textAlign={"center"}
            sx={{
              opacity: 0.5,
              fontSize: ["1rem", null, null, "1rem"],
            }}
          >
            Licensed Professional Clinical Counseling Corporation
          </Typography>
        </div>
      </div>
      <div className={classes.rightColumn}></div>
    </div>
  );
};

export default ChangePasswordPage;
