import React from "react";
import { SwiperSlide } from "swiper/react";
import SwiperScroll from "../../../../components/SwiperScroll";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import Thumbnail from "../../../../components/Thumbnail";
import { Video } from "../../../../types";
// import LoadingView from "../../../../components/LoadingView";
// import ErrorView from "../../../../components/ErrorView";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
    },
  },
}));

interface SearchResultProps {
  videos: Video[];
  loading: boolean;
  isError: boolean;
  loadMore?: () => void;
}

const SearchResults = ({ videos, loadMore }: SearchResultProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h2 style={{ marginBottom: 2, color: "#121212", fontWeight: "normal" }}>
        Search Results
      </h2>
      <SwiperScroll onReachEnd={loadMore ? loadMore : undefined}>
        {videos.map((video) => (
          <SwiperSlide key={video._id} style={{ width: "auto" }}>
            <Thumbnail video={video} videoId={video._id} />
          </SwiperSlide>
        ))}
      </SwiperScroll>
    </div>
  );
};

export default SearchResults;
