import React from "react";
import { styled } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppRoutes } from "../constants/routes";
import * as Pages from "../pages";
import ProtectedRoute from "../utils/ProfileProtectedRoute";
import DashboardLayout from "../layout/DashboardLayout";

/**
 * @description Styled container for the base of the router component.
 */
const BaseContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default, // Sets the background color from the theme
}));

/**
 * @description Router component managing all the application's routing.
 * Utilizes react-router-dom for navigation and route management.
 * @returns {React.ReactElement} - Rendered Routes within a base container.
 */
export const Router = (): React.ReactElement => {
  return (
    <BaseContainer>
      <Routes>
        {/** AUTH ROUTES - Routes for authentication-related pages */}
        <Route path={`/${AppRoutes.AuthBase}/*`}>
          <Route path={AppRoutes.Auth.Login} element={<Pages.LoginPage />} />
          <Route path={AppRoutes.Auth.Signup} element={<Pages.SignUpPage />} />
          <Route
            path={AppRoutes.Auth.ForgotPassword}
            element={<Pages.ForgotPasswordPage />}
          />
          <Route
            path={AppRoutes.Auth.AccountVerification}
            element={<Pages.VerifyAccountPage />}
          />
          <Route
            path={AppRoutes.Auth.ChangePassword}
            element={<Pages.ChangePasswordPage />}
          />
        </Route>

        {/** PROGRAM ROUTES - Routes for program-related pages within a protected route */}
        <Route
          path={`/${AppRoutes.ProgramBase}/*`}
          element={
            <ProtectedRoute handleBaseRedirect={false}>
              <Routes>
                <Route
                  index
                  element={<Pages.SelectProgramPage mode="select" />}
                />
                <Route
                  path={`${AppRoutes.Programs.Manage}`}
                  element={<Pages.SelectProgramPage mode="manage" />}
                />
                <Route
                  path={`${AppRoutes.Programs.Create}`}
                  element={<Pages.AddProgramPage />}
                />
                <Route
                  path={`${AppRoutes.Programs.Update}`}
                  element={<Pages.EditProgramPage />}
                />
              </Routes>
            </ProtectedRoute>
          }
        />

        {/** SUBSCRIPTION ROUTES - Routes for subscription-related pages within a protected route */}
        <Route
          path={`/${AppRoutes.SubscriptionBase}/*`}
          element={
            <ProtectedRoute handleBaseRedirect={false}>
              <Routes>
                <Route
                  path={`${AppRoutes.Subscriptions.Plans}`}
                  element={<Pages.SubscriptionPage />}
                />
              </Routes>
            </ProtectedRoute>
          }
        />

        {/** PAYMENT STATUS ROUTES */}
        <Route
          path="/payment-success"
          element={
            <ProtectedRoute handleBaseRedirect={false}>
              <Pages.PaymentStatusPage status="successful" />
            </ProtectedRoute>
          }
        />

        <Route
          path="/payment-cancelled"
          element={
            <ProtectedRoute handleBaseRedirect={false}>
              <Pages.PaymentStatusPage status="unsuccessful" />
            </ProtectedRoute>
          }
        />

        {/** DASHBOARD ROUTES - Routes for dashboard-related pages */}
        <Route
          path={`/${AppRoutes.RouteBase}/*`}
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <Routes>
                  <Route
                    path={`/${AppRoutes.Browse.Home}`}
                    element={<Pages.LandingPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Search}`}
                    element={<Pages.SearchPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Settings}`}
                    element={<Pages.SettingsPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Playlists}`}
                    element={<Pages.PlaylistPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Stream}`}
                    element={<Pages.NowPlayingPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Categories}`}
                    element={<Pages.CategoriesPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Notifications}`}
                    element={<Pages.NotificationsPage />}
                  />

                  <Route
                    path="/more-info/:videoId"
                    element={<Pages.MoreInfoPage />}
                  />

                  {/* Additional dashboard routes can be added here */}
                  <Route
                    path="/"
                    element={<Navigate to={`${AppRoutes.Browse.Home}`} />}
                    // Redirect from /browse/ to /browse/home
                  />
                </Routes>
              </DashboardLayout>
            </ProtectedRoute>
          }
        />

        {/** HOME ROUTE - Redirects to appropriate page based on user status */}
        <Route
          path="/"
          element={
            <ProtectedRoute handleBaseRedirect={true}>
              <></>
            </ProtectedRoute>
          }
        />

        <Route
          path="/terms-and-conditions"
          element={<Pages.TermsAndConditionsPage />}
        />

        {/** OTHER ROUTES - Catch-all or 404 Not Found route */}
        {/* Uncomment below for a 404 page */}
        {/* <Route path="*" element={<Status404 />} /> */}
      </Routes>
    </BaseContainer>
  );
};
