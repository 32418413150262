import ContinueWatchingCard from "./ContinueWatchingCard";
import { SwiperSlide } from "swiper/react";
import SwiperScroll from "../../../../components/SwiperScroll";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Video } from "../../../../types";
import { useLazyGetVideoByIdQuery } from "../../../../redux/api/videoApi";
import { ContinueWatchingSkeletonCard } from "../../../../components/Skeletons/ContinueWatchingSkeletonCard";
import { Skeleton } from "@mui/material";
import { ContinueWatching as CW } from "../../../../types";
import { useGetContinueWatchingQuery } from "../../../../redux/api/continueWatchingApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface ContinueWatchingProps {
  continueWatchingData: {
    data: CW[];
    count: number;
  };
}

const ContinueWatching = ({
  continueWatchingData: initialState,
}: ContinueWatchingProps) => {
  const { currentProgram } = useSelector((state: RootState) => state.program);
  const { t } = useTranslation();

  const [getVideoById] = useLazyGetVideoByIdQuery();

  // State to hold the fetched video details
  const [videos, setVideos] = useState<
    { video: Video; progress: number; continueWatchingId: string }[]
  >([]);
  const [page, setPage] = useState(2);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [shouldLoadMore, setShouldLoadMore] = useState(true);

  const pageSize = 10; // Number of items to fetch per page

  // Fetch continue watching data using the query
  const { data: newContinueWatchingData, isLoading: isFetching } =
    useGetContinueWatchingQuery({
      program_id: currentProgram.id,
      page_number: page,
      page_size: pageSize,
      sort: "desc",
      field: "created_at",
    });

  const fetchVideoDetails = async (continueWatchingItems: CW[]) => {
    const fetchedVideos = await Promise.all(
      continueWatchingItems.map(async (film) => {
        const { data: videoData } = await getVideoById(film.video_id).unwrap();
        return {
          video: videoData,
          progress: film.current_position,
          continueWatchingId: film.id,
        };
      })
    );

    // Append fetched videos to the existing state
    setVideos((prevVideos) => [
      ...prevVideos,
      ...fetchedVideos.filter(
        (newVideo) =>
          !prevVideos.some((video) => video?.video?.id === newVideo?.video?.id)
      ),
    ]);

    // Update shouldLoadMore based on whether more videos can be loaded
    setShouldLoadMore(
      videos.length + fetchedVideos.length < initialState.count
    );
  };

  // Initial fetch for video details from the provided initial data
  useEffect(() => {
    if (initialState && initialState.data.length > 0) {
      fetchVideoDetails(initialState.data);
    }
  }, [initialState]);

  const loadMoreVideos = async () => {
    if (
      shouldLoadMore &&
      !isFetching &&
      !isFetchingMore &&
      newContinueWatchingData
    ) {
      setIsFetchingMore(true);
      await fetchVideoDetails(newContinueWatchingData.data);
      setPage((prevPage) => prevPage + 1);
      setIsFetchingMore(false);
    }
  };

  return (
    <>
      {isFetching && videos.length === 0 ? (
        <Skeleton variant="text" width={80} />
      ) : (
        <h2 style={{ marginBottom: 2, color: "white", fontWeight: "normal" }}>
          {t("HomePage.ContinueWatchingTitle")}
        </h2>
      )}
      <SwiperScroll onReachEnd={shouldLoadMore ? loadMoreVideos : undefined}>
        {isFetching && videos.length === 0
          ? Array.from(new Array(4)).map((_, index) => (
              <SwiperSlide key={index} style={{ width: "auto" }}>
                <ContinueWatchingSkeletonCard />
              </SwiperSlide>
            ))
          : videos.map((film) => (
              <SwiperSlide key={film.video?.id} style={{ width: "auto" }}>
                {film.video ? (
                  <ContinueWatchingCard
                    key={film.video?.id}
                    id={film.video?.id}
                    video={film.video}
                    progress={film.progress}
                    total={film.video?.medias[0]?.length}
                    continueWatchingId={film.continueWatchingId}
                  />
                ) : null}
              </SwiperSlide>
            ))}
      </SwiperScroll>
    </>
  );
};

export default ContinueWatching;
