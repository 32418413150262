import React from "react";
import {
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import { StyledListItemButton } from "../../../../components/ListButton";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

interface LanguageSettingsProps {
  selectedLanguage: string;
  onLanguageSelect: (code: "en" | "fr" | "es") => void;
}

const LanguageSettings = ({
  selectedLanguage,
  onLanguageSelect,
}: LanguageSettingsProps) => {
  const { t } = useTranslation();
  const languages: { code: "en" | "fr" | "es"; name: string }[] = [
    
    { code: "en", name: t("Settings.Languages.en") },
    { code: "fr", name: t("Settings.Languages.fr") },
    { code: "es", name: t("Settings.Languages.es") },
  ];

  return (
    <>
      <Typography variant="h4" mb={4}>
        {t("Settings.LanguageSetting.Title")}
      </Typography>
      <List sx={{ width: "60%" }}>
        {languages.map((language) => (
          <StyledListItemButton
            key={language.code}
            selected={selectedLanguage === language.code}
            onClick={() => onLanguageSelect(language.code)}
          >
            <ListItemText primary={language.name} />
            {selectedLanguage === language.code && <CheckIcon />}
          </StyledListItemButton>
        ))}
      </List>
    </>
  );
};

export default LanguageSettings;
