// videoProgressSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Video } from "../../types";

interface VideoProgress {
  progress: number; // Fraction of the video played
  totalLength: number; // Total length of the video in seconds
  video: Video;
}

export interface ProgramVideoProgress {
  [videoId: string]: VideoProgress;
}

interface VideoProgressState {
  videos: { [programId: string]: ProgramVideoProgress };
}

const initialState: VideoProgressState = {
  videos: {},
};

const videoProgressSlice = createSlice({
  name: "videoProgress",
  initialState,
  reducers: {
    setProgress: (
      state,
      action: PayloadAction<{
        programId: string;
        videoId: string;
        progress: number;
        totalLength: number;
        video: Video;
      }>
    ) => {
      const { programId, videoId, progress, totalLength, video } = action.payload;
      if (!state.videos[programId]) {
        state.videos[programId] = {};
      }
      state.videos[programId][videoId] = { progress, totalLength, video };
    },
    loadProgressFromStorage: (
      state,
      action: PayloadAction<VideoProgressState>
    ) => {
      // Replace the current state with the loaded state
      state.videos = action.payload.videos;
    },
  },
});

export const { setProgress, loadProgressFromStorage } = videoProgressSlice.actions;
export default videoProgressSlice.reducer;
