import React, { useState, useEffect } from "react";
import Hero from "./components/Hero";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  Box,
  Typography,
  Theme,
  Card,
  CardContent,
  Grid,
  Skeleton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useGetVideoByIdQuery } from "../../redux/api/videoApi";
import ErrorView from "../../components/ErrorView";
import { formatVideoLength } from "../../utils/formatVideoLength";
import { Instructor, Sponsor } from "../../types";
import { useLazyGetSponsorQuery } from "../../redux/api/sponsorApi";
import HeroSkeleton from "../../components/Skeletons/HeroSkeleton";
import { useLazyGetInstructorQuery } from "../../redux/api/instructorApi";
import InstructorCard from "./components/InstructorCard";
import ViewDocument from "./components/ViewDocument";

const useStyles = makeStyles((theme: Theme) => ({
  infoContainer: {
    marginBottom: theme.spacing(10),
    margin: "0 auto", // Center the content
    textAlign: "center", // Center-align the text
    [theme.breakpoints.up("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),

      paddingTop: theme.spacing(5),
    },
  },
  detailsList: {
    paddingLeft: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gap: theme.spacing(2),
  },

  sponsorCard: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 200,
    textAlign: "center",
  },
  sponsorAvatar: {
    height: 200,
    width: 200,
    // Ensures the image fits within the given size
  },
  sponsorName: {
    fontSize: "2rem",
    fontWeight: "bold",
  },
}));

const SponsorCard = ({ sponsor }: { sponsor: Sponsor }) => {
  const classes = useStyles();
  return (
    <Card className={classes.sponsorCard} sx={{ boxShadow: "none" }}>
      <CardContent>
        {sponsor.logo ? (
          <img
            alt={sponsor.name}
            src={sponsor.logo.url}
            className={classes.sponsorAvatar}
            style={{ objectFit: "contain" }}
          />
        ) : (
          <Typography className={classes.sponsorName}>
            {sponsor.name}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const MoreInfoPage = () => {
  const { videoId } = useParams();
  const classes = useStyles();
  const { currentVideo } = useSelector((state: RootState) => state.video);
  const {
    data: videoData,
    isFetching,
    isError,
  } = useGetVideoByIdQuery(videoId ?? "", { skip: !!currentVideo });
  const [video, setVideo] = useState(currentVideo);
  const [sponsors, setSponsors] = useState<(Sponsor | null)[]>([]);
  const [instructors, setInstructors] = useState<(Instructor | null)[]>([]);

  const { user } = useSelector((state: RootState) => state.user);
  const { features } = user;

  const shouldShowResources = features.video_resource;

  const { t } = useTranslation();

  const [getSponsor, { isLoading }] = useLazyGetSponsorQuery();
  const [getInstructor] = useLazyGetInstructorQuery();

  const fetchSponsorById = async (id: string): Promise<Sponsor | null> => {
    try {
      const response = await getSponsor(id).unwrap();
      return response.data;
    } catch (error) {
      console.error("Failed to fetch sponsor", error);
      return null;
    }
  };

  const fetchInstructorById = async (
    id: string
  ): Promise<Instructor | null> => {
    try {
      const response = await getInstructor(id).unwrap();
      return response.data;
    } catch (error) {
      console.error("Failed to fetch Instructor", error);
      return null;
    }
  };

  useEffect(() => {
    if (!currentVideo && videoData) {
      setVideo(videoData.data);
    }
  }, [currentVideo, videoData]);

  useEffect(() => {
    const loadSponsors = async () => {
      if (video?.sponsors) {
        try {
          const sponsorPromises = video.sponsors.map((sponsor) =>
            fetchSponsorById(sponsor.id)
          );
          const fetchedSponsors = await Promise.all(sponsorPromises);
          setSponsors(fetchedSponsors.filter((sponsor) => sponsor !== null));
        } catch (error) {
          console.error("Failed to fetch sponsors", error);
        }
      }
    };

    const loadInstructors = async () => {
      if (video.instructors) {
        try {
          const instructorPromises = video.instructors.map((instructor) => {
            if (instructor.id) return fetchInstructorById(instructor.id);
          });
          const fetchedInstructors = await Promise.all(instructorPromises);
          setInstructors(
            fetchedInstructors.filter((instructor) => instructor !== null)
          );
          console.log(fetchedInstructors);
        } catch (error) {
          console.error("Failed to fetch Instructors", error);
        }
      }
    };

    loadSponsors();
    loadInstructors();
  }, [video]);

  if (isFetching || isLoading) {
    return (
      <>
        <HeroSkeleton />
        <InfoContainerSkeleton />
      </>
    );
  }

  if (isError) {
    return (
      <ErrorView message={"Error Fetching Video Details"} type={"error"} />
    );
  }

  return (
    <>
      <Hero
        videoCategory={video?.category?.length ? video?.category[0] : ""}
        videoDescription={video?.description || ""}
        videoLength={
          video?.medias.length && video.medias[0].length
            ? formatVideoLength(video.medias[0].length)
            : ""
        }
        videoYear={video?.created_at.slice(0, 4) || ""}
        image={
          video?.thumbnails && video?.thumbnails.length
            ? video.thumbnails[0].url
            : ""
        }
        heading={video?.title || ""}
      />

      <Box className={classes.infoContainer}>
        {video?.sponsors.length ? (
          <Box mb={10}>
            <Typography variant="h4" sx={{ mb: 5 }} textAlign={"left"}>
              {t("MoreInfoPage.sponsors")}
            </Typography>
            <Grid container spacing={2} justifyContent="flex-start">
              {sponsors.map((sponsor, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <SponsorCard sponsor={sponsor} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : null}

        {instructors.length ? (
          <Box mb={10}>
            <Typography variant="h4" sx={{ mb: 5 }} textAlign={"left"}>
              {t("MoreInfoPage.instructors")}
            </Typography>
            <Grid container spacing={2} justifyContent="flex-start">
              {instructors.map((instructor, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <InstructorCard instructor={instructor} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : null}

        {shouldShowResources && video?.resources.length ? (
          <Box mb={10}>
            <Typography variant="h4" sx={{ mb: 5 }} textAlign={"left"}>
              {t("MoreInfoPage.resources")}
            </Typography>
            <Grid container spacing={1} justifyContent="flex-start">
              {video?.resources.map((resource, index) => (
                <Grid item key={index}>
                  <ViewDocument
                    name={resource.name}
                    fileUrl={resource.url}
                    description=""
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <></>
        )}

        {video?.copywrites.length ? (
          <>
            <Typography variant="h4" sx={{ mb: 5 }} textAlign={"left"}>
              {t("MoreInfoPage.copywrites")}
            </Typography>
            <Grid container spacing={1} justifyContent="flex-start">
              {video?.copywrites.map((copywrite, index) => (
                <Grid item key={index}>
                  <ViewDocument
                    name={"Copyright Document"}
                    fileUrl={copywrite.url}
                    description=""
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

const InfoContainerSkeleton = () => {
  const classes = useStyles();
  return (
    <Box className={classes.infoContainer}>
      <Skeleton variant="text" width="40%" height={40} />
      <Grid container spacing={2} justifyContent="center">
        {Array.from(new Array(4)).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
        ))}
      </Grid>
      <Skeleton variant="text" width="40%" height={40} sx={{ marginTop: 4 }} />
      <Grid container spacing={2} justifyContent="center">
        {Array.from(new Array(4)).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Skeleton variant="rectangular" height={50} />
          </Grid>
        ))}
      </Grid>
      <Skeleton variant="text" width="40%" height={40} sx={{ marginTop: 4 }} />
      {Array.from(new Array(3)).map((_, index) => (
        <Skeleton
          key={index}
          variant="text"
          width="60%"
          height={30}
          sx={{ marginTop: 2 }}
        />
      ))}
    </Box>
  );
};


export default MoreInfoPage;
