import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TopLevelBackButton: React.FC = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigates one step back in the history stack
  };

  return (
    <IconButton
      onClick={goBack}
      aria-label="go back"
      sx={{
        color: '#ee674c', // Sets the icon color to orange
        position: 'absolute', // Positions the button absolutely
        top: 16, // Positions the button near the top right
        right: 16,
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

export default TopLevelBackButton;
