import { baseApi } from "./baseApi";
import {
  VideoViewAnalytics,
  VideoViewAnalyticsParams,
  VideoViewPayload,
} from "../../types";

export const analyticsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getViewWatchAnalytics: builder.query<
      { data: VideoViewAnalytics[]; count: number },
      VideoViewAnalyticsParams
    >({
      query: (params) => ({
        url: `video-view`,
        method: "GET",
        params,
      }),
    }),

    getViewByProgramIdAndVideoId: builder.query<
      { data: VideoViewAnalytics },
      { program_id: string; video_id: string }
    >({
      query: ({ video_id, program_id }) => ({
        url: `/video-view/latest/program/${program_id}/video/${video_id}`,
        method: "GET",
      }),
    }),

    sendVideoView: builder.mutation<
      { data: VideoViewAnalytics },
      VideoViewPayload
    >({
      query: (body) => ({
        url: `video-view`,
        method: "POST",
        body,
      }),
    }),

    updateVideoView: builder.mutation<
      { data: VideoViewAnalytics },
      { id: string; retention_rate: number }
    >({
      query: (args) => ({
        url: `video-view/${args.id}`,
        method: "PATCH",
        body: { retention_rate: args.retention_rate },
      }),
    }),
  }),
});

export const {
  useGetViewByProgramIdAndVideoIdQuery,
  useSendVideoViewMutation,
  useUpdateVideoViewMutation,
  useGetViewWatchAnalyticsQuery,
  useLazyGetViewWatchAnalyticsQuery,
} = analyticsApi;
