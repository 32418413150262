import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton: React.FC = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigates one step back in the history stack
  };

  return (
    <IconButton onClick={goBack} aria-label="go back" className="control-button">
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
