import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./state/userSlice";
import sidebarReducer from "./state/sidebarSlice";
import settingsReducer from "./state/settingsSlice";
import videoProgressReducer from "./state/videoProgressSlice";
import videoReducer from "./state/videoSlice";
import programReducer from "./state/programSlice";
import { baseApi } from "./api/baseApi"; // assuming your injected APIs are included here

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const persistedSidebarReducer = persistReducer(persistConfig, sidebarReducer);
const persistedSettingsReducer = persistReducer(persistConfig, settingsReducer);
const persistedVideoProgressReducer = persistReducer(persistConfig, videoProgressReducer);
const persistedVideoReducer = persistReducer(persistConfig, videoReducer);

const store = configureStore({
  reducer: {
    user: userReducer,
    sidebar: persistedSidebarReducer,
    settings: persistedSettingsReducer,
    videoProgress: persistedVideoProgressReducer,
    video: persistedVideoReducer,
    program: programReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // Disable only the immutable state check
      serializableCheck: {
        // Ignore specific action types from redux-persist that are non-serializable
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(baseApi.middleware),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
