import { baseApi } from "./baseApi";
import type {
  Playlist,
  CreatePlaylistInput,
  PlaylistListResponse,
  GetMyPlaylistsParams,
  UpdatePlaylistInput,
} from "../../types";
import {
  CREATE_PLAYLIST_ENDPOINT,
  GET_MY_PLAYLISTS_ENDPOINT,
  GET_PLAYLIST_BY_ID_ENDPOINT,
  DELETE_PLAYLIST_BY_ID_ENDPOINT,
  UPDATE_PLAYLIST_BY_ID_ENDPOINT,
} from "../../constants/endpoints";


export const playlistApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Mutation to create a new playlist
    createPlaylist: builder.mutation<Playlist, CreatePlaylistInput>({
      query: (playlist) => ({
        url: CREATE_PLAYLIST_ENDPOINT,
        method: "POST",
        body: playlist,
      }),
      invalidatesTags: ["Playlist"],
    }),

    // Query to get a list of playlists
    getMyPlaylists: builder.query<PlaylistListResponse, GetMyPlaylistsParams>({
        query: (params) => ({
          url: GET_MY_PLAYLISTS_ENDPOINT,
          params, // Pass the query parameters to the request
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.data.map(({ id }) => ({
                  type: "Playlist" as const,
                  id,
                })),
                { type: "Playlist", id: "LIST" },
              ]
            : [{ type: "Playlist", id: "LIST" }],
      }),

    // Query to get a specific playlist by ID
    getPlaylistById: builder.query<Playlist, string>({
      query: (playlistId) => GET_PLAYLIST_BY_ID_ENDPOINT(playlistId),
    }),

    // Mutation to update a specific playlist
    updatePlaylist: builder.mutation<
      Playlist,
      Partial<UpdatePlaylistInput> & { playlistId: string }
    >({
      query: ({ playlistId, ...body }) => ({
        url: UPDATE_PLAYLIST_BY_ID_ENDPOINT(playlistId),
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { playlistId }) => [
        { type: "Playlist", id: playlistId },
      ],
    }),

    // Mutation to delete a specific playlist
    deletePlaylist: builder.mutation<{ success: boolean }, string>({
      query: (playlistId) => ({
        url: DELETE_PLAYLIST_BY_ID_ENDPOINT(playlistId),
        method: "DELETE",
      }),
      invalidatesTags: (result, error, playlistId) => [
        { type: "Playlist", id: playlistId },
      ],
    }),

    // ... other endpoints
  }),
});

export const {
  useCreatePlaylistMutation,
  useGetMyPlaylistsQuery,
  useGetPlaylistByIdQuery,
  useUpdatePlaylistMutation,
  useDeletePlaylistMutation,
} = playlistApi;
